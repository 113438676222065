module.exports = {
    "ts": 1617868254000,
    "data": {
        "eventsBadges": [
            {
                "type": "event",
                "description": {
                    "fr": "Accès au programme \"Lili à l'école\" lors de la journée de l'éducation"
                },
                "sections": [
                    {
                        "id": "school",
                        "count": 1
                    }
                ],
                "operator": "AND",
                "goal": 1,
                "id": "0124",
                "isDate": true,
                "bgColor": "#314762",
                "last_update": 1617868254000
            },
            {
                "type": "event",
                "description": {
                    "fr": "Podcast philo \"Fille, garçon : c'est différent ?\" lors de la journée internationale des droits de la femme"
                },
                "activities": [
                    {
                        "id": "AUT_05",
                        "count": 1
                    }
                ],
                "operator": "AND",
                "goal": 1,
                "id": "0308",
                "isDate": true,
                "bgColor": "#42bc99",
                "last_update": 1617868254000
            },
            {
                "type": "event",
                "description": {
                    "fr": "Podcast philo \"Nous sommes tous pareils ?\" lors de la journée pour l'élimination de la discrimination raciale"
                },
                "activities": [
                    {
                        "id": "MON_01",
                        "count": 1
                    }
                ],
                "operator": "AND",
                "goal": 1,
                "id": "0321",
                "isDate": true,
                "bgColor": "#bdf3f9",
                "last_update": 1617868254000
            },
            {
                "type": "event",
                "description": {
                    "fr": "Podcast philo \"L'important, c'est de participer ?\" lors de la journée du sport au service du développement et de la paix"
                },
                "activities": [
                    {
                        "id": "REG_05",
                        "count": 1
                    }
                ],
                "operator": "AND",
                "goal": 1,
                "id": "0406",
                "isDate": true,
                "bgColor": "#f3d676",
                "last_update": 1617868254000
            },
            {
                "type": "event",
                "description": {
                    "fr": "Improvisation ou mime lors de la journée de la créativité et de l’innovation"
                },
                "subsections": [
                    {
                        "id": "impro_game",
                        "count": 1
                    },
                    {
                        "id": "mime_game",
                        "count": 1
                    }
                ],
                "operator": "OR",
                "goal": 1,
                "id": "0421",
                "isDate": true,
                "bgColor": "#bba9fc",
                "last_update": 1617868254000
            },
            {
                "type": "event",
                "description": {
                    "fr": "Atelier de yoga lors de la journée internationale du yoga"
                },
                "subsections": [
                    {
                        "id": "yoga",
                        "count": 1
                    }
                ],
                "operator": "AND",
                "goal": 1,
                "id": "0621",
                "isDate": true,
                "bgColor": "#314762",
                "last_update": 1617868254000
            },
            {
                "type": "event",
                "description": {
                    "fr": "Podcast philo \"Ça veut dire quoi, un ami ?\" lors de la journée de l'amitié"
                },
                "activities": [
                    {
                        "id": "AUT_01",
                        "count": 1
                    }
                ],
                "operator": "AND",
                "goal": 1,
                "id": "0730",
                "isDate": true,
                "bgColor": "#42bc99",
                "last_update": 1617868254000
            },
            {
                "type": "event",
                "description": {
                    "fr": "Méditation lors de la journée de la paix"
                },
                "subsections": [
                    {
                        "id": "meditation",
                        "count": 1
                    }
                ],
                "operator": "AND",
                "goal": 1,
                "id": "0921",
                "isDate": true,
                "bgColor": "#bdf3f9",
                "last_update": 1617868254000
            },
            {
                "type": "event",
                "description": {
                    "fr": "Pratique de Lili le jour de la date anniversaire de l'application (Lili a été lancée le 22 septembre 2020)"
                },
                "operator": "AND",
                "goal": 1,
                "id": "0922",
                "isDate": true,
                "bgColor": "#f3d676",
                "last_update": 1617868254000
            },
            {
                "type": "event",
                "description": {
                    "fr": "Podcast philo \"J'ai peur !\" le jour de Halloween"
                },
                "activities": [
                    {
                        "id": "EMO_05",
                        "count": 1
                    }
                ],
                "operator": "AND",
                "goal": 1,
                "id": "1031",
                "isDate": true,
                "bgColor": "#bba9fc",
                "last_update": 1617868254000
            },
            {
                "type": "event",
                "description": {
                    "fr": "Podcast philo \"Ça va, on rigole !\" lors de la journée de lutte contre le harcèlement"
                },
                "activities": [
                    {
                        "id": "AUT_02",
                        "count": 1
                    }
                ],
                "operator": "AND",
                "goal": 1,
                "id": "1105",
                "isDate": true,
                "bgColor": "#314762",
                "last_update": 1617868254000
            },
            {
                "type": "event",
                "description": {
                    "fr": "Podcast philo \"Ça veut dire quoi, normal ?\" lors de la journée des personnes handicapées"
                },
                "activities": [
                    {
                        "id": "MON_02",
                        "count": 1
                    }
                ],
                "operator": "AND",
                "goal": 1,
                "id": "1203",
                "isDate": true,
                "bgColor": "#42bc99",
                "last_update": 1617868254000
            }
        ],
        "discoveryBadges": [
            {
                "type": "discovery",
                "id": "badge_melomane",
                "description": {
                    "fr": "Une bulle sonore et un atelier de yoga en musique"
                },
                "subsections": [
                    {
                        "id": "yoga",
                        "count": 1
                    },
                    {
                        "id": "sound_bubule",
                        "count": 1
                    }
                ],
                "operator": "AND",
                "goal": 2,
                "pdf": {
                    "fr": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F05%20-%20Badges%2FLILI_BADGE_A_COLORIER_MELOMANE.pdf?alt=media&token=81576567-733f-4d92-b06e-f0042a99d5c1"
                },
                "last_update": 1617868254000
            },
            {
                "type": "discovery",
                "id": "badge_expressif",
                "description": {
                    "fr": "Mime \"Alphabet visuel\" et jeu d’émotion \"La valse des émotions\""
                },
                "activities": [
                    {
                        "id": "JMI_03",
                        "count": 1
                    },
                    {
                        "id": "JEM_01",
                        "count": 1
                    }
                ],
                "operator": "AND",
                "goal": 2,
                "pdf": {
                    "fr": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F05%20-%20Badges%2FLILI_BADGE_A_COLORIER_EXPRESSIF.pdf?alt=media&token=370f24c6-16f8-46ee-87af-d61759894c4d"
                },
                "last_update": 1617868254000
            },
            {
                "type": "discovery",
                "id": "badge_audacieux",
                "description": {
                    "fr": "Improvisation \"Super, j’ai raté !\" et podcast philo \"Mince, j’ai fait une erreur !\""
                },
                "activities": [
                    {
                        "id": "JIM_07",
                        "count": 1
                    },
                    {
                        "id": "REG_04",
                        "count": 1
                    }
                ],
                "operator": "AND",
                "goal": 2,
                "pdf": {
                    "fr": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F05%20-%20Badges%2FLILI_BADGE_A_COLORIER_INTREPIDE.pdf?alt=media&token=6b1f5653-f6bd-4b52-a11f-b4d70f465d76"
                },
                "last_update": 1617868254000
            },
            {
                "type": "discovery",
                "id": "badge_aventurier",
                "description": {
                    "fr": "Méditation \"L’île intérieure\" et respiration \"La montgolfière\""
                },
                "activities": [
                    {
                        "id": "MED_11",
                        "count": 1
                    },
                    {
                        "id": "RESP_03",
                        "count": 1
                    }
                ],
                "operator": "AND",
                "goal": 2,
                "pdf": {
                    "fr": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F05%20-%20Badges%2FLILI_BADGE_A_COLORIER_AVENTURIER(E).pdf?alt=media&token=8e75c184-2865-4cf2-8d1b-9056c9c8a026"
                },
                "last_update": 1617868254000
            },
            {
                "type": "discovery",
                "id": "badge_joueur",
                "description": {
                    "fr": "Dessin à dédicacer \"Victoire !\" et podcast philo \"L’important, c’est de participer ?\""
                },
                "activities": [
                    {
                        "id": "DED_04",
                        "count": 1
                    },
                    {
                        "id": "REG_05",
                        "count": 1
                    }
                ],
                "operator": "AND",
                "goal": 2,
                "pdf": {
                    "fr": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F05%20-%20Badges%2FLILI_BADGE_A_COLORIER_JOUEUR.pdf?alt=media&token=29e8629f-ee00-4806-8ba6-9ca8538990d5"
                },
                "last_update": 1617868254000
            },
            {
                "type": "discovery",
                "id": "badge_courageux",
                "description": {
                    "fr": "Improvisation \"Attention à la chute !\" et dessin à dédicacer \"Même pas peur !\""
                },
                "activities": [
                    {
                        "id": "JIM_04",
                        "count": 1
                    },
                    {
                        "id": "DED_06",
                        "count": 1
                    }
                ],
                "operator": "AND",
                "goal": 2,
                "pdf": {
                    "fr": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F05%20-%20Badges%2FLILI_BADGE_A_COLORIER_TEMERAIRE.pdf?alt=media&token=bbaccc12-cf31-45c1-bc6f-d53a2f7c65f1"
                },
                "last_update": 1617868254000
            },
            {
                "type": "discovery",
                "id": "badge_agile",
                "description": {
                    "fr": "Réalisation d'au moins une activité depuis les 4 sections \"Mes programmes\", \"Je me pose\", \"Je m’exprime\" et \"Je grandis\""
                },
                "sections": [
                    {
                        "id": "path",
                        "count": 1
                    },
                    {
                        "id": "grow",
                        "count": 1
                    },
                    {
                        "id": "still",
                        "count": 1
                    },
                    {
                        "id": "talk",
                        "count": 1
                    }
                ],
                "operator": "AND",
                "goal": 4,
                "pdf": {
                    "fr": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F05%20-%20Badges%2FLILI_BADGE_A_COLORIER_AGILE.pdf?alt=media&token=45632291-5d87-4092-860c-7d4b413227d9"
                },
                "last_update": 1617868254000
            },
            {
                "type": "discovery",
                "id": "badge_grand_lecteur",
                "description": {
                    "fr": "Lecture de plus de 10 conseils de lecture depuis \"Je grandis\""
                },
                "sections": [
                    {
                        "id": "grow",
                        "count": 10,
                        "detail": "preview"
                    }
                ],
                "operator": "AND",
                "goal": 10,
                "pdf": {
                    "fr": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F05%20-%20Badges%2FLILI_BADGE_A_COLORIER_LITTERAIRE.pdf?alt=media&token=70d2b2f1-01ec-46d9-8555-44eb5d30de43"
                },
                "last_update": 1617868254000
            },
            {
                "type": "discovery",
                "id": "badge_pose",
                "description": {
                    "fr": "Un origami, un dessin à dédicacer et un carnet philo"
                },
                "subsections": [
                    {
                        "id": "dedicaces",
                        "count": 1
                    },
                    {
                        "id": "finger_yoga",
                        "count": 1
                    }
                ],
                "sections": [
                    {
                        "id": "path",
                        "count": 1,
                        "detail": "philo_notebook"
                    }
                ],
                "operator": "AND",
                "goal": 3,
                "pdf": {
                    "fr": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F05%20-%20Badges%2FLILI_BADGE_A_COLORIER_CALME.pdf?alt=media&token=a5f1ddf9-c948-40e9-9a1d-c4a3520d144b"
                },
                "last_update": 1617868254000
            },
            {
                "type": "discovery",
                "id": "badge_repose",
                "description": {
                    "fr": "Pratique d’une activité \"Je me pose\" un samedi et un dimanche"
                },
                "sections": [
                    {
                        "id": "still",
                        "count": 2,
                        "detail": "week_end"
                    }
                ],
                "operator": "AND",
                "goal": 2,
                "pdf": {
                    "fr": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F05%20-%20Badges%2FLILI_BADGE_A_COLORIER_DETENDU(E).pdf?alt=media&token=64e17a5a-b69a-4fc7-98c0-4da08ef0ac35"
                },
                "last_update": 1617868254000
            },
            {
                "type": "discovery",
                "id": "badge_intellectuel",
                "description": {
                    "fr": "Jeu d'attention \"Jeu de mémoire\" et podcast philo \"Ça veut dire quoi, être intelligent ?\""
                },
                "activities": [
                    {
                        "id": "JAT_03",
                        "count": 1
                    },
                    {
                        "id": "REG_03",
                        "count": 1
                    }
                ],
                "operator": "AND",
                "goal": 2,
                "pdf": {
                    "fr": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F05%20-%20Badges%2FLILI_BADGE_A_COLORIER_INTELLECTUEL(LE).pdf?alt=media&token=4c4f5310-82a0-4bbe-aa76-ff293a2db96c"
                },
                "last_update": 1617868254000
            },
            {
                "type": "discovery",
                "id": "badge_curieux",
                "description": {
                    "fr": "Pratique d’une activité de chaque catégorie"
                },
                "subsections": [
                    {
                        "id": "game_to_build",
                        "count": 1
                    },
                    {
                        "id": "sound_bubule",
                        "count": 1
                    },
                    {
                        "id": "yoga",
                        "count": 1
                    },
                    {
                        "id": "my_emotion",
                        "count": 1
                    },
                    {
                        "id": "breath",
                        "count": 1
                    },
                    {
                        "id": "listening_game",
                        "count": 1
                    },
                    {
                        "id": "others",
                        "count": 1
                    },
                    {
                        "id": "dedicaces",
                        "count": 1
                    },
                    {
                        "id": "meditation",
                        "count": 1
                    },
                    {
                        "id": "auto_massage",
                        "count": 1
                    },
                    {
                        "id": "emotion_game",
                        "count": 1
                    },
                    {
                        "id": "finger_yoga",
                        "count": 1
                    },
                    {
                        "id": "concentration_game",
                        "count": 1
                    },
                    {
                        "id": "impro_game",
                        "count": 1
                    },
                    {
                        "id": "my_entourage",
                        "count": 1
                    },
                    {
                        "id": "mime_game",
                        "count": 1
                    },
                    {
                        "id": "introspection",
                        "count": 1
                    },
                    {
                        "id": "world",
                        "count": 1
                    }
                ],
                "operator": "AND",
                "goal": 18,
                "pdf": {
                    "fr": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F05%20-%20Badges%2FLILI_BADGE_A_COLORIER_CURIEUX.pdf?alt=media&token=5f6e684b-64cf-4d62-9b9a-c2701a505ae1"
                },
                "last_update": 1617868254000
            },
            {
                "type": "discovery",
                "id": "badge_collectionneur",
                "description": {
                    "fr": "Pratique de 4 ateliers au sein d’une catégorie"
                },
                "subsections": [
                    {
                        "id": "game_to_build",
                        "count": 4
                    },
                    {
                        "id": "sound_bubule",
                        "count": 4
                    },
                    {
                        "id": "yoga",
                        "count": 4
                    },
                    {
                        "id": "my_emotion",
                        "count": 4
                    },
                    {
                        "id": "breath",
                        "count": 4
                    },
                    {
                        "id": "listening_game",
                        "count": 4
                    },
                    {
                        "id": "others",
                        "count": 4
                    },
                    {
                        "id": "dedicaces",
                        "count": 4
                    },
                    {
                        "id": "meditation",
                        "count": 4
                    },
                    {
                        "id": "auto_massage",
                        "count": 4
                    },
                    {
                        "id": "emotion_game",
                        "count": 4
                    },
                    {
                        "id": "finger_yoga",
                        "count": 4
                    },
                    {
                        "id": "concentration_game",
                        "count": 4
                    },
                    {
                        "id": "impro_game",
                        "count": 4
                    },
                    {
                        "id": "my_entourage",
                        "count": 4
                    },
                    {
                        "id": "mime_game",
                        "count": 4
                    },
                    {
                        "id": "introspection",
                        "count": 4
                    },
                    {
                        "id": "world",
                        "count": 4
                    }
                ],
                "operator": "OR",
                "goal": 4,
                "pdf": {
                    "fr": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F05%20-%20Badges%2FLILI_BADGE_A_COLORIER_LOYAL(E).pdf?alt=media&token=e34cc165-c315-4cc1-bd42-6163dad11f33"
                },
                "last_update": 1617868254000
            },
            {
                "type": "discovery",
                "id": "badge_reveur",
                "description": {
                    "fr": "Bulle sonore \"La plage\" et dessin à dédicacer \"Je me souviens\""
                },
                "activities": [
                    {
                        "id": "BUL_02",
                        "count": 1
                    },
                    {
                        "id": "DED_01",
                        "count": 1
                    }
                ],
                "operator": "AND",
                "goal": 2,
                "pdf": {
                    "fr": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F05%20-%20Badges%2FLILI_BADGE_A_COLORIER_REVEUR.pdf?alt=media&token=e5f4a0d9-98ae-4840-beb9-de0f03a4d298"
                },
                "last_update": 1617868254000
            }
        ],
        "fidelityBadges": [
            {
                "type": "fidelity",
                "id": "badge_tranquille",
                "description": {
                    "fr": "Pratique de 3 ateliers \"Je me pose\""
                },
                "sections": [
                    {
                        "id": "still",
                        "count": 3
                    }
                ],
                "operator": "AND",
                "goal": 3,
                "pdf": {
                    "fr": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F05%20-%20Badges%2FLILI_BADGE_A_COLORIER_TRANQUILLE.pdf?alt=media&token=fbf138ad-2bc8-46a4-b067-685465f3e81b"
                },
                "last_update": 1617868254000
            },
            {
                "type": "fidelity",
                "id": "badge_serein",
                "description": {
                    "fr": "Pratique de 10 ateliers \"Je me pose\""
                },
                "sections": [
                    {
                        "id": "still",
                        "count": 10
                    }
                ],
                "operator": "AND",
                "goal": 10,
                "pdf": {
                    "fr": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F05%20-%20Badges%2FLILI_BADGE_A_COLORIER_SEREIN(E).pdf?alt=media&token=8aca9a6a-471d-41ce-b2e2-4aacf275cab5"
                },
                "last_update": 1617868254000
            },
            {
                "type": "fidelity",
                "id": "badge_maitre_zen",
                "description": {
                    "fr": "Pratique de 20 ateliers \"Je me pose\""
                },
                "sections": [
                    {
                        "id": "still",
                        "count": 20
                    }
                ],
                "operator": "AND",
                "goal": 20,
                "pdf": {
                    "fr": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F05%20-%20Badges%2FLILI_BADGE_A_COLORIER_ZEN.pdf?alt=media&token=4ef3950c-0f8f-42d8-95ef-2e4917ed6d60"
                },
                "last_update": 1617868254000
            },
            {
                "type": "fidelity",
                "id": "badge_comedien",
                "description": {
                    "fr": "Pratique de 3 ateliers \"Je m'exprime\""
                },
                "sections": [
                    {
                        "id": "talk",
                        "count": 3
                    }
                ],
                "operator": "AND",
                "goal": 3,
                "pdf": {
                    "fr": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F05%20-%20Badges%2FLILI_BADGE_A_COLORIER_COMEDIEN(NE).pdf?alt=media&token=ff45b8eb-b6f6-4c28-8a30-0c0719e6b0f1"
                },
                "last_update": 1617868254000
            },
            {
                "type": "fidelity",
                "id": "badge_star",
                "description": {
                    "fr": "Pratique de 10 ateliers \"Je m'exprime\""
                },
                "sections": [
                    {
                        "id": "talk",
                        "count": 10
                    }
                ],
                "operator": "AND",
                "goal": 10,
                "pdf": {
                    "fr": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F05%20-%20Badges%2FLILI_BADGE_A_COLORIER_STAR.pdf?alt=media&token=d5527768-6fac-4e1e-8f9c-be002c68ef73"
                },
                "last_update": 1617868254000
            },
            {
                "type": "fidelity",
                "id": "badge_roi_de_la_scene",
                "description": {
                    "fr": "Pratique de 20 ateliers \"Je m'exprime\""
                },
                "sections": [
                    {
                        "id": "talk",
                        "count": 20
                    }
                ],
                "operator": "AND",
                "goal": 20,
                "pdf": {
                    "fr": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F05%20-%20Badges%2FLILI_BADGE_A_COLORIER_ROI_DE_LA_SCENE.pdf?alt=media&token=556c31d5-65ee-440d-a82f-83274e1b95fc"
                },
                "last_update": 1617868254000
            },
            {
                "type": "fidelity",
                "id": "badge_enqueteur",
                "description": {
                    "fr": "Pratique de 3 ateliers \"Je grandis\""
                },
                "operator": "AND",
                "sections": [
                    {
                        "id": "grow",
                        "count": 3
                    }
                ],
                "goal": 3,
                "pdf": {
                    "fr": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F05%20-%20Badges%2FLILI_BADGE_A_COLORIER_DETECTIVE.pdf?alt=media&token=fc43867e-55aa-4108-bd24-badfc90306e2"
                },
                "last_update": 1617868254000
            },
            {
                "type": "fidelity",
                "id": "badge_explorateur",
                "description": {
                    "fr": "Pratique de 10 ateliers \"Je grandis\""
                },
                "sections": [
                    {
                        "id": "grow",
                        "count": 10
                    }
                ],
                "operator": "AND",
                "goal": 10,
                "pdf": {
                    "fr": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F05%20-%20Badges%2FLILI_BADGE_A_COLORIER_EXPLORATEUR.pdf?alt=media&token=bcc154bf-7594-4042-96e6-66d6ddb00fde"
                },
                "last_update": 1617868254000
            },
            {
                "type": "fidelity",
                "id": "badge_grand_sage",
                "description": {
                    "fr": "Pratique de 20 ateliers \"Je grandis\""
                },
                "sections": [
                    {
                        "id": "grow",
                        "count": 20
                    }
                ],
                "operator": "AND",
                "goal": 20,
                "pdf": {
                    "fr": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F05%20-%20Badges%2FLILI_BADGE_A_COLORIER_PHILOSOPHE.pdf?alt=media&token=7ae4a78a-f24a-4bee-b31e-52ca4240a83f"
                },
                "last_update": 1617868254000
            }
        ]
    }
};