import React, { useEffect, useState } from "react";

import {
    Modal,
    Platform,
    Text,
    TouchableOpacity,
    View
} from "react-native";
import i18n, { getLanguage } from "../../../utils/Localization/Localization";
import AppTheme from "../../../utils/Theme";
import ActivityMediaCell from "../ActivityMediaCell";
import { trackEvent, analyticsEventActivityLaunch, analyticsEventMediaLaunch, propertiesEventContext, propertiesEventContent, propertiesEventAccess, propertiesEventCategory, propertiesEventCompleted, propertiesEventSection, propertiesEventName, analyticsEventActivityCompleted, analyticsEventActivityDownloaded, propertiesEventProfile, analyticsEventWorkshopLaunched, analyticsEventWorkshopCompleted, analyticsEventPaywallDisplayed, propertiesEventSource } from "../../../services/Tracking/Analytics";

import User from "../../../data/user/User";

import * as Navigation from "../../../../specific/utils/Navigation/Navigation";
import * as Constants from "../../../utils/Constants";
import PDFViewer from "../../../../specific/components/PDF/PDFViewer";
import BookPreview from "../../Preview/BookPreview";
import { onActivityCompleted } from "../../../../specific/services/Specific_Database";
import Activity from "../../../models/Activity";
import { checkLocalUrl } from "../../../utils/LocalStorage";
import LiliVideoPlayer from "../../../../specific/components/VideoPlayer/LiliVideoPlayer";
import ActivityPlayer from "../../Audio/ActivityPlayer";
import Images from "../../../../specific/utils/Images";
import Style from "../../../utils/Style";
import SVGView from "../../../utils/SvgView";
import { default as icDismiss } from "../../../assets/svg/icons/solid/times.svg";
import { default as IcFilm } from "../../../assets/svg/icons/solid/film.svg";
import { default as IcHeadphones } from "../../../assets/svg/lili/ic_audio_book.svg";
import ActivityMultiFormatCell from "../ActivityMultiFormatCell";
import { getAppTarget } from "../../../../specific/utils/Navigation/Host";
import { getAudioUrlForMediaId, getDocumentUrlForMediaId, getVideoUrlForMediaId } from "../../../../specific/services/AppWrite/AppWriteDatabase";

type localizedString = {
    fr?: string
}

type media = {
    id: string,
    title: localizedString,
    type: string,
    url: string
}

interface ActivityCellBodyProps {
    title: string;
    media: media;
    color: string;
    width: number;
    type: string;
    isEnable?: boolean;
    componentId?: string;
    sectionKey: string;
    activity: Activity;
    categoryKey: string;
    context: string;
    categoryAnalyticsKey: string,
    sectionAnalyticsKey: string,
    starting_point?: "superpower" | "section"
}

const ActivityCellMedia = (props: ActivityCellBodyProps) => {
    const { title, media, color, width, type, isEnable = false, componentId = "", sectionKey, activity, categoryKey, context, categoryAnalyticsKey, sectionAnalyticsKey, starting_point } = props;

    const appTheme: AppTheme = new AppTheme();
    const user: User = User.getInstance();
    const images: Images = new Images();

    const [mediaType, setMediaType] = useState("pdf");
    const [startTime, setStartTime] = useState(0);
    const [itemSelected, setItemSelected] = useState(null);
    const [documentData, setDocumentData] = useState({ url: "", title: "", color: appTheme.darkBlue });
    const [showMedia, setShowMedia] = useState(false);
    const [previewData, setPreviewData] = useState({ title: "", img: "", age: "", id: "", owner: "", body: "", author: "", color: appTheme.darkBlue });
    const [showChoices, setShowChoices] = useState<boolean>(false);

    if (type === "media_title") {
        let introTitle = i18n.t("activities.media.intro");
        if (activity.action.type === "workshop") {
            introTitle = i18n.t("activities.workshop.media.intro");
        } else if (activity.action.type === "audio_steps") {
            introTitle = i18n.t("activities.grow.media.intro");
        }
        if (activity.sectionKey === "inspire") {
            introTitle = title;
        }
        return <View style={{ flex: 1, marginTop: appTheme.pixelPerfect(20) }}>
            <View style={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: appTheme.schoolBackgroundColor,
                width
            }}>
                <Text style={{ color: appTheme.darkBlue, fontSize: appTheme.pixelPerfect(14), paddingVertical: appTheme.pixelPerfect(10), fontFamily: appTheme.secondaryFont, paddingHorizontal: appTheme.pixelPerfect(20), textAlign: "center" }}>
                    {introTitle}
                </Text>
            </View>
        </View>
    }

    const showPremiumAlert = () => {
        trackEvent(analyticsEventPaywallDisplayed, { [propertiesEventSource]: "user_profile", [propertiesEventProfile]: user.userProfile() });
        Navigation.showModalInView({
            componentId: componentId,
            name: Constants.ScreenSubscription,
            passProps: {
                isModal: true
            }
        })
    }

    const onAudioChoiceDone = (isAudio: boolean) => {
        let analyticsContent = "audio";
        if (isAudio === false) {
            analyticsContent = "video";
        }
        trackEvent(analyticsEventActivityLaunch, {
            [propertiesEventCategory]: categoryAnalyticsKey,
            [propertiesEventContent]: analyticsContent,
            [propertiesEventAccess]: activity.isPremium() === true ? "premium" : "free",
            [propertiesEventName]: activity.analytics_key,
            "starting_point": starting_point
        });
        setMediaType(analyticsContent);
        let mediaToShow = undefined;
        for (const aMediaIndex in itemSelected.media) {
            if (Object.prototype.hasOwnProperty.call(itemSelected.media, aMediaIndex)) {
                const aMedia = itemSelected.media[aMediaIndex];
                if (aMedia.type === "audio") {
                    if (isAudio === true) {
                        mediaToShow = aMedia;
                        break;
                    }
                } else {
                    if (isAudio === false) {
                        mediaToShow = aMedia;
                        break;
                    }
                }
            }
        }
        if (mediaToShow !== undefined) {
            const isPublicApp = getAppTarget();
            let urlToLaunch = mediaToShow.url[getLanguage()];
            if (isPublicApp !== "public") {
                if (isAudio === true) {
                    urlToLaunch = getAudioUrlForMediaId({ media_id: itemSelected.id });
                } else {
                    urlToLaunch = getVideoUrlForMediaId({ media_id: itemSelected.id });
                }
            }
            setDocumentData({
                url: urlToLaunch,
                title: mediaToShow.title[getLanguage()],
                color: props.color
            });
            setShowMedia(true);
            setTimeout(() => {
                setItemSelected(null);
            }, 500);
        }
    }

    const cancelTheChoice = () => {
        setItemSelected(null);
    }

    const getItemChoices = () => {
        if (itemSelected === null) {
            return <View />;
        }
        const marginHorizontal = appTheme.pixelPerfect(20);
        const buttonWidth = width - marginHorizontal * 2;
        const buttonPadding = appTheme.pixelPerfect(5);
        const iconWidth = appTheme.pixelPerfect(40);
        const textWidth = buttonWidth - iconWidth - 2 * buttonPadding;
        const buttonFontSize = appTheme.pixelPerfectForFont(12);
        const buttonHeight = appTheme.pixelPerfect(80);
        const windowMarginBottom = Platform.OS === "web" ? appTheme.pixelPerfect(20) : 0;
        return <View style={[Style.shadowed, { paddingTop: appTheme.pixelPerfect(10), backgroundColor: props.color, width, borderRadius: appTheme.pixelPerfect(10), alignItems: "center", marginBottom: windowMarginBottom }]}>
            <View style={{ marginBottom: appTheme.pixelPerfect(20), width, paddingHorizontal: appTheme.pixelPerfect(10), alignItems: "flex-end" }}>
                <TouchableOpacity onPress={cancelTheChoice} style={{ backgroundColor: appTheme.white, width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), borderRadius: appTheme.pixelPerfect(15), justifyContent: "center", alignItems: "center" }}>
                    <SVGView Component={icDismiss} size={20} color={props.color} />
                </TouchableOpacity>
            </View>
            <Text style={{ paddingHorizontal: appTheme.pixelPerfect(20), textAlign: "center", fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(16), color: appTheme.white }}>
                {itemSelected.title[getLanguage()]}
            </Text>
            <View style={{ marginVertical: appTheme.pixelPerfect(20) }}>
                <TouchableOpacity key="choice-video" onPress={() => onAudioChoiceDone(false)} style={{ paddingHorizontal: buttonPadding, flexDirection: "row", alignItems: "center", justifyContent: "space-around", backgroundColor: appTheme.white, width: buttonWidth, height: buttonHeight, borderRadius: appTheme.pixelPerfect(20), marginBottom: appTheme.pixelPerfect(20) }}>
                    <View style={{ width: iconWidth, alignItems: "center", justifyContent: "center" }}>
                        <SVGView Component={IcFilm} size={20} color={props.color} />
                    </View>
                    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                        <Text numberOfLines={3} style={{ textAlign: "center", fontFamily: appTheme.secondaryFont, fontSize: buttonFontSize, color: props.color, width: textWidth }}>{"REGARDER UNE PREMIERE REPONSE EN VIDEO".toLocaleUpperCase()}</Text>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity key="choice-audio" onPress={() => onAudioChoiceDone(true)} style={{ paddingHorizontal: buttonPadding, flexDirection: "row", alignItems: "center", justifyContent: "space-around", backgroundColor: appTheme.white, width: buttonWidth, height: buttonHeight, borderRadius: appTheme.pixelPerfect(20), marginBottom: appTheme.pixelPerfect(20) }}>
                    <View style={{ width: iconWidth, alignItems: "center", justifyContent: "center" }}>
                        <SVGView Component={IcHeadphones} size={24} color={props.color} />
                    </View>
                    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                        <Text numberOfLines={3} style={{ textAlign: "center", fontFamily: appTheme.secondaryFont, fontSize: buttonFontSize, color: props.color, width: textWidth }}>{"ECOUTER TOUTES LES REPONSES A CETTE QUESTION".toLocaleUpperCase()}</Text>
                    </View>
                </TouchableOpacity>
            </View>
        </View>;
    }

    const closeTheDocument = () => {
        if (mediaType === "pdf") {
            // On va clore la lecture pour la gamification
            const timeOfLeaving = new Date().getTime();
            const readingTime = timeOfLeaving - startTime;
            if (readingTime > 9999) {
                onActivityCompleted({
                    activityKey: activity.key,
                    sectionKey: sectionKey,
                    categoryKey,
                    iteration: 1,
                    detail: sectionKey === 'grow' ? 'philo_notebook' : '',
                    menu: ""
                })
            };
            setDocumentData({
                url: "",
                title: "",
                color
            });
        } else {
            setPreviewData({
                title: "",
                img: "",
                age: "",
                id: "",
                owner: "",
                body: "",
                author: "",
                color: ""
            })
        }

        setShowMedia(false);
    }

    const onVideoCompleted = () => {
        trackEvent(analyticsEventActivityCompleted, {
            [propertiesEventContent]: "video",
            [propertiesEventCategory]: categoryAnalyticsKey,
            [propertiesEventAccess]: activity.isPremium() === true ? "premium" : "free",
            [propertiesEventName]: activity.analytics_key,
            "starting_point": starting_point
        });
    }

    const onAudioCompleted = () => {
        trackEvent(analyticsEventActivityCompleted, {
            [propertiesEventContent]: "audio",
            [propertiesEventCategory]: categoryAnalyticsKey,
            [propertiesEventAccess]: activity.isPremium() === true ? "premium" : "free",
            [propertiesEventName]: activity.analytics_key,
            "starting_point": starting_point
        });
    }

    useEffect(() => {
        if (itemSelected !== null) {
            setShowChoices(true);
        } else {
            setShowChoices(false);
        }
    }, [itemSelected]);

    const getMediaModalContent = () => {
        if (mediaType === "pdf") {
            // On va vérifier quel est l'url du document
            // Initialement les urls étaient contenu dans documentData.url, or cela ne permettait pas la localisation
            // Donc en juillet 2024, on a changé pour document.url[getLanguage()]
            let documentUrl: string = documentData.url;
            if (documentData.url[getLanguage()] !== undefined) {
                documentUrl = documentData.url[getLanguage()];
            }
            return <PDFViewer
                title={documentData.title}
                pdfUrl={checkLocalUrl(documentUrl)}
                color={documentData.color}
                sectionId={sectionKey}
                closeTheDocument={closeTheDocument} />;
        } else if (mediaType === "preview") {
            return <BookPreview
                preview={previewData}
                sectionId={sectionKey}
                closeTheDocument={closeTheDocument} />;
        } else if (mediaType === "video") {
            return <LiliVideoPlayer
                onVideoCompleted={onVideoCompleted}
                url={checkLocalUrl(documentData.url)}
                closeTheDocument={closeTheDocument} />
        } else if (mediaType === "audio") {
            return <ActivityPlayer
                title={activity.title[getLanguage()]}
                onActivityCompleted={onAudioCompleted}
                urls={[checkLocalUrl(documentData.url)]}
                color={props.color}
                notificationImage={Platform.OS === "ios" ? activity.img_url : images[activity.app_image]}
                localImage={images[activity.app_image]}
                remoteImage={activity.img_url}
                togglePlayer={closeTheDocument} />
        }
    }

    const onPressMediaCell = (item: any, itemColor: string) => {
        trackEvent(analyticsEventMediaLaunch);
        if (item.type === "multi_formats") {
            setItemSelected(item);
        } else {
            setMediaType(item.type);
            if (item.type === "pdf") {
                setStartTime(new Date().getTime());
                let pdfUrl = item.url;
                const appTarget = getAppTarget();
                if (appTarget !== "public") {
                    pdfUrl = getDocumentUrlForMediaId({ media_id: item.id });
                }
                setDocumentData({
                    url: pdfUrl,
                    title: item.title[getLanguage()],
                    color: itemColor
                });
                setShowMedia(true);
            } else if (item.type === "preview") {
                setStartTime(new Date().getTime());
                setPreviewData({
                    title: item.title[getLanguage()],
                    img: item.img,
                    age: item.age,
                    id: item.id,
                    owner: item.owner,
                    body: item.body,
                    author: item.author,
                    color: itemColor
                })
                setShowMedia(true);
            } else if (item.type === "video") {
                const isPublicApp = getAppTarget();
                let urlToLaunch = item.url;
                if (isPublicApp !== "public") {
                    urlToLaunch = getVideoUrlForMediaId({ media_id: item.id });
                }
                setDocumentData({
                    url: urlToLaunch,
                    title: item.title[getLanguage()],
                    color: itemColor
                });
                setShowMedia(true);
            } else if (item.type === "audio") {
                const isPublicApp = getAppTarget();
                let urlToLaunch = item.url;
                if (isPublicApp !== "public") {
                    urlToLaunch = getAudioUrlForMediaId({ media_id: item.id });
                }
                setDocumentData({
                    url: urlToLaunch,
                    title: item.title[getLanguage()],
                    color: itemColor
                });
                setShowMedia(true);
            }
        }
    }

    const getMediaCell = () => {
        if (media.type === "multi_formats") {
            return (
                <ActivityMultiFormatCell
                    screenWidth={width}
                    displayActivities={isEnable}
                    item={media}
                    color={color}
                    onPress={onPressMediaCell}
                    showPremiumAlert={showPremiumAlert} />
            )
        }
        return (<ActivityMediaCell
            screenWidth={width}
            displayActivities={isEnable}
            item={media}
            color={color}
            onPress={onPressMediaCell}
            showPremiumAlert={showPremiumAlert} />)
    }

    return <View>
        {getMediaCell()}
        <Modal animationType="slide" visible={showMedia} transparent={true} style={{ width: appTheme.getFullScreenWidth() }}>
            <View style={{ flex: 1, alignItems: "center", backgroundColor: color + "80", width: appTheme.getFullScreenWidth() }}>
                <View style={{ flex: 1, justifyContent: "flex-end", backgroundColor: color + "80", width: appTheme.getFullAppWidth() }}>
                    {getMediaModalContent()}
                </View>
            </View>
        </Modal>
        <Modal animationType="slide" visible={showChoices} transparent={true} style={{ width: appTheme.getFullScreenWidth() }}>
            <View style={{ flex: 1, alignItems: "center", backgroundColor: appTheme.white + "80", width: appTheme.getFullScreenWidth() }}>
                <View style={{ flex: 1, justifyContent: "flex-end", alignItems: "center", width: appTheme.getFullAppWidth() }}>
                    {getItemChoices()}
                </View>
            </View>
        </Modal>
    </View>
}

export default ActivityCellMedia;