import React, { useState } from "react";

import {
    Linking,
    Modal,
    Platform,
    Text,
    View
} from "react-native";
import Button from "../../../designSystem/Button";
import PremiumButton from "../../../designSystem/PremiumButton";
import Activity from "../../../models/Activity";
import { getLanguage } from "../../../utils/Localization/Localization";
import AppTheme from "../../../utils/Theme";
import GamePlay from "../../Game/GamePlay";
import { trackEvent, analyticsEventActivityLaunch, propertiesEventContext, propertiesEventContent, propertiesEventAccess, propertiesEventCategory, propertiesEventCompleted, propertiesEventSection, propertiesEventName, analyticsEventActivityCompleted, analyticsEventActivityDownloaded, propertiesEventProfile, analyticsEventWorkshopLaunched } from "../../../services/Tracking/Analytics";
import User from "../../../data/user/User";
import TutorialPager from "../../Tutorials/TutorialPager";
import LiliWebView from "../../../../specific/components/Webview/LiliWebView";
import LiliVideoPlayer from "../../../../specific/components/VideoPlayer/LiliVideoPlayer";
import { checkLocalUrl } from "../../../utils/LocalStorage";
import { getAppTarget } from "../../../../specific/utils/Navigation/Host";
import { getVideoUrlForMediaId } from "../../../../specific/services/AppWrite/AppWriteDatabase";

import RoundIconButton from "../../../designSystem/RoundIconButton";
import { default as IcDraw } from "../../../assets/svg/lili/ic_draw.svg";
import { default as IcPlay } from "../../../assets/svg/lili/ic_play.svg";
import { default as IcOrigami } from "../../../assets/svg/lili/ic_origami.svg";
import Game from "../../Game/Game";

type localizedString = {
    fr?: string
}

interface ActivityCellActionGameProps {
    activity: Activity,
    isEnable?: boolean,
    componentId?: string,
    title: localizedString,
    color: string,
    width: number,
    categoryAnalyticsKey: string,
    sectionAnalyticsKey: string,
    context: string,
    sub_type: string,
    rules?: string[],
    starting_point?: "superpower" | "section"
}

const ActivityCellActionGame = (props: ActivityCellActionGameProps) => {
    const { isEnable = false, color, width, componentId = "", title, activity, context, categoryAnalyticsKey, sectionAnalyticsKey, sub_type, rules, starting_point="superpower" } = props;

    const appTheme: AppTheme = new AppTheme();
    const user: User = User.getInstance();

    const [launchGame, setLaunchGame] = useState<boolean>(false);

    const launchTheGame = () => {
        trackEvent(analyticsEventActivityLaunch, {
            [propertiesEventCategory]: categoryAnalyticsKey,
            [propertiesEventContent]: sub_type,
            [propertiesEventAccess]: activity.isPremium() === true ? "premium" : "free",
            [propertiesEventName]: activity.analytics_key,
            "starting_point":starting_point
        });
        if ((sub_type === "web") && (Platform.OS === "web")) {
            const appTarget = getAppTarget();
            if ((appTarget === "gar-prod") || (appTarget === "gar-preprod")) {
                const contactUrlForGar = "https://sortie-confiance.gar.education.fr?idRessource=ark%3A%2F19153%2Frcqbq94w21%2Fs3&IDO=" + user.tne_ido + "&urlDest=" + encodeURI(activity.action.url);
                Linking.openURL(contactUrlForGar);
            } else {
                Linking.openURL(activity.action.url);
            }
        } else {
            setLaunchGame(true);
        }
    }

    const onGameCompleted = () => {
        trackEvent(analyticsEventActivityCompleted, {
            [propertiesEventCategory]: categoryAnalyticsKey,
            [propertiesEventContent]: sub_type,
            [propertiesEventAccess]: activity.isPremium() === true ? "premium" : "free",
            [propertiesEventName]: activity.analytics_key,
            "starting_point":starting_point
        });
    }

    const closeTheGame = () => {
        setLaunchGame(false);
    }

    const getGameContent = () => {
        if (sub_type === "game") {
            return <Game
                onGameCompleted={onGameCompleted}
                closeTheGame={closeTheGame}
                activity={activity}
                color={color} />;
            return <GamePlay
                onGameCompleted={onGameCompleted}
                closeTheGame={closeTheGame}
                activity={activity}
                color={color} />;
        } else if (sub_type === "tuto") {
            return <TutorialPager
                onTutorialCompleted={onGameCompleted}
                rules={rules}
                closeTheTutorial={closeTheGame}
                didAppear={launchGame}
                activity={activity}
                color={color} />
        } else if (sub_type === "web") {
            return <LiliWebView color={color} closeTheDocument={closeTheGame} title="" url={activity.action.url} />
        } else if (sub_type === "video") {
            let videoUrl = activity.action.url;
            const appTarget = getAppTarget();
            if (appTarget !== "public") {
                videoUrl = getVideoUrlForMediaId({ media_id: activity.key });
            }
            return <LiliVideoPlayer
                onVideoCompleted={onGameCompleted}
                url={checkLocalUrl(videoUrl)}
                closeTheDocument={closeTheGame} />
        }
    }

    let actionButton = <PremiumButton componentId={componentId} />;
    if (isEnable === true) {
        actionButton = <Button onPress={launchTheGame} title={title[getLanguage()]} style={{ minWidth: appTheme.pixelPerfect(140), backgroundColor: color, marginHorizontal: appTheme.pixelPerfect(20), marginBottom: appTheme.pixelPerfect(10) }} />;
        if (sub_type === "game") {
            actionButton = <RoundIconButton onPress={launchTheGame} iconName={IcPlay} backgroundColor={color} />;
        } else if (sub_type === "tuto") {
            if (categoryAnalyticsKey === "DRAWS") {
                actionButton = <RoundIconButton onPress={launchTheGame} iconName={IcDraw} backgroundColor={color} />;
            } else if (categoryAnalyticsKey === "ORIGA") {
                actionButton = <RoundIconButton onPress={launchTheGame} iconName={IcOrigami} backgroundColor={color} />;
            }
        }
    }

    return <View style={{ width, alignItems: "center", marginTop: appTheme.pixelPerfect(10) }}>
        {actionButton}
        <Modal animationType="slide" visible={launchGame} transparent={true} style={{ width: appTheme.getFullScreenWidth(), height: appTheme.getFullAppHeight() }}>
            <View style={{ flex: 1, alignItems: "center", backgroundColor: appTheme.daySkyColor, width: appTheme.getFullScreenWidth(), height: appTheme.getFullAppHeight() }}>
                {getGameContent()}
            </View>
        </Modal>
    </View>
}

export default ActivityCellActionGame;