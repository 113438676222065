import React from "react";
import { useState, useEffect } from "react";
import {
    View,
    StyleSheet,
    Modal,
    Text,
    TouchableOpacity
} from 'react-native';
import * as Navigation from "../../utils/Navigation/Navigation";

export const initNotificationService = async () => {
}

const Notifications = (props: any) => {

    const { componentId } = props;
    const closeView = () => {
        Navigation.dismissModalInView({
            componentId
        });
    }

    return <View />
}

export default Notifications;

const styles = StyleSheet.create({

});