import React, { useState } from "react";

import {
    ActivityIndicator,
    Dimensions,
    Platform,
    StyleSheet,
    Text,
    View,
    Image,
    TouchableOpacity,
    FlatList
} from "react-native";
import { appUrl } from "../../utils/Constants";
import AppTheme from "../../utils/Theme";
import ActivityMediaCell from "../Activities/ActivityMediaCell";
import SVGView from "../../utils/SvgView";
import { default as IcPlay } from "../../assets/svg/icons/solid/play.svg";
import { getLanguage } from "../../utils/Localization/Localization";
import AudioPlayer from "../../../specific/utils/AudioPlayer/AudioPlayer";
import { getAppTarget } from "../../../specific/utils/Navigation/Host";
import { getChapterAudioIdForActivityId, getDocumentUrlForActivityId } from "../../../specific/services/AppWrite/AppWriteDatabase";

interface AudioChapterProps {
    onSelectionDone: (data: any) => void,
    chapters: string[],
    urls: string[],
    localImage?: string,
    remoteUrl?: string,
    title: string,
    color: string,
    navColor?: string,
    activityId: string
}

const AudioChapter = (props: AudioChapterProps) => {

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const appTheme: AppTheme = new AppTheme();

    const screenWidth: number = Platform.OS === "web" ? appTheme.getBlockWidth() : appTheme.getFullAppWidth();

    const { chapters, urls, onSelectionDone, localImage, remoteUrl, title, color, activityId } = props;
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    const onViewWillAppear = () => {
        setViewDidAppear(true);
    }

    const onPress = (index: string) => {
        setSelectedIndex(parseInt(index));
    }

    const mobileMediaCell = ({ item, index }) => {
        const isFirst = parseInt(index) === 0;
        const isLast = parseInt(index) + 1 === urls.length;
        return <View style={{ alignItems: "center" }}>
            {mediaCell({ media: item, isFirst, isLast, index })}
        </View>
    }

    const onActivityCompletedForGamification = () => {

    }

    const onPlayerEnd = (index: number) => {
        if (urls[index + 1] !== undefined) {
            setSelectedIndex(index + 1);
        } else {
            setSelectedIndex(-1);
        }
    }

    const stopRequest = () => {

    }

    const getAudioPlayerView = (data: { url: string, index: number }) => {
        const { url, index } = data;
        return <View style={{ justifyContent: "center", alignItems: "center" }}>
            <AudioPlayer
                size={appTheme.pixelPerfect(48)}
                onActivityCompleted={onActivityCompletedForGamification}
                percentageForCompletion={70}
                onPlayerEnd={() => onPlayerEnd(index)}
                stopRequest={false}
                autoLaunch={true}
                color={props.color}
                urls={[url]}
                title={props.title !== undefined ? props.title : "Lili"}
                image={props.remoteUrl} />
        </View>
    }

    const mediaCell = (data: { media: any, isFirst: boolean, isLast: boolean, index: string }) => {
        const { media, isFirst, isLast, index } = data;

        const playButtonSize = appTheme.pixelPerfect(48);
        const playButtonMargin: number = appTheme.pixelPerfect(6);
        const nextButtonSize: number = playButtonSize - 2 * playButtonMargin;
        const bottomLine = isLast === true ? <View /> : <View style={{ position: "absolute", width: 1, height: playButtonSize, bottom: 0, left: playButtonSize / 2, backgroundColor: color }} />;
        const topLine = isFirst === true ? <View /> : <View style={{ position: "absolute", width: 1, height: playButtonSize, top: 0, left: playButtonSize / 2, backgroundColor: color }} />;
        const mediaCellWidth = Platform.OS === "web" ? appTheme.getBlockWidth() : appTheme.getFullAppWidth();

        let playerButton = <TouchableOpacity onPress={() => onPress(index)} style={{ marginStart: playButtonMargin, borderWidth: 1, borderColor: color, width: nextButtonSize, height: nextButtonSize, borderRadius: nextButtonSize / 2, justifyContent: "center", alignItems: "center", backgroundColor: appTheme.white, marginEnd: appTheme.pixelPerfect(15) }}>
            <SVGView size={appTheme.pixelPerfect(14)} Component={IcPlay} color={color} />
        </TouchableOpacity>;
        let titleSize: number = appTheme.pixelPerfectForFont(9);
        let titleColor: string = color;
        if (selectedIndex === parseInt(index)) {
            titleSize = appTheme.pixelPerfectForFont(10);
            titleColor = color;
            playerButton = <View style={{ marginEnd: appTheme.pixelPerfect(15) }}>
                {getAudioPlayerView({ url: media.url, index: parseInt(index) })}
            </View>
        }
        let authorView = <View />;
        if (media.chapterBody.length > 0) {
            authorView = <Text style={{ width: "100%", textAlign: "left", color: appTheme.darkBlue, opacity: 1, fontFamily: appTheme.primarySemiBoldFont, fontSize: titleSize }}>{media.chapterBody}</Text>
        }
        const marginBottom: number = isLast === true ? appTheme.pixelPerfect(40) + appTheme.bottomInsets : 0;
        return <View style={{ marginBottom, flexDirection: "row", height: appTheme.pixelPerfect(70), justifyContent: "flex-start", alignItems: "center", width: mediaCellWidth * 0.9 }}>
            {topLine}
            {bottomLine}
            {playerButton}
            <View style={{ flex: 1, paddingRight: appTheme.pixelPerfect(10), alignItems: "center", justifyContent: "center" }}>
                <Text numberOfLines={3} style={{ width: "100%", textAlign: "left", color: titleColor, opacity: 1, fontFamily: appTheme.primaryBoldFont, fontSize: titleSize }}>{media.chapterTitle}</Text>
                {authorView}
            </View>
        </View>
    }

    const getChapterList = () => {
        const chaptersData = [];
        for (const aUrlIndex in urls) {
            if (Object.prototype.hasOwnProperty.call(urls, aUrlIndex)) {
                let anAudioUrl: string = urls[aUrlIndex];
                const appTarget = getAppTarget();
                if (appTarget !== "public") {
                    anAudioUrl = getChapterAudioIdForActivityId({ activity_id: activityId, index: aUrlIndex });
                }
                const chapterNumber: string = (parseInt(aUrlIndex) + 1).toString();
                let chapterTitle: string = "Chapitre " + chapterNumber;
                if ((chapters[aUrlIndex] !== undefined) && (chapters[aUrlIndex].title !== undefined) && (chapters[aUrlIndex].title[getLanguage()] !== undefined)) {
                    chapterTitle = chapters[aUrlIndex].title[getLanguage()];
                }
                let chapterBody: string = ((chapters[aUrlIndex] !== undefined) && (chapters[aUrlIndex].body !== undefined) && (chapters[aUrlIndex].body[getLanguage()] !== undefined)) ? chapters[aUrlIndex].body[getLanguage()] : "";
                chaptersData.push({
                    url: anAudioUrl,
                    chapterTitle,
                    chapterBody
                })
            }
        }

        if (Platform.OS === "web") {
            let mediaListView = [];
            for (const aMediaIndex in chaptersData) {
                if (Object.prototype.hasOwnProperty.call(chaptersData, aMediaIndex)) {
                    const aMedia = chaptersData[aMediaIndex];
                    const isFirst = parseInt(aMediaIndex) === 0;
                    const isLast = parseInt(aMediaIndex) + 1 === chaptersData.length;
                    mediaListView.push(mediaCell({ media: aMedia, isFirst, isLast, index: aMediaIndex }));
                }
            }
            return <View style={{ width: appTheme.getBlockWidth(), alignItems: "center" }}>
                <View style={{ marginTop: appTheme.pixelPerfect(20), width: appTheme.getBlockWidth(), alignItems: "center" }}>
                    {mediaListView}
                </View>;
            </View>
        }
        return <FlatList
            data={chaptersData}
            style={{ width: appTheme.getFullAppWidth() }}
            renderItem={mobileMediaCell}
            keyExtractor={(index, item) => item.chapterTitle} />
    }

    let imageWidth: number = screenWidth;
    let imageHeight: number = 270 * imageWidth / 360;
    if (imageHeight > appTheme.pixelPerfect(200)) {
        imageHeight = appTheme.pixelPerfect(200);
        imageWidth = 360 * imageHeight / 270;
    }
    return <View style={[styles.container, { width: screenWidth }]}>
        <View style={{ marginBottom: appTheme.pixelPerfect(10), width: screenWidth, height: imageHeight, backgroundColor: "#FFFFFF", alignItems: "center", paddingVertical: appTheme.pixelPerfect(10), borderRadius: appTheme.pixelPerfect(10) }}>
            <Image source={{ uri: remoteUrl }} style={{ width: imageWidth, height: imageHeight, borderRadius: appTheme.pixelPerfect(10) }} resizeMode="cover" />
        </View>
        {getChapterList()}
    </View>

}

export default AudioChapter;

const styles = StyleSheet.create({
    container: {
        justifyContent: "center",
        alignItems: "center"
    }
})