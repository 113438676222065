import React, { useState } from "react";

import {
    View,
    Text,
    TouchableOpacity,
    Platform
} from "react-native";
import ComponentProps from "../../../specific/utils/Interfaces/ComponentProps";
import Activity from "../../models/Activity";
import Button from "../../designSystem/Button";
import AppTheme from "../../utils/Theme";
import GameStyle from "../../styles/game/GameStyle";
import ActivityIndicator from "../../designSystem/ActivityIndicator/ActivityIndicator";
import GameBackground from "./GameBackground";
import CloseButton from "../../designSystem/Buttons/CloseButton";
import GameIntroduction from "./GameIntroduction";
import GameLaunchCountdown from "./GameLaunchCountdown";
import { playSound } from "../../../specific/utils/AudioPlayer/SimpleSoundPlayer";
import { getLanguage } from "../../utils/Localization/Localization";
import GameBoard from "./GameBoard";
import LiliAirPlayButton from "../../../specific/utils/AudioPlayer/AirPlayButton";

interface GameProps {
    onGameCompleted: () => void,
    closeTheGame: () => void,
    activity: Activity,
    color: string
}

const Game = (props: GameProps) => {

    const appTheme: AppTheme = new AppTheme();
    const gameStyle: GameStyle = new GameStyle();

    const { activity, color, closeTheGame, onGameCompleted } = props;
    const [step, setStep] = useState<number>(0);

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);

    const onViewDidAppear = () => {
        setViewDidAppear(true);
    }

    const onCloseRequest = () => {
        closeTheGame();
    }

    const playASound = async (soundName: string, source?: string) => {
        const soundSource = source !== undefined ? source : "app";
        await playSound(soundName, soundSource);
    }

    const resetGame = () => {
        setStep(1);
    }

    const onPauseRequest = () => {

    }

    const getContent = () => {
        const closeButton = <View style={gameStyle.closeButtonView}>
            <CloseButton onPress={onCloseRequest} color={appTheme.talk} />
        </View>;
        //Bouton airplay
        const leftPosition = appTheme.pixelPerfect(10);
        const iosAirPlayLeftPosition = step === 0 ? leftPosition : (leftPosition + appTheme.pixelPerfect(60));
        const iosAirPlayButton = (Platform.OS === "ios") ? <View style={[{ position: 'absolute', top: appTheme.pixelPerfect(10), left: iosAirPlayLeftPosition, backgroundColor: appTheme.white, borderRadius: appTheme.pixelPerfect(20), height: appTheme.pixelPerfect(40), width: appTheme.pixelPerfect(40), justifyContent: 'center', alignItems: 'center' }]}>
            <LiliAirPlayButton
                activeTintColor={appTheme.talk}
                tintColor={appTheme.darkBlue} />
        </View> : <View />;
        const aTitleView: JSX.Element = <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Text style={{ fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(16), color, textAlign: 'center' }}>{activity.title[getLanguage()].toLocaleUpperCase()}</Text>
        </View>;
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear} style={gameStyle.mainView}>
                <ActivityIndicator loading={viewDidAppear === false} color={appTheme.white} />
                {closeButton}
            </View>
        }
        if (step === 0) {
            return <View style={gameStyle.mainView}>
                <GameIntroduction titleView={aTitleView} onPlayerEnd={() => setStep(1)} activity={activity} color={color} />
                {iosAirPlayButton}
                {closeButton}
            </View>
        } else if (step === 1) {
            return <View style={gameStyle.mainView}>
                <GameLaunchCountdown playASound={playASound} onCountdownEnd={() => setStep(2)} color={color} />
                {closeButton}
            </View>
        } else if (step === 2) {
            return <View style={gameStyle.mainView}>
                <GameBoard iosAirPlayButton={iosAirPlayButton} resetGame={resetGame} playASound={playASound} titleView={aTitleView} onGameCompleted={onGameCompleted} onGameEnd={onCloseRequest} activity={activity} color={color} />
            </View>
        }

    }

    return <View style={gameStyle.mainContainer}>
        <GameBackground />
        {getContent()}
    </View>

}

export default Game;