import React from "react";
import { useState, useEffect, useRef } from "react";

// On importe les éléments spécifique au web et au mobile
import ComponentProps from "../../../specific/utils/Interfaces/ComponentProps";
import * as Navigation from "../../../specific/utils/Navigation/Navigation";
import Main from "../../../specific/components/Main/Main";

import {
    View,
    Platform,
    Linking,
    Animated,
    EmitterSubscription
} from 'react-native';

import AppTheme from "../../utils/Theme";
import * as Constants from "../../utils/Constants";
import MainOptions from "../../models/MainOptions";

// Assets
import Images from "../../../specific/utils/Images";
import Image from "../../designSystem/OTBImage";

// Services
import { isConnnected, signOut } from "../../services/Auth";
import { getUserWithId, getUserActions, launchUserListener, revokeDBListeners } from "../../services/Database";

// Utils
import Alert from "../Notification/Alert";
import * as DateUtils from "../../utils/DateUtils";

import { EventRegister } from "../../utils/EventListeners";

import User from "../../data/user/User";

import { initInAppPurchaseSDK } from "../../../specific/services/InAppPurchase/InAppPurchaseService";
import { initAnalyticsServices, initUserIdForAnalytics, revokeAnalyticsServices, setUserProperty } from "../../services/Tracking/Analytics";

import { Location, NavigateFunction, useLocation, useNavigate, useParams } from "react-router-dom";
import { buildLocalDB, getLocalActivities, getLocalCategories, getLocalGameLabels, getLocalQuotes, getLocalStorage, removeItemFromLocalStorage } from "../../../specific/utils/LocalStorage";
import { requestTrackingTransparencyStatus } from "../../services/Tracking/TrackingTransparency";
import { handleUrl } from "../../../specific/utils/Deeplink/Deeplink";
import { playSound } from "../../../specific/utils/AudioPlayer/SimpleSoundPlayer";
import { activitiesListener, categoriesListener, checkMenuSettings, checkVersionUpgrade, gameLabelListener, initFirebase, openAppOnAppStore, quotesListener, updateActions, updateLater, userActionsListener } from "../../../specific/services/Specific_Database";
import { revokeSoundPlayerSubscriptions } from "../../../specific/utils/AudioPlayer/SimpleSoundPlayerService";

// Data
import { relaunchLostDownloads } from "../../../specific/components/Download/DownloadButton";
import { hideNativeSplashscreen } from "../../../specific/utils/NativeSplashScreen/NativeSplashScreen";
import { initNotificationService } from "../../../specific/services/Notifications/Notifications";
import Style from "../../utils/Style";
import Footer from "../../designSystem/Footer/Footer";
import ActivityIndicator from "../../designSystem/ActivityIndicator/ActivityIndicator";
import Divider from "../../designSystem/Divider/Divider";
import DeepLink from "../../services/DeepLink/DeepLink";

let isUserConnected = false;
//let isPushNotificationDefined = false;

export const logoutUser = () => {
    isUserConnected = false;
}

interface SplashscreenProps extends ComponentProps {

}

const Splashscreen = (props: SplashscreenProps) => {

    const appTheme: AppTheme = new AppTheme();

    // PROPS
    const { componentId = "" } = props;

    let navigate: NavigateFunction | null = null;
    let location: Location | null = null;
    if (Platform.OS === "web") {
        navigate = useNavigate();
        location = useLocation();
    }

    // Animation
    const [isAnimationOver, setIsAnimationOver] = useState(false);
    const [fadeAnim] = useState(new Animated.Value(0));
    const [mvmtAnim] = useState(new Animated.Value(appTheme.pixelPerfect(-40)));
    const [fadeFinalDisappearAnim] = useState(new Animated.Value(1));
    const [fadeFinalAppearAnim] = useState(new Animated.Value(0));

    const images: Images = new Images();
    const user: User = User.getInstance();
    const alertRef = useRef<Alert>(null);

    const onViewDidAppear = () => {
        if (isAnimationOver === false) {
            launchSplashcreenAnimation();
        }
    }

    useEffect(() => {
        if (isAnimationOver === true) {
            launchAppCheck()
        }
    }, [isAnimationOver]);

    useEffect(() => {
        hideNativeSplashscreen();
        // Universal Links
        let linkingSubscription: EmitterSubscription | undefined = undefined;
        if (Platform.OS !== "web") {
            linkingSubscription = Linking.addEventListener('url', (event) => {
                if (isUserConnected === true) {
                    handleUrl({ url: event.url, isLaunchedUrl: false, componentId, navigate });
                } else {
                    const deepLink: DeepLink = DeepLink.getInstance();
                    deepLink.path = event.url;
                }
            });
        }
        EventRegister.addEventListener(Constants.EventGRPDConsentDone, () => {
            setTimeout(() => {
                checkGpdrConsent();
            }, 1500);
        });
        EventRegister.addEventListener(Constants.EventRelaunchApp, () => {
            setTimeout(() => {
                checkGpdrConsent();
            }, 1500);
        });
        EventRegister.addEventListener(Constants.EventNotificationChoiceDone, (userData) => {
            setTimeout(() => {
                launchApp();
            }, 1500);
        });
        return () => {
            if (isAnimationOver === true) {
                revokeSoundPlayerSubscriptions();
                revokeAnalyticsServices();
                revokeDBListeners();
                EventRegister.removeEventListener(Constants.EventUserAuthDB);
                if (Platform.OS !== "web") {
                    if (linkingSubscription !== undefined) {
                        Linking.removeSubscription(linkingSubscription);
                    }
                }
            }
        };
    }, []);

    const launchAppCheck = async () => {
        if (Platform.OS === "android") {
            await initFirebase();
        }
        checkGpdrConsent();
    }

    const checkUpradeNotification = async () => {
        const versionNeedUpgrade = await checkVersionUpgrade();
        const { updateUpgradeRequest, details } = versionNeedUpgrade;
        if (updateUpgradeRequest === true) {
            if (details.force === true) {
                alertRef.current?.showAlert({
                    title: details.updateTitle,
                    body: details.updateMsg,
                    primaryButtonTitle: "Mettre à jour",
                    onPressPrimaryButton: openAppOnAppStore,
                    hideTheAlertOnOptionPressed: false
                });
            } else {
                alertRef.current?.showAlert({
                    title: details.updateTitle,
                    body: details.updateMsg,
                    primaryButtonTitle: "Mettre à jour",
                    onPressPrimaryButton: openAppOnAppStore,
                    secondaryButtonTitle: "Plus tard",
                    onPressSecondaryButton: updateTheAppLater
                });
            }
        }
    }

    const updateTheAppLater = () => {
        updateLater()
        checkGpdrConsent();
    }

    const checkNotificationInitialisation = async () => {
        const notificationStatus = await getLocalStorage({ key: Constants.keyNotificationData });
        const lastPushToken = await initNotificationService();
        if (notificationStatus === null) {
            if (lastPushToken !== undefined) {
                isPushNotificationDefined = true;
            }
        } else {
            const notificationStatusData: { status: string, ts: number } = JSON.parse(notificationStatus);
            if (notificationStatusData.status === "later") {
                // On va regarder si cela fait plus de X qu'on lui a demandé 
                const now = new Date().getTime();
                const timeSinceLastRequest = now - notificationStatusData.ts;
                const differenceNeeded = 3 * 24 * 60 * 60 * 1000;
                if (timeSinceLastRequest < differenceNeeded) {
                    isPushNotificationDefined = false;
                } else {
                    isPushNotificationDefined = true;
                }
            }
        }
    }

    const checkGpdrConsent = async () => {
        try {
            const gdprConsentValue = await getLocalStorage({ key: Constants.keyGdprConsent });
            if (gdprConsentValue === null) {
                launchGdprConsent();
            } else {
                const gdprData = JSON.parse(gdprConsentValue);
                const now = new Date().getTime();
                const numberOfMonth = DateUtils.monthDiff(now, gdprData.ts);
                if (numberOfMonth > 12) {
                    // Cela fait plus de 13 mois que nous avons demandé le consentement, il faut recommencer.
                    launchGdprConsent();
                } else {
                    checkUserConnexion();
                    initAnalyticsServices();
                    if (Platform.OS === "ios") {
                        // Nous allons demander l'autorisation d'utiliser l'IDFA
                        await requestTrackingTransparencyStatus();
                    }
                }
            }
        } catch (error) {
            launchGdprConsent();
        }
    }

    const launchGdprConsent = () => {
        Navigation.showModalInView({
            componentId,
            name: Constants.ScreenConsentNotification,
            options: {
                modalPresentationStyle: 'overCurrentContext',
                layout: {
                    componentBackgroundColor: 'transparent'
                }
            }
        });
    }

    const checkUserConnexion = async () => {
        const userAuthData: { email: string | null | undefined, uid: string | undefined } | null = await isConnnected();
        if ((userAuthData !== null) && (userAuthData.uid !== undefined)) {
            isUserConnected = true;
            initUserIdForAnalytics(userAuthData.uid);
            await user.setAuthUserData(userAuthData);
            EventRegister.emit(Constants.EventUserAuthDB, userAuthData);
            // On va transmettre les informations de l'utilisateur
            const offlineUserData = await user.initUserData(userAuthData);
            if (offlineUserData === undefined) {
                await signOut();
                checkUserConnexion();
            } else {
                const dbUSerData = await getUserData(userAuthData);
                if (dbUSerData === undefined) {
                    await signOut();
                    checkUserConnexion();
                } else {
                    if (Platform.OS !== "web") {
                        getAppData();
                        relaunchLostDownloads();
                    }
                    if (Platform.OS !== "web") {
                        initInAppPurchaseSDK();
                    }
                    await user.getUserSubForRC();
                    enterAppOnceConnected(offlineUserData);
                    checkMenuSettings();
                    checkUpradeNotification();
                }
            }
        } else {
            isUserConnected = false;
            launchAuthLanding();
        }
    }

    const enterAppOnceConnected = (offlineUserData) => {
        // On va regarder si l'URL est splaschreen ou deeplink
        if (Platform.OS === "web") {
            if (location.pathname.indexOf("deeplink") !== -1) {
                let urlToNavigate = location.pathname.replace("/deeplink/", "");
                navigate(decodeURIComponent(urlToNavigate), { state: "deeplink" });
            } else {
                launchApp();
            }
        } else {
            launchApp();
        }
    }

    const getAppData = async () => {
        const localActivities = await getLocalActivities();
        activitiesListener();
        const localCategories = await getLocalCategories();
        categoriesListener();
        const localGameLabels = await getLocalGameLabels();
        gameLabelListener();
        //const localQuotes = await getLocalQuotes();
        //quotesListener({ ts: localQuotes.ts });
        buildLocalDB(localActivities.data);
    }

    const getUserData = async (userAuthData: { email: string | null | undefined, uid: string | undefined } | null) => {
        try {
            // On récupère les informations du profil utilisateur
            const userData = await getUserWithId({ user_id: userAuthData?.uid });
            if (userData === undefined) {
                return undefined;
            }
            await user.setDBUserData(userData);
            const userProfileForAnalytics = user.getUserProfileForAnalytics();
            if ((userProfileForAnalytics !== undefined) && (userProfileForAnalytics !== null)) {
                setUserProperty({ key: "profile", value: userProfileForAnalytics });
            }
            // On va regarder si l'utilisateur est premium
            const userSubStatusForAnalytics = user.getUserSubscriptionStatusForAnalytics();
            setUserProperty({ key: "subscription_status", value: userSubStatusForAnalytics.subStatus });
            setUserProperty({ key: "subscription_end_date", value: userSubStatusForAnalytics.endDate });
            EventRegister.emit(Constants.EventUserAuthDB, userData);
            // On récupère les informaitons de gamification
            let userActions = await getUserActions({ user_id: userAuthData.uid });
            if (userActions !== undefined) {
                // On va regarder si l'utilisateur gagne un jour de plus
                const today = new Date().getTime();
                let lastConnexionDate = DateUtils.getNumericDateAsIntForTimestamp(today);
                let daysInARow = userActions.days_in_a_row !== undefined ? parseInt(userActions.days_in_a_row) : 1;
                let rewards = [];
                let pts = userActions.pts;
                if (userActions.last_connexion_date !== undefined) {
                    const lastDay = parseInt(userActions.last_connexion_date);
                    const diffBetweenConnexion = lastConnexionDate - lastDay;
                    if (diffBetweenConnexion === 1) {
                        daysInARow = daysInARow + 1;
                        const dayPts = daysInARow > 2 ? 30 : 10;
                        rewards.push({
                            type: 'modal',
                            action: 'days_in_a_row',
                            number: daysInARow,
                            pts: dayPts
                        });
                        pts = pts + dayPts;
                    } else if (diffBetweenConnexion > 1) {
                        daysInARow = 1;
                    }
                }
                await updateActions({ pts, last_connexion_date: lastConnexionDate, days_in_a_row: daysInARow });
                userActions.days_in_a_row = daysInARow;
                userActions.last_connexion_date = lastConnexionDate;
                userActions.pts = pts;
                await user.setActionsUserData(userActions);
                EventRegister.emit(Constants.EventUserAuthDB, userData);
                userActionsListener({ user_id: userAuthData?.uid });
            }
            launchUserListener({ user_id: userAuthData.uid });
            return userData;
        } catch (error) {
            throw (error);
        }

    }

    const launchApp = async () => {
        const initialUrl: string | null = await Linking.getInitialURL();
        if (initialUrl !== null) {
            const deepLink: DeepLink = DeepLink.getInstance();
            deepLink.path = initialUrl;
        }
        const user: User = User.getInstance();
        let context = Constants.CONTEXT.family;
        if (user.profile === "teacher") {
            context = Constants.CONTEXT.teacher;
        }
        Navigation.showModal({
            componentId,
            navigate,
            name: Constants.ScreenAppMenu,
            passProps: {
                context
            }
        });
    }

    const launchAuthLanding = () => {
        Navigation.showModal({
            componentId,
            navigate,
            name: Constants.ScreenAuthLanding,
            passProps: {
            }
        });
    }

    // MainOptions
    const mainOptions: MainOptions = new MainOptions({
        key: "splashscreen",
        componentId,
        showNavBar: false,
        showMenu: false,
        canGoBack: false,
        safeArea: false
    });

    const topPosition = (appTheme.getFullAppHeight() - appTheme.pixelPerfect(67)) / 2;
    const center = (appTheme.getFullScreenWidth() - appTheme.pixelPerfect(121)) / 2;
    const launchSplashcreenAnimation = () => {
        playSound("lili_bienvenue.mp3", "app", true);
        Animated.parallel([
            Animated.timing(fadeAnim, {
                toValue: 1,
                duration: 1000,
                useNativeDriver: false
            }),
            Animated.timing(mvmtAnim, {
                toValue: center,
                duration: 1000,
                useNativeDriver: false
            })
        ]).start(() => {
            Animated.timing(fadeAnim, {
                toValue: 0,
                duration: 1000,
                useNativeDriver: false
            }).start(() => {
            });
            Animated.timing(fadeFinalAppearAnim, {
                toValue: 1,
                duration: 1000,
                useNativeDriver: false
            }).start(() => {
            });
            Animated.timing(fadeFinalDisappearAnim, {
                toValue: 0,
                duration: 1000,
                useNativeDriver: false
            }).start((event) => {
                if (event.finished === true) {
                    setTimeout(() => {
                        setIsAnimationOver(true);
                    }, 1000);
                }
            });
        });
    };

    const getContentView = () => {
        if (isAnimationOver === false) {
            return <View onLayout={onViewDidAppear} style={{ width: appTheme.getFullScreenWidth(), height: appTheme.getFullScreenHeight(), backgroundColor: appTheme.still, justifyContent: "center", alignItems: "center" }}>
                <Animated.Image source={images.icCloud} style={{ left: mvmtAnim, position: "absolute", top: topPosition, width: appTheme.pixelPerfect(121), height: appTheme.pixelPerfect(67), opacity: fadeAnim }} resizeMode="contain" />
                <Animated.Image source={images.icTalkEyes} style={{ position: "absolute", top: topPosition, width: appTheme.pixelPerfect(121), height: appTheme.pixelPerfect(67), opacity: fadeFinalDisappearAnim }} resizeMode="contain" />
                <Animated.Image source={images.icTalk} style={{ left: center, position: "absolute", top: topPosition, width: appTheme.pixelPerfect(121), height: appTheme.pixelPerfect(67), opacity: fadeFinalAppearAnim }} resizeMode="contain" />
                <Animated.Text style={{ position: "absolute", top: topPosition + appTheme.pixelPerfect(30), left: center - appTheme.pixelPerfect(6), letterSpacing: appTheme.pixelPerfectForFont(2), fontFamily: appTheme.primaryMediumFont, fontSize: appTheme.pixelPerfect(56), opacity: fadeFinalDisappearAnim }}>LILI</Animated.Text>
            </View>
        }
        return <View style={[Style.shadowed, { width: appTheme.getFullScreenWidth(), height: appTheme.getFullScreenHeight(), backgroundColor: appTheme.still, justifyContent: "center", alignItems: "center" }]}>
            <View style={{ left: center, position: "absolute", top: topPosition }}>
                <Image
                    source={images.icTalk}
                    style={{ width: appTheme.pixelPerfect(121), height: appTheme.pixelPerfect(67), opacity: 1 }}
                    resizeMode="contain" />
                <Divider />
                <ActivityIndicator loading={true} />
            </View>
            <Footer />
        </View>
    }

    return (
        <Main mainOptions={mainOptions} >
            {getContentView()}
            <Alert ref={alertRef} />
        </Main>
    )
}

export default Splashscreen;