/* REACT */
import React, { useEffect, useState, useContext } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Platform } from 'react-native';
import Image from "../../designSystem/OTBImage";
import * as Constants from "../../utils/Constants";
import AppTheme from '../../utils/Theme';
import SVGView from '../../utils/SvgView';
import { default as IcLiliStar } from "../../assets/svg/lili/ic_star.svg";
import { default as IcChevronRight } from "../../assets/svg/icons/solid/chevron-right.svg";
import { convertHMS } from '../../utils/DateUtils';
import * as Navigation from "../../../specific/utils/Navigation/Navigation";
import Style from '../../utils/Style';
import { getLanguage } from '../../utils/Localization/Localization';
import { useNavigate, useParams } from 'react-router-dom';
import User from '../../data/user/User';
import Images from '../../../specific/utils/Images';
import { analyticsEventActivityClicked, propertiesEventCategory, propertiesEventName, trackEvent } from '../../services/Tracking/Analytics';

import { LocalDBContext } from "../../data/LocalDB";

const ActivitiesCell = (props: any) => {

    const [refresh, setRefresh] = useState(false);

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const user: User = User.getInstance();

    const { componentId, contex, localCategories } = props;
    const {
        width = appTheme.getFullAppWidth() - appTheme.pixelPerfect(40),
        item,
        activities,
        index,
        menu = "classic",
        titleColor = appTheme.darkBlue,
        bodyColor,
        context,
        showBackground = false,
        activityTitleFirst = false,
        showCategoryTitle = true,
        type = "line" } = props;
    let navigate: any = null;
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    useEffect(() => {
        setRefresh(!refresh);
    }, [props]);

    const onPress = (activityDef) => {
        const { onPress } = props;
        if (onPress !== undefined) {
            onPress(activityDef);
        } else {
            showActivity(activityDef);
        }
    }

    const numberOfActivity = type === "bloc" ? 3 : Platform.OS === "web" ? 5 : 3;
    let activityIconSize = width / (numberOfActivity + 0.2);
    if (activityIconSize > appTheme.pixelPerfect(150)) {
        activityIconSize = appTheme.pixelPerfect(150);
    }

    const showActivitySheet = (data: { activityKey: string, categoryKey: string, sectionKey: string }) => {
        const { activityKey, categoryKey, sectionKey } = data;
        let analyticsMetaData = {[propertiesEventName]:item.analytics_key};
        if (localCategories[categoryKey] !== undefined) {
            analyticsMetaData = {
                [propertiesEventName]:item.analytics_key,
                [propertiesEventCategory]:localCategories[categoryKey].analytics_key
            };
        }
        trackEvent(analyticsEventActivityClicked, analyticsMetaData);
        if ((Platform.OS === "web") && (props.onPress !== undefined)) {
            props.onPress();
        } else {
            Navigation.push({
                componentId,
                navigate,
                passProps: {
                    activityKey,
                    categoryKey,
                    sectionKey,
                    context,
                    menu
                },
                name: Constants.ScreenActivitySheet
            })
        }
    }

    const showActivity = (activityDef) => {
        if (componentId !== undefined) {
            let targetedScreen = 'lili.public.freemeditation';
            let passProps = {
                activity: activityDef.activityContext.activity
            };
            const { activityContext, subSection, sectionId, sectionKey } = activityDef;
            let anActivity = activityContext.activity;
            if (activityDef.activityContext.activity.subSectionId === 'school') {
                // TO DO : Remettre un écran spécifique pour les PDF
                targetedScreen = Constants.ScreenAppMenu;
                passProps = {
                    pdfUrl: activityDef.activityContext.activity.doc[getLanguage()],
                    color: appTheme.schoolColor,
                    isModal: false,
                    sectionId: 'liliAtSchool',
                    name: activityDef.activityContext.activity.title
                }
            } else {
                const activityType = anActivity.type !== undefined ? anActivity.type : '';
                const sectionMainColor = ((anActivity.subSection !== undefined) && (anActivity.subSection.section !== undefined)) ? anActivity.subSection.section.clr.main : appTheme.daySkyColor;
                const mainSectionColor = props.mainSectionColor !== undefined ? props.mainSectionColor : sectionMainColor;
                const activityProgramAlgo = props.programAlgo !== undefined ? props.programAlgo : '';
                if (activityType !== 'free_meditation') {
                    anActivity.subSection = subSection;
                    anActivity.sectionId = sectionId;
                    anActivity.sectionKey = sectionKey;
                    if (activityProgramAlgo === 'notebooks') {
                        const aNotebook = ((anActivity.media !== undefined) && (anActivity.media[0] !== undefined) && (anActivity.media[0].url !== undefined)) ? anActivity.media[0].url : '';
                        anActivity.action = { title: { fr: "C'est parti" }, type: "doc" };
                        anActivity.doc = { fr: aNotebook };
                        anActivity.type = 'doc';
                    }
                    targetedScreen = 'lili.public.activity';
                    //On va faire le tri de ce qu'on doit afficher
                    let activitiesToDisplay = [];
                    let activityPosition = activityContext.position;
                    let position = 0;
                    activityContext.activities.forEach(activity => {
                        if (activity.type !== 'free_meditation') {
                            let activityToAdd = activity;
                            if (activityProgramAlgo === 'notebooks') {
                                const aNotebook = ((activityToAdd.media !== undefined) && (activityToAdd.media[0] !== undefined) && (activityToAdd.media[0].url !== undefined)) ? activityToAdd.media[0].url : '';
                                activityToAdd.action = { title: { fr: "C'est parti" }, type: "doc" };
                                activityToAdd.doc = { fr: aNotebook };
                                activityToAdd.type = 'doc';
                            }
                            activitiesToDisplay.push(activityToAdd);
                            position++;
                        } else {
                            if (activityPosition > position) {
                                activityPosition = activityPosition - 1;
                            }
                        }
                    });
                    passProps = {
                        mainSectionColor,
                        sectionColors: anActivity.subSection.section.clr !== undefined ? anActivity.subSection.section.clr : { main: appTheme.daySkyColor, action: appTheme.darkBlue },
                        activities: activitiesToDisplay,
                        position: activityPosition,
                        subSection: subSection,
                        sectionId: sectionId,
                        menu: props.menu !== undefined ? props.menu : sectionId,
                        sectionName: props.sectionName !== undefined ? props.sectionName : anActivity.subSection.section.name
                    }
                }
            }
            Navigation.push({
                componentId,
                navigate,
                name: targetedScreen,
                passProps,
                options: {}
            });
        }
    }

    let sectionMainColor = ((item.subSection !== undefined) && (item.subSection.section !== undefined)) ? item.subSection.section.clr.main : appTheme.daySkyColor;
    sectionMainColor = sectionMainColor === "#bdf3f9" ? "#75DCEA" : sectionMainColor;
    const sectionActivityColor = ((item.subSection !== undefined) && (item.subSection.section !== undefined)) ? item.subSection.section.clr.action : appTheme.daySkyColor;
    const mainSectionColor = props.mainSectionColor !== undefined ? props.mainSectionColor : sectionMainColor;
    // On va déterminer la couleur de l'étoile en fonction du statut de l'activité
    let premiumMarkColor = appTheme.premium;
    let icon = <SVGView Component={IcLiliStar} size={appTheme.pixelPerfect(14)} color={appTheme.white} />;
    let isPremium = false;
    if (item.access.is_essential === true) {
        isPremium = true;
    } else if (item.access.is_premium === true) {
        isPremium = true;
    }
    const premiumMark = isPremium === true ? <View style={[Style.shadowed, { width: appTheme.pixelPerfect(20), height: appTheme.pixelPerfect(20), borderRadius: appTheme.pixelPerfect(10), backgroundColor: premiumMarkColor, position: "absolute", top: appTheme.pixelPerfect(5), right: appTheme.pixelPerfect(5), justifyContent: 'center', alignItems: 'center' }]}>
        {icon}
    </View> : null;
    // Gestion de l'opacité en fonction du statut premium, LASessentiel ou LASPremium
    let opacity = 1;
    if (isPremium === true) {
        let isUserSubscriber = user.getPremiumStatus() !== "free";
        if (isUserSubscriber === false) {
            opacity = 0.5;
        }
    }
    const localImage = item.app_image;
    const remoteImage = item.img_url;
    const imageToDisplay = ((localImage.length > 0) && (images[localImage] !== undefined)) ? images[localImage] : {uri:remoteImage};
    let imageView = <Image
        resizeMode="cover"
        source={imageToDisplay}
        style={{ width: appTheme.pixelPerfect(58), height: appTheme.pixelPerfect(58), borderRadius: appTheme.pixelPerfect(29), opacity }} />;

    let titleLabel = item.title[getLanguage()] !== undefined ? item.title[getLanguage()] : "";
    if ((menu !== undefined) && (menu === "superpowers") && (type === "single") && (item.document.title[getLanguage()].length > 0)) {
        titleLabel = item.document.title[getLanguage()];
    }
    let titleView = titleLabel.length > 0 ? <Text 
    style={{
        textAlign: Platform.OS === "web" ? "center" : "left",
        fontSize: ((menu !== undefined) && (menu === "superpowers")) ? appTheme.pixelPerfectForFont(10) : appTheme.pixelPerfectForFont(12),
        color: titleColor,
        fontFamily: ((menu !== undefined) && (menu === "superpowers")) ? appTheme.secondaryFont : appTheme.primaryFont
    }}>
        {titleLabel}
    </Text> : <View />;

    let subSectionColor = type === "single" ? "#515EA0" : sectionMainColor;
    if (bodyColor !== undefined) {
        subSectionColor = bodyColor;
    }
    let subSectionTitle = "";
    let subSectionView = <View />;
    if (item.subSection !== undefined) {
        if ((menu !== undefined) && (menu === "superpowers") && (item.subSection.childrenTitle.length > 0)) {
            subSectionTitle = item.subSection.childrenTitle;
        } else {
            if (item.subSection.title !== undefined) {
                subSectionTitle = item.subSection.title;
            }
        }
    }
    if (subSectionTitle.length > 0) {
        subSectionView = showCategoryTitle === true ? <Text style={{
            fontSize: appTheme.pixelPerfectForFont(10),
            color: subSectionColor,
            fontFamily: appTheme.primaryFont
        }}>
            {subSectionTitle.toLocaleUpperCase()}
        </Text> : <View />;
    }
    const durationView = item.duration !== undefined ? <Text
        style={{
            fontSize: appTheme.pixelPerfectForFont(8),
            color: subSectionColor,
            paddingTop: appTheme.pixelPerfect(8),
            fontFamily: appTheme.primaryFont
        }}
    >
        {convertHMS(item.duration).toLocaleUpperCase()}
    </Text> : <View />;
    let styleforCell = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: appTheme.pixelPerfect(10)
    };
    if ((showBackground !== undefined) && (showBackground === true)) {
        styleforCell.backgroundColor = appTheme.white;
        styleforCell.borderRadius = appTheme.pixelPerfect(10);
        styleforCell.marginHorizontal = appTheme.pixelPerfect(20);
    }
    const chevronBackgroundColor = ((menu !== undefined) && (menu === "superpowers")) ? appTheme.nightSkyColor : mainSectionColor;

    // On voir si on met en avant le titre de l'activité ou de la catégorie
    if (activityTitleFirst === true) {
        subSectionView = <Text
        style={{
            fontSize: appTheme.pixelPerfectForFont(10),
            color: subSectionColor,
            fontFamily: appTheme.primaryFont
        }}>
            {titleLabel.toLocaleUpperCase()}
        </Text>;
        titleView = showCategoryTitle === true ? <Text style={{
            textAlign: Platform.OS === "web" ? "center" : "left",
            fontSize: ((menu !== undefined) && (menu === "superpowers")) ? appTheme.pixelPerfectForFont(8) : appTheme.pixelPerfectForFont(12),
            color: titleColor,
            fontFamily: ((menu !== undefined) && (menu === "superpowers")) ? appTheme.secondaryFont : appTheme.primaryFont
        }}>
            {subSectionTitle}
        </Text> : <View />;
    }

    if (type === "bloc") {
        const widthForBloc = (width / 3) - appTheme.pixelPerfect(10);
        const imageSize = widthForBloc > activityIconSize ? activityIconSize : widthForBloc;
        imageView = <Image
            resizeMode="cover"
            source={imageToDisplay}
            remoteSource={remoteImage}
            style={{ width: imageSize, height: imageSize, borderRadius: appTheme.pixelPerfect(10), opacity }} />;
        styleforCell = {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: appTheme.pixelPerfect(0)
        };
        return (
            <TouchableOpacity style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', paddingVertical: appTheme.pixelPerfect(10) }}
                onPress={() => showActivitySheet({ activityKey: item.key, sectionKey: item.section_key, categoryKey: item.category_keys[0] })}>
                <View style={styleforCell}>
                    <View style={[Style.shadowed, { marginVertical: appTheme.pixelPerfect(10), borderRadius: appTheme.pixelPerfect(10) }]}>
                        {imageView}
                        {premiumMark}
                    </View>
                    <View style={{ width: widthForBloc, alignItems:"center" }}>
                        {subSectionView}
                        {titleView}
                    </View>
                </View>
            </TouchableOpacity>
        )
    } else if (type === "single") {
        imageView = <Image
            source={imageToDisplay}
            style={{ width: activityIconSize, height: activityIconSize, borderRadius: appTheme.pixelPerfect(10) }} />;
        styleforCell = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: appTheme.pixelPerfect(30)
        };
        return (
            <TouchableOpacity
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    paddingVertical: appTheme.pixelPerfect(10)
                }}
                onPress={() => showActivitySheet({ activityKey: item.key, sectionKey: item.section_key, categoryKey: item.category_keys[0] })}>
                <View style={styleforCell}>
                    <View style={[Style.shadowed, { marginVertical: appTheme.pixelPerfect(10), borderRadius: appTheme.pixelPerfect(10) }]}>
                        {imageView}
                        {premiumMark}
                    </View>
                    <View style={{ flex: 1, marginStart: appTheme.pixelPerfect(16) }}>
                        {subSectionView}
                        {titleView}
                    </View>
                </View>
            </TouchableOpacity>
        )
    } else {
        return (
            <TouchableOpacity
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingVertical: appTheme.pixelPerfect(10)
                }}
                activeOpacity={0.5}
                onPress={() => onPress({
                    activityContext: { activity: item, activities: activities, position: index },
                    subSection: item.subSection,
                    sectionId: ((item.subSection !== undefined) && (item.subSection.section !== undefined)) ? item.subSection.section.id : undefined
                })}>
                <View style={styleforCell}>
                    <View style={{ marginVertical: appTheme.pixelPerfect(10), marginRight: appTheme.pixelPerfect(10) }}>
                        {imageView}
                        {premiumMark}
                    </View>
                    <View style={{ flex: 1 }}>
                        {titleView}
                        {subSectionView}
                        {durationView}
                    </View>
                    <View
                        style={{
                            height: appTheme.pixelPerfect(26),
                            width: appTheme.pixelPerfect(26),
                            borderRadius: appTheme.pixelPerfect(13),
                            backgroundColor: chevronBackgroundColor,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginHorizontal: appTheme.pixelPerfect(20)
                        }}>
                        <SVGView Component={IcChevronRight} width={appTheme.pixelPerfectForFont(10)} height={appTheme.pixelPerfectForFont(10)} color={appTheme.white} />
                    </View>
                </View>
            </TouchableOpacity>
        )
    }
}

export default ActivitiesCell;
