import React, { useEffect, useRef } from "react";
import { useState, useContext } from "react";
import i18n from "../../utils/Localization/Localization";
import * as Navigation from "../../../specific/utils/Navigation/Navigation";

import {
    View,
    Text,
    ActivityIndicator,
    StyleSheet,
    TouchableOpacity,
    Image,
    ScrollView,
    Animated,
    Alert,
    Platform,
    Modal
} from 'react-native';

import AppTheme from "../../utils/Theme";
import * as Constants from "../../utils/Constants";

// Assets
import Images from "../../../specific/utils/Images";


// Data
import { LocalDBContext } from "../../data/LocalDB";

import { useNavigate } from "react-router-dom";
import { setLocalStorage } from "../../../specific/utils/LocalStorage";

import OtbImage from "../../../specific/utils/OtbImage/OtbImage";
import { analyticsEventHpLoungeClicked, analyticsEventHpSleepMeditationsClicked, analyticsEventHpSuperPowerClicked, analyticsEventHpSuperpowersPosterClicked, analyticsEventHpTrainingPathClicked, propertiesEventHpLoungeStartingPoint, propertiesEventSuperPower, propertiesValueHpLoungeStartingPointSection, propertiesValueHpLoungeStartingPointSuperpower, trackEvent } from "../../services/Tracking/Analytics";
import User from "../../data/user/User";
import Divider from "../../designSystem/Divider/Divider";
import LiliVideoPlayer from "../../../specific/components/VideoPlayer/LiliVideoPlayer";
import MenuStyle from "../../styles/menu/MenuStyle";
import InViewModal from "../../../specific/components/InViewModal/InViewModal";
import DeepLink from "../../services/DeepLink/DeepLink";
import MainOptions from "../../models/MainOptions";
import Main from "../../../specific/components/Main/Main";
import { handleUrl } from "../../../specific/utils/Deeplink/Deeplink";
import MenuSegment, { menuSegmentOrigin } from "../../services/MenuSegment/MenuSegment";

const SchoolMenu = (props: any) => {
    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const [launchEcologie, setLaunchEcologie] = useState<boolean>(false);
    const [launchInspiration, setLaunchInspiration] = useState<boolean>(false);
    const [segmentMenuDidAppear, setSegmentMenuDidAppear] = useState<boolean>(false);

    const [menuType, setMenuType] = useState<number>(0);

    const menuSegment: MenuSegment = MenuSegment.getInstance();

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const menuStyle: MenuStyle = new MenuStyle();

    const mainViewOpacity = useRef(new Animated.Value(1)).current;

    const typeMenuWidth = Platform.OS === "web" ? appTheme.pixelPerfect(280) : appTheme.pixelPerfect(220);
    const typeMenuBackgroundWidth = typeMenuWidth / 2;
    const menuBackgroundPosition = useRef(new Animated.Value(0)).current;

    const inViewModal = useRef<InViewModal>(null);

    const { componentId = "" } = props;
    let navigate: any = null;
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    const mainOptions: MainOptions = new MainOptions({
        key: "app_menu",
        componentId,
        showNavBar: false,
        showMenu: false,
        safeArea: false,
        canGoBack: false
    });

    const user: User = User.getInstance();

    const localDBData = useContext(LocalDBContext);

    let menuWidth = appTheme.getBlockWidth() - appTheme.pixelPerfect(20);
    let menuHeight = menuWidth * 380 / 285;
    const ratioForMenuHeightInScreen = 3 / 5;
    const ratioForMenuHeight = menuHeight / appTheme.getFullScreenHeight();
    if (ratioForMenuHeight > ratioForMenuHeightInScreen) {
        menuHeight = ratioForMenuHeightInScreen * appTheme.getFullScreenHeight();
        menuWidth = menuHeight * 285 / 380;
    }

    const refWidth = appTheme.pixelPerfect(285);
    const refHeight = appTheme.pixelPerfect(380);
    const refResolution = Math.sqrt(refWidth * refWidth + refHeight * refHeight);
    const currentResolution = Math.sqrt(menuWidth * menuWidth + menuHeight * menuHeight);
    let imageScale = currentResolution / refResolution;
    if (imageScale > 1.5) {
        imageScale = 1.5
    } else if (imageScale < 1) {
        imageScale = 1;
    }

    const onViewDidAppear = () => {
        const deepLink: DeepLink = DeepLink.getInstance();
        if (deepLink.path.length > 0) {
            handleDeepLink(deepLink.path);
            deepLink.path = "";
        } else {
            setViewDidAppear(true);
        }
    }

    useEffect(() => {
        if (segmentMenuDidAppear === true) {
            testMenuSegmentOrigin();
        }
    }, [segmentMenuDidAppear]);

    const testMenuSegmentOrigin = () => {
        if (menuSegment.origin.length > 0) {
            const activitiesBySectionIndex = user.profile === "family" ? 1 : 0;
            const activitiesBySuperPowersIndex = user.profile === "family" ? 0 : 1;
            if (menuSegment.origin === "section") {
                if (menuType !== activitiesBySectionIndex) {
                    onSelectMenuItem(activitiesBySectionIndex);
                }
            } else if (menuSegment.origin === "superpowers") {
                if (menuType !== activitiesBySuperPowersIndex) {
                    onSelectMenuItem(activitiesBySuperPowersIndex);
                }
            }
        }
    }

    const handleDeepLink = (path: string) => {
        if (path.includes("/appMenu") === true) {
            if (path.includes("/appMenu/skills") === true) {
                if ((user.profile === "teacher") || (user.profile === "animator")) {
                    onSelectMenuItem(1);
                } else {
                    onSelectMenuItem(0);
                }
            }
        } else {
            if (Platform.OS === "web") {
                if (path.includes("/selectSubscription") === true) {
                    goToSuperRoom("sub");
                } else if (path.includes("/superRoom") === true) {
                    goToSuperRoom();
                } else if ((path.includes("/philo") === true) || (path.includes("/categoryHome/phi_questions"))) {
                    goToSuperRoom("philo");
                } else if ((path.includes("/wellBeing") === true) || (path.includes("/categoryHome/wellBeing"))) {
                    goToSuperRoom("well_being");
                }  else if (path.includes("/advices") === true) {
                    goToSuperRoom("advices");
                } else if (path.includes("/activityTypes") === true) {
                    goToSuperRoom("activity_types");
                } else  if (path.includes("/stripeCallBack") === true) {
                    let urlArguments: string[] = path.split("/");
                    const subId: string = urlArguments[urlArguments.length - 1];
                    const status: string = urlArguments[urlArguments.length - 2];
                    goToSuperRoom("sub", {status, subId});
                } else if (path.includes("/activitiesBySection") === true) {
                    let section = { key: "" };
                    if (path.includes("still") === true) {
                        section.key = "still";
                    } else if (path.includes("talk") === true) {
                        section.key = "talk";
                    } else if (path.includes("grow") === true) {
                        section.key = "grow";
                    } else if (path.includes("liliAtSchool") === true) {
                        section.key = "liliAtSchool";
                    }
                    if (section.key.length > 0) {
                        launchTheApp({ section });
                    }
                } else if (path.includes("/activitySheet") === true) {
                    // Quelque soit l'origine, on aura toujours la section en dernier, la catégorie en avant-dernier et enfin le code de l'activité en avant-avant-dernier
                    let urlArguments: string[] = path.split("/");
                    const deepLink: DeepLink = DeepLink.getInstance();
                    deepLink.category_key = urlArguments[urlArguments.length - 2];
                    deepLink.activity_key = urlArguments[urlArguments.length - 3];
                    launchTheApp({ section: { key: urlArguments[urlArguments.length - 1]} });
                }
            } else {
                handleUrl({url:path, isLaunchedUrl:false, componentId, navigate});
            }
        }
        
        setViewDidAppear(true);
    }

    const launchTheApp = (data: { section: any }) => {
        const { section } = data;
        const { key } = section;
        if ((key === "still") || (key === "talk") || (key === "grow") || (key === "liliAtSchool") || (key === "inspire")) {
            if (key === "still") {
                trackEvent("section_calm_clicked");
            } else if (key === "talk") {
                trackEvent("section_expression_clicked");
            } else if (key === "grow") {
                trackEvent("section_philo_clicked");
            }
            //trackEvent(analyticsEventHpSectionClicked, { [propertiesEventSection]: analytics_key });
            Navigation.push({
                componentId,
                navigate,
                name: Constants.ScreenActivitiesBySection,
                passProps: {
                    context: user.userProfile(),
                    sectionKey: key
                }
            });
        } else if (key === "actForThePlanet") {
            setLaunchEcologie(true);
        } else {
            Alert.alert(key);
        }
    }

    const launchSuperPowerPlaylist = (data: { key: string }) => {
        const { key } = data;
        // ON va vérifier si l'utilisateur est un pro
        trackEvent(analyticsEventHpSuperPowerClicked, { [propertiesEventSuperPower]: key });
        Navigation.push({
            componentId,
            navigate,
            passProps: {
                superPowersKey: key,
                context: Constants.CONTEXT.family
            },
            name: Constants.ScreenSuperPowersPlaylist
        });
    }

    const launchCategory = (data: { categoryKey: string }) => {
        const { categoryKey } = data;
        let passProps = {
            context: user.profile,
            isModal: true,
            category: "sleepMeditation"
        };
        let name = Constants.ScreenFullScreenCategory;
        if (categoryKey === "firstExtra") {
            trackEvent(analyticsEventHpTrainingPathClicked);
            passProps = {
                activityKey: user.profile === "family" ? "ROU_02" : "PAC_16",
                categoryKey: "routineSetUp",
                sectionKey: user.profile === "family" ? "liliAtHome" : "liliAtSchool",
                context: user.profile,
                menu: "superpowers",
                isModal: true
            };
            name = Constants.ScreenActivitySheet;
        } else if (categoryKey === "secondExtra") {
            if ((user.profile === "teacher") || (user.profile === "animator")) {
                name = Constants.ScreenActivitySheet;
                passProps = {
                    activityKey: "PAC_18",
                    categoryKey: "poster_instructions",
                    sectionKey: "liliAtSchool",
                    context: user.profile,
                    menu: "superpowers",
                    isModal: true
                };
                trackEvent(analyticsEventHpSuperpowersPosterClicked);
            } else {
                trackEvent(analyticsEventHpSleepMeditationsClicked);
            }
        }
        if ((Platform.OS === "web") && (name === Constants.ScreenActivitySheet)) {
            inViewModal.current?.showAlert({
                name,
                passProps
            });
        } else {
            Navigation.showModal({
                componentId,
                navigate,
                passProps,
                name
            });
        }
    }

    const getSuperPowersContent = () => {
        let finalBlocsSize = (appTheme.getBlockWidth() / 2) - appTheme.pixelPerfect(10);
        if (finalBlocsSize > 256) {
            finalBlocsSize = 256;
        }

        let superPowerMenuWidth = menuWidth;
        let superPowerMenuHeight = menuHeight;
        let beamAndCatView = [];
        let titleViews = [];
        if (Platform.OS === "web") {
            superPowerMenuHeight = appTheme.getBlockWidth() * 288 / 384;
            superPowerMenuWidth = appTheme.getBlockWidth();
            const beamAndCatImages = [
                {
                    img: images.illHomeBeamApprivoiserMesEmotions,
                    height: superPowerMenuHeight / 2,
                    width: 556 * (superPowerMenuHeight / 2) / 883,
                    top: 0,
                    left: superPowerMenuWidth / 2
                },
                {
                    img: images.illHomeBeamMusclerMaConcentration,
                    width: (superPowerMenuWidth / 2) * 0.9,
                    height: 383 * ((superPowerMenuWidth / 2) * 0.9) / 1002,
                    bottom: superPowerMenuHeight / 2,
                    left: superPowerMenuWidth / 2
                },
                {
                    img: images.illHomeBeamDompterErreur,
                    width: (superPowerMenuWidth / 2) * 0.7,
                    height: 569 * ((superPowerMenuWidth / 2) * 0.7) / 747,
                    top: superPowerMenuHeight / 2,
                    left: superPowerMenuWidth / 2
                },
                {
                    img: images.illHomeBeamOserAOral,
                    height: superPowerMenuHeight / 2,
                    width: 381 * (superPowerMenuHeight / 2) / 892,
                    top: superPowerMenuHeight / 2,
                    left: (superPowerMenuWidth / 2) - (381 * (superPowerMenuHeight / 2) / 892) / 2
                },
                {
                    img: images.illHomeBeamLaJouerCollectif,
                    width: (superPowerMenuWidth / 2) * 0.7,
                    height: 569 * ((superPowerMenuWidth / 2) * 0.7) / 747,
                    top: superPowerMenuHeight / 2,
                    right: superPowerMenuWidth / 2
                },
                {
                    img: images.illHomeBeamReflechirSurLaVie,
                    width: (superPowerMenuWidth / 2) * 0.9,
                    height: 383 * ((superPowerMenuWidth / 2) * 0.9) / 1002,
                    bottom: superPowerMenuHeight / 2,
                    right: superPowerMenuWidth / 2
                },
                {
                    img: images.illHomeBeamCroireEnMoi,
                    height: superPowerMenuHeight / 2,
                    width: 532 * (superPowerMenuHeight / 2) / 878,
                    top: 0,
                    right: superPowerMenuWidth / 2
                },
                {
                    img: images.illHomeSupercat,
                    height: superPowerMenuHeight - appTheme.pixelPerfect(80),
                    width: superPowerMenuWidth - appTheme.pixelPerfect(80),
                    top: appTheme.pixelPerfect(40),
                    left: appTheme.pixelPerfect(40)
                }
            ];

            for (const aBeamAndCatIndex in beamAndCatImages) {
                if (Object.prototype.hasOwnProperty.call(beamAndCatImages, aBeamAndCatIndex)) {
                    const aBeamAndCaTImage = beamAndCatImages[aBeamAndCatIndex];
                    let imageStyle = {
                        position: "absolute",
                        width: aBeamAndCaTImage.width,
                        height: aBeamAndCaTImage.height
                    }
                    if (aBeamAndCaTImage.left !== undefined) {
                        imageStyle.left = aBeamAndCaTImage.left;
                    }
                    if (aBeamAndCaTImage.right !== undefined) {
                        imageStyle.right = aBeamAndCaTImage.right;
                    }
                    if (aBeamAndCaTImage.bottom !== undefined) {
                        imageStyle.bottom = aBeamAndCaTImage.bottom;
                    }
                    if (aBeamAndCaTImage.top !== undefined) {
                        imageStyle.top = aBeamAndCaTImage.top;
                    }
                    beamAndCatView.push(<View key={aBeamAndCatIndex} style={{ width: superPowerMenuWidth, height: superPowerMenuHeight, position: "absolute" }}>
                        <Image source={aBeamAndCaTImage.img} style={imageStyle} />
                    </View>);
                }
            }
            const heightRatio = superPowerMenuHeight / 2880;
            const widthRatio = superPowerMenuWidth / 2160;
            const titleImages = [
                {
                    key: Constants.superPowersKeys.EMOTIONS_TAMING,
                    top: 103,
                    right: 556,
                    width: 430,
                    height: 364,
                    image: images.icHomeApprivoiserMesEmotions
                },
                {
                    key: Constants.superPowersKeys.FOCUS,
                    top: 850,
                    right: 50,
                    width: 570,
                    height: 470,
                    image: images.icHomeMusclerMaConcentration
                },
                {
                    key: Constants.superPowersKeys.ERROR_TAMING,
                    top: 1850,
                    right: 260,
                    width: 515,
                    height: 515,
                    image: images.icHomeDompterErreur
                },
                {
                    key: Constants.superPowersKeys.SPEAK,
                    top: 2300,
                    right: 830,
                    width: 500,
                    height: 500,
                    image: images.icHomeOserAOral
                },
                {
                    key: Constants.superPowersKeys.CRITICAL_THINKING,
                    top: 850,
                    left: 10,
                    width: 640,
                    height: 540,
                    image: images.icHomeReflechirSurLaVie
                },
                {
                    key: Constants.superPowersKeys.COLLECTIVE,
                    top: 1850,
                    left: 260,
                    width: 515,
                    height: 515,
                    image: images.icHomeLaJouerCollectif
                },
                {
                    key: Constants.superPowersKeys.SELF_ESTEEM,
                    top: 90,
                    left: 540,
                    width: 430,
                    height: 464,
                    image: images.icHomeCroireEnMoi
                }
            ];
            for (const aTitleIndex in titleImages) {
                if (Object.prototype.hasOwnProperty.call(titleImages, aTitleIndex)) {
                    const aTitleImage = titleImages[aTitleIndex];
                    const { key, top, right = -1, left = -1, width, height, image } = aTitleImage;
                    let titleViewStyle = { top: (top * heightRatio) + appTheme.pixelPerfect(5), position: "absolute", justifyContent: 'flex-start', alignItems: "flex-start" };
                    if (right !== -1) {
                        titleViewStyle.right = (right * widthRatio) + appTheme.pixelPerfect(5);
                    } else if (left !== -1) {
                        titleViewStyle.left = (left * widthRatio) + appTheme.pixelPerfect(5);
                    }
                    titleViews.push(<View key={key} style={titleViewStyle}>
                        <TouchableOpacity onPress={() => launchSuperPowerPlaylist({ key })}>
                            <OtbImage resizeMode="contain" source={image} style={{ width: (width * widthRatio) - appTheme.pixelPerfect(10), height: (height * heightRatio) - appTheme.pixelPerfect(10) }} />
                        </TouchableOpacity>
                    </View >);
                }
            }
        } else {
            const beamAndCatImages = [
                images.illHomeBeamApprivoiserMesEmotions,
                images.illHomeBeamMusclerMaConcentration,
                images.illHomeBeamDompterErreur,
                images.illHomeBeamOserAOral,
                images.illHomeBeamReflechirSurLaVie,
                images.illHomeBeamLaJouerCollectif,
                images.illHomeBeamCroireEnMoi,
                images.illHomeSupercat
            ];

            for (const aBeamAndCatIndex in beamAndCatImages) {
                if (Object.prototype.hasOwnProperty.call(beamAndCatImages, aBeamAndCatIndex)) {
                    const aBeamAndCaTImage = beamAndCatImages[aBeamAndCatIndex];
                    beamAndCatView.push(<View key={aBeamAndCatIndex} style={{ width: menuWidth, height: menuHeight, position: "absolute", transform: [{ scale: 1 }] }}>
                        <OtbImage source={aBeamAndCaTImage} style={{ width: menuWidth, height: menuHeight }} />
                    </View>);
                }
            }

            const heightRatio = superPowerMenuHeight / 2880;
            const widthRatio = superPowerMenuWidth / 2160;
            const titleImages = [
                {
                    key: Constants.superPowersKeys.EMOTIONS_TAMING,
                    top: 103,
                    right: 300,
                    width: 608,
                    height: 515,
                    image: images.icHomeApprivoiserMesEmotions
                },
                {
                    key: Constants.superPowersKeys.FOCUS,
                    top: 1000,
                    right: 66,
                    width: 630,
                    height: 515,
                    image: images.icHomeMusclerMaConcentration
                },
                {
                    key: Constants.superPowersKeys.ERROR_TAMING,
                    top: 1816,
                    right: 110,
                    width: 515,
                    height: 515,
                    image: images.icHomeDompterErreur
                },
                {
                    key: Constants.superPowersKeys.SPEAK,
                    top: 2256,
                    right: 820,
                    width: 518,
                    height: 518,
                    image: images.icHomeOserAOral
                },
                {
                    key: Constants.superPowersKeys.CRITICAL_THINKING,
                    top: 1030,
                    left: 99,
                    width: 570,
                    height: 455,
                    image: images.icHomeReflechirSurLaVie
                },
                {
                    key: Constants.superPowersKeys.COLLECTIVE,
                    top: 1824,
                    left: 110,
                    width: 515,
                    height: 515,
                    image: images.icHomeLaJouerCollectif
                },
                {
                    key: Constants.superPowersKeys.SELF_ESTEEM,
                    top: 102,
                    left: 325,
                    width: 475,
                    height: 475,
                    image: images.icHomeCroireEnMoi
                }
            ];
            for (const aTitleIndex in titleImages) {
                if (Object.prototype.hasOwnProperty.call(titleImages, aTitleIndex)) {
                    const aTitleImage = titleImages[aTitleIndex];
                    const { key, top, right = -1, left = -1, width, height, image } = aTitleImage;
                    let titleViewStyle = { top: (top * heightRatio) + appTheme.pixelPerfect(5), position: "absolute", justifyContent: 'flex-start', alignItems: "flex-start" };
                    if (right !== -1) {
                        titleViewStyle.right = (right * widthRatio) + appTheme.pixelPerfect(5);
                    } else if (left !== -1) {
                        titleViewStyle.left = (left * widthRatio) + appTheme.pixelPerfect(5);
                    }
                    titleViews.push(<View key={key} style={titleViewStyle}>
                        <TouchableOpacity onPress={() => launchSuperPowerPlaylist({ key })}>
                            <OtbImage resizeMode="contain" source={image} style={{ width: (width * widthRatio) - appTheme.pixelPerfect(10), height: (height * heightRatio) - appTheme.pixelPerfect(10) }} />
                        </TouchableOpacity>
                    </View >);
                }
            }
        }

        return (<View style={{ justifyContent: 'flex-start', alignItems: "center", marginVertical: appTheme.pixelPerfect(20) }}>
            <View style={{ flex: 1, justifyContent: 'flex-start', alignItems: "center" }}>
                <View style={{ width: superPowerMenuWidth, height: superPowerMenuHeight }}>
                    {beamAndCatView}
                    {titleViews}
                </View>
            </View>
        </View >)
    }

    const getContent = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear} style={{ flex: 1, width: appTheme.getFullAppWidth(), justifyContent: "center", alignItems: "center", paddingBottom: appTheme.pixelPerfect(20) }}>
                <ActivityIndicator color={appTheme.darkBlue} />
            </View>
        }
        const activitiesByTypeIndex = user.profile === "family" ? 1 : 0;
        if (menuType === activitiesByTypeIndex) {
            const bodyTextSize = appTheme.pixelPerfectForFont(8);
            const sections = localDBData.sections.data;
            let mainMenuButtons = [
                <View />,
                <View />,
                <View />,
                <View />
            ];

            // Liste des sections à afficher
            //const sectionKeyArray: string[] = ["still", "talk", "grow"]; 
            const sectionKeyArray: string[] = ["inspire", "still", "talk", "grow" ]; 
            for (const aLocalSectionKey in sections) {
                if (Object.hasOwnProperty.call(sections, aLocalSectionKey)) {
                    const aLocalSection = sections[aLocalSectionKey];
                    const aLocalSectionTitle = ((aLocalSection.title !== undefined) && (aLocalSection.title.fr !== undefined)) ? aLocalSection.title.fr : "";
                    let aLocalSectionBody = ((aLocalSection.appMenuBody !== undefined) && (aLocalSection.appMenuBody.fr !== undefined)) ? aLocalSection.appMenuBody.fr : "";
                    if (user.profile !== undefined) {
                        if ((aLocalSection[user.profile] !== undefined) && (aLocalSection[user.profile].appMenuBody !== undefined) && (aLocalSection[user.profile].appMenuBody.fr !== undefined)) {
                            aLocalSectionBody = aLocalSection[user.profile].appMenuBody.fr;
                        }
                    }
                    if (sectionKeyArray.indexOf(aLocalSection.key) !== -1) {
                        const colors = aLocalSection.clr !== undefined ? aLocalSection.clr : { "action": "#ff855f", "main": "#ff855f", "txt": "#25364f" };
                        const textColor: string = colors.menu_txt !== undefined ? colors.menu_text : "#25364f";
                        let buttonPosition = 0;
                        let buttonImage = images.illStill;
                        if (aLocalSection.key === "still") {
                            buttonPosition = 1;
                            buttonImage = images.illStill;
                        } else if (aLocalSection.key === "talk") {
                            buttonPosition = 2;
                            buttonImage = images.illTalk;
                        } else if (aLocalSection.key === "grow") {
                            buttonPosition = 3;
                            buttonImage = images.illGrow;
                        } else if (aLocalSection.key === "inspire") {
                            buttonPosition = 0;
                            buttonImage = images.icInspiration;
                        } else if (aLocalSection.key === "actForThePlanet") {
                            buttonPosition = 4;
                            buttonImage = images.icPlanet;
                        }
                        const imageSize = appTheme.getFlexDirectionForSplitScreen() === "row" ? appTheme.pixelPerfect(130) : appTheme.pixelPerfect(66);
                        const menuButton = <TouchableOpacity key={aLocalSection.key} onPress={() => launchTheApp({ section: aLocalSection })} style={[menuStyle.headingButtonStyle, { backgroundColor: colors.main }]}>
                            <OtbImage source={buttonImage} style={{ width: imageSize, height: imageSize }} resizeMode="contain" />
                            <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                                <Text style={[{ color: textColor, fontFamily: appTheme.superHerosFontBold, textAlign: "center", fontSize: bodyTextSize }]}>{aLocalSectionTitle.toLocaleUpperCase()}</Text>
                                <Text style={[{ fontFamily: appTheme.primarySemiBoldFont, color: textColor, textAlign: "center", fontSize: bodyTextSize }]}>{aLocalSectionBody}</Text>
                            </View>
                        </TouchableOpacity>;
                        mainMenuButtons[buttonPosition] = menuButton;
                    }
                }
            }
            const flexDirection = appTheme.getFlexDirectionForSplitScreen();
            const widthForMenu = flexDirection === "row" ? (appTheme.pixelPerfect(140)*mainMenuButtons.length + appTheme.pixelPerfect(20)*mainMenuButtons.length-1) : appTheme.getFullAppWidth();
            return (
                <View style={{ flex: 1, width: appTheme.getFullAppWidth(), justifyContent: "center", alignItems: "center", paddingBottom: appTheme.pixelPerfect(20) }}>
                    <View style={{ flexDirection, flex: 1, width: widthForMenu, justifyContent: "space-evenly", alignItems: "center", paddingBottom: appTheme.pixelPerfect(20) }}>
                        {mainMenuButtons}
                    </View>
                </View>
            )
        } else {
            return getSuperPowersContent();
        }
    }

    const onSelectMenuItem = (menuItemIndex: number) => {
        const activitiesByTypeIndex = user.profile === "family" ? 1 : 0;
        if (activitiesByTypeIndex === menuItemIndex) {
            menuSegment.origin = "section";
            trackEvent("hp_sections_clicked");
        } else {
            menuSegment.origin = "superpowers";
            trackEvent("hp_superpowers_clicked");
        }
        Animated.parallel([
            Animated.timing(menuBackgroundPosition, {
                toValue: menuItemIndex * (typeMenuBackgroundWidth + appTheme.pixelPerfect(2)),
                duration: 500,
                useNativeDriver: false
            }),
            Animated.sequence([
                Animated.timing(mainViewOpacity, {
                    toValue: 0,
                    duration: 500,
                    useNativeDriver: true
                }),
                Animated.timing(mainViewOpacity, {
                    toValue: 1,
                    duration: 500,
                    useNativeDriver: true
                })
            ])

        ]).start();
        setTimeout(() => {
            setMenuType(menuItemIndex);
        }, 500);
    }

    const getFontForMenuItemIndex = (menuItemIndex: number) => {
        if (user.profile === "family") {
            return appTheme.darkBlue;
        }
        const outputRangeByIndex: string[] = menuItemIndex === 0 ? [appTheme.white, appTheme.schoolColor] : [appTheme.schoolColor, appTheme.white];
        return menuBackgroundPosition.interpolate({
            inputRange: [0, typeMenuBackgroundWidth],
            outputRange: outputRangeByIndex
        })
    }

    const getBackgroundColor = () => {
        const colorRange = [appTheme.daySkyColor, appTheme.nightSkyColor];
        const outputRange = user.profile === "family" ? colorRange.reverse() : colorRange;
        return menuBackgroundPosition.interpolate({
            inputRange: [0, typeMenuBackgroundWidth],
            outputRange
        })
    }

    const getGroundColor = () => {
        const colorRange = ["#00BC98", "#515EA0"];
        const outputRange = user.profile === "family" ? colorRange.reverse() : colorRange;
        return menuBackgroundPosition.interpolate({
            inputRange: [0, typeMenuBackgroundWidth],
            outputRange
        })
    }

    const getWaveColor = () => {
        const colorRange = ["#57537F", "#354F84"];
        const outputRange = user.profile === "family" ? colorRange.reverse() : colorRange;
        return menuBackgroundPosition.interpolate({
            inputRange: [0, typeMenuBackgroundWidth],
            outputRange
        })
    }

    const getBackgroundImageOpacity = (menuItemIndex: number) => {
        const outputRangeByIndex: number[] = menuItemIndex === 0 ? [1, 0] : [0, 1];
        return menuBackgroundPosition.interpolate({
            inputRange: [0, typeMenuBackgroundWidth],
            outputRange: outputRangeByIndex
        })
    }

    const getExtraContent = () => {
        let finalBlocsSize = (appTheme.getBlockWidth() / 2) - appTheme.pixelPerfect(5);
        if (finalBlocsSize > 256) {
            finalBlocsSize = 256;
        }
        const finalBlocsImageSize = Platform.OS === "web" ? finalBlocsSize / 2 : finalBlocsSize / 3;
        const positionForClouds = Platform.OS === "web" ? finalBlocsSize / 2 : finalBlocsSize * 2 / 3;
        const fontSizeForBottomBlocks = Platform.OS === "web" ? appTheme.pixelPerfectForFont(10) : appTheme.pixelPerfectForFont(6);
        const extraTitle = user.profile === "teacher" ? i18n.t("appMenu.extra.teachers.title") : user.profile === "animator" ? i18n.t("appMenu.extra.animators.title") : i18n.t("appMenu.extra.family.title");
        const firstSectionTitle = user.profile === "teacher" ? i18n.t("appMenu.extra.teachers.first.title") : user.profile === "animator" ? i18n.t("appMenu.extra.animators.first.title") : i18n.t("appMenu.extra.family.first.title");
        const firstSectionBody = user.profile === "teacher" ? i18n.t("appMenu.extra.teachers.first.body") : user.profile === "animator" ? i18n.t("appMenu.extra.animators.first.body") : i18n.t("appMenu.extra.family.first.body");
        const secondSectionTitle = user.profile === "teacher" ? i18n.t("appMenu.extra.teachers.second.title") : user.profile === "animator" ? i18n.t("appMenu.extra.animators.second.title") : i18n.t("appMenu.extra.family.second.title");
        const secondSectionBody = user.profile === "teacher" ? i18n.t("appMenu.extra.teachers.second.body") : user.profile === "animator" ? i18n.t("appMenu.extra.animators.second.body") : i18n.t("appMenu.extra.family.second.body");
        const secondSectionImage = user.profile === "teacher" ? images.icSuperpowerPoster : user.profile === "animator" ? images.icSuperpowerPoster : images.icMeditationsSleep;
        const firstCloudImage = user.profile === "teacher" ? <View /> : <Image resizeMode="contain" source={images.icDay} style={{ width: finalBlocsImageSize * 2 / 3, height: appTheme.pixelPerfect(23), position: "absolute", top: appTheme.pixelPerfect(2), right: positionForClouds }} />;
        const secondCloudImage = user.profile === "teacher" ? <View /> : <Image resizeMode="contain" source={images.icNight} style={{ width: finalBlocsImageSize * 2 / 3, height: appTheme.pixelPerfect(23), position: "absolute", top: appTheme.pixelPerfect(2), left: positionForClouds }} />;
        const extraHeightForWeb = Platform.OS === "web" ? appTheme.pixelPerfect(20) : 0;
        return <View style={{ width: appTheme.getFullAppWidth(), alignItems: "center" }}>
            <Text style={menuStyle.titleStyle}>{extraTitle.toLocaleUpperCase()}</Text>
            <Animated.View style={{ opacity: 1, justifyContent: "space-evenly", alignItems: "center", paddingTop: appTheme.pixelPerfect(10), flexDirection: "row", width: appTheme.getBlockWidth(), paddingBottom: appTheme.pixelPerfect(30) }}>
                <TouchableOpacity onPress={() => launchCategory({ categoryKey: "firstExtra" })} style={{ paddingHorizontal: appTheme.pixelPerfect(5), width: finalBlocsSize, height: finalBlocsImageSize + appTheme.pixelPerfect(40) + extraHeightForWeb, paddingVertical: appTheme.pixelPerfect(5), borderRadius: appTheme.pixelPerfect(10), alignItems: "center" }}>
                    <View style={{ width: "100%", height: finalBlocsImageSize + appTheme.pixelPerfect(20) + extraHeightForWeb, borderRadius: appTheme.pixelPerfect(10), backgroundColor: appTheme.white, position: "absolute", bottom: 0 }} />
                    <Image resizeMode="contain" source={images.icPath} style={{ width: finalBlocsImageSize, height: finalBlocsImageSize }} />
                    <View style={{ width: finalBlocsSize, justifyContent: "center", alignItems: "center" }}>
                        <Text style={[{ fontFamily: appTheme.primaryBoldFont, color: appTheme.nightSkyColor, textAlign: "center", fontSize: fontSizeForBottomBlocks }]}>
                            {firstSectionTitle}
                        </Text>
                        <Text style={[{ fontFamily: appTheme.primaryFont, color: appTheme.nightSkyColor, textAlign: "center", fontSize: fontSizeForBottomBlocks }]}>
                            {firstSectionBody}
                        </Text>
                    </View>
                    {firstCloudImage}
                </TouchableOpacity>
                <TouchableOpacity onPress={() => launchCategory({ categoryKey: "secondExtra" })} style={{ paddingHorizontal: appTheme.pixelPerfect(5), width: finalBlocsSize, height: finalBlocsImageSize + appTheme.pixelPerfect(40) + extraHeightForWeb, paddingVertical: appTheme.pixelPerfect(5), borderRadius: appTheme.pixelPerfect(10), alignItems: "center" }}>
                    <View style={{ width: "100%", height: finalBlocsImageSize + appTheme.pixelPerfect(20) + extraHeightForWeb, borderRadius: appTheme.pixelPerfect(10), backgroundColor: appTheme.white, position: "absolute", bottom: 0 }} />
                    <Image resizeMode="contain" source={secondSectionImage} style={{ width: finalBlocsImageSize, height: finalBlocsImageSize }} />
                    <View style={{ width: finalBlocsSize, justifyContent: "center", alignItems: "center" }}>
                        <Text style={[{ fontFamily: appTheme.primaryBoldFont, color: appTheme.nightSkyColor, textAlign: "center", fontSize: fontSizeForBottomBlocks }]}>
                            {secondSectionTitle}
                        </Text>
                        <Text style={[{ fontFamily: appTheme.primaryFont, color: appTheme.nightSkyColor, textAlign: "center", fontSize: fontSizeForBottomBlocks }]}>
                            {secondSectionBody}
                        </Text>
                    </View>
                    {secondCloudImage}
                </TouchableOpacity>
            </Animated.View>
        </View>;
    }

    const getHeaderMenu = () => {
        const headerIcon = user.profile === "teacher" ? images.icLiliAtSchoolCircle : user.profile === "animator" ? images.icLiliAtExtracurricularCircle : images.icLiliAtHomeCircle;
        const headerTitle = user.profile === "teacher" ? i18n.t("appMenu.teachers.activitiesTitle") : user.profile === "animator" ? i18n.t("appMenu.animators.activitiesTitle") : i18n.t("appMenu.parents.activitiesTitle");
        const menuColor = user.profile === "teacher" ? appTheme.schoolColor : user.profile === "animator" ? appTheme.schoolColor : appTheme.homeColor;
        const activitiesByTypeIndex = user.profile === "family" ? 1 : 0;
        const firstMenuItemTitle = activitiesByTypeIndex === 0 ? "par type" : "par super-pouvoir";
        const secondMenuItemTitle = activitiesByTypeIndex === 0 ? "par compétence" : "par type";
        const iconSize = Platform.OS === "web" ? appTheme.pixelPerfect(80) : appTheme.pixelPerfect(50);
        return <View onLayout={() => setSegmentMenuDidAppear(true)} style={{ alignItems: "center", justifyContent: "flex-start", width: appTheme.getFullAppWidth() }}>
            <Divider />
            <Image source={headerIcon} style={{ width: iconSize, height: iconSize }} resizeMode="contain" />
            <Divider />
            <Text style={menuStyle.titleStyle}>{headerTitle.toLocaleUpperCase()}</Text>
            <Divider />
            <View style={{ flexDirection: "row", width: typeMenuWidth, height: appTheme.pixelPerfect(36), borderRadius: appTheme.pixelPerfect(20), backgroundColor: appTheme.white }}>
                <Animated.View style={{ position: "absolute", top: 0, left: menuBackgroundPosition, backgroundColor: menuColor, width: "50%", height: "100%", borderRadius: appTheme.pixelPerfect(20) }} />
                <TouchableOpacity onPress={() => onSelectMenuItem(0)} style={{ width: "50%", height: "100%", justifyContent: "center", alignItems: "center" }}>
                    <Animated.Text style={{ fontFamily: appTheme.primaryBoldFont, color: getFontForMenuItemIndex(0), fontSize: appTheme.pixelPerfectForFont(8) }}>{firstMenuItemTitle}</Animated.Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => onSelectMenuItem(1)} style={{ width: "50%", height: "100%", justifyContent: "center", alignItems: "center" }}>
                    <Animated.Text style={{ fontFamily: appTheme.primaryBoldFont, color: getFontForMenuItemIndex(1), fontSize: appTheme.pixelPerfectForFont(8) }}>{secondMenuItemTitle}</Animated.Text>
                </TouchableOpacity>
            </View>
            <Divider />
        </View>;
    }

    const goToSuperRoom = (path?: string, stripe?: {status:string, subId}) => {
        const activitiesByTypeIndex = user.profile === "family" ? 1 : 0;
        let superRoomPath: string = path !== undefined ? path : "";
        Navigation.showModalInView({
            componentId,
            name: Constants.ScreenSuperRoom,
            passProps: {
                path:superRoomPath,
                stripe
            }
        });

        // On définie quel "lounge" est ouvert
        let loungeRoom: string = user.profile === "family" ? "parent_lounge" : user.profile === "animator" ? "animator_lounge" : "teacher_lounge";
        trackEvent(analyticsEventHpLoungeClicked, {
            "room": loungeRoom,
            [propertiesEventHpLoungeStartingPoint]: menuType === activitiesByTypeIndex ? propertiesValueHpLoungeStartingPointSection : propertiesValueHpLoungeStartingPointSuperpower
        });
    }

    const illustrationPosition = Platform.OS === "web" ? "fixed" : "absolute";
    const illustrationHeight = Platform.OS === "web" ? appTheme.getFullAppWidth() / 9 : appTheme.getFullAppWidth() * 2 / 9;
    const pictoIllustrationHeight = Platform.OS === "web" ? appTheme.pixelPerfect(160) : appTheme.pixelPerfect(80) + appTheme.topInsets;
    const illustationPaddingBottom = Platform.OS === "web" ? appTheme.pixelPerfect(160) : appTheme.pixelPerfect(80) + appTheme.topInsets;
    const bottomButtonWidth = appTheme.getFullAppWidth();
    const bottomButtonHeight = (bottomButtonWidth * 2 / 45);
    const fullBottomViewHeight = appTheme.pixelPerfect(60) + appTheme.bottomInsets;
    const activitiesByTypeIndex = user.profile === "family" ? 1 : 0;
    const activitiesBySkillsIndex = user.profile === "family" ? 0 : 1;
    const backgroundPosition: string = Platform.OS === "web" ? "fixed" : "absolute";

    const getFooter = () => {
        const imageSize = appTheme.pixelPerfect(60);
        const buttonTitle = user.profile === "teacher" ? i18n.t("appMenu.teachers.button.title") : user.profile === "animator" ? i18n.t("appMenu.animators.button.title") : i18n.t("appMenu.parents.button.title");
        const buttonBody = user.profile === "teacher" ? i18n.t("appMenu.teachers.button.body") : user.profile === "animator" ? i18n.t("appMenu.animators.button.body") : i18n.t("appMenu.parents.button.body");
        const buttonImage = user.profile === "teacher" ? images.icSuperTeachersRoom : images.icSuperParentsRoom;
        const paddingBottom = appTheme.bottomInsets > 0 ? appTheme.bottomInsets : appTheme.pixelPerfect(20);
        const waveHeight = Platform.OS === "web" ? bottomButtonWidth * 12 / 384 : bottomButtonWidth * 16 / 360;
        return <View style={{ position: illustrationPosition, bottom: 0, right: 0, left: 0 }}>
            <View style={{ width: appTheme.getFullAppWidth(), height: waveHeight }}>
                <Animated.Image source={images.illTeacherWave} style={{ position: "absolute", width: appTheme.getFullAppWidth(), height: waveHeight, opacity: getBackgroundImageOpacity(activitiesByTypeIndex) }} resizeMode="cover" />
                <Animated.Image source={images.illFamilyWave} style={{ position: "absolute", width: appTheme.getFullAppWidth(), height: waveHeight, opacity: getBackgroundImageOpacity(activitiesBySkillsIndex) }} resizeMode="cover" />
            </View>
            <Animated.View style={{ width: appTheme.getFullAppWidth(), justifyContent: "center", alignItems: "center", backgroundColor: getWaveColor() }}>
                <TouchableOpacity onPress={goToSuperRoom} style={{ flexDirection: "row", width: appTheme.getBlockWidth(), justifyContent: "center", alignItems: "center", paddingBottom }}>
                    <View style={{ flex: 1, paddingHorizontal: appTheme.pixelPerfect(10), alignItems: "center" }}>
                        <Text style={{ fontFamily: appTheme.primaryBoldFont, textTransform: "uppercase", color: appTheme.white, fontSize: appTheme.pixelPerfectForFont(7), textAlign: "center" }}>{buttonTitle}</Text>
                        <Text style={{ fontFamily: appTheme.primaryMediumFont, color: appTheme.white, fontSize: appTheme.pixelPerfectForFont(6), textAlign: "center" }}>{buttonBody}</Text>
                    </View>
                    <Image source={buttonImage} style={{ width: imageSize, height: imageSize, marginEnd: appTheme.pixelPerfect(10) }} resizeMode="contain" />
                </TouchableOpacity>
            </Animated.View >
        </View>
    }

    const getModalContent = () => {
        const url = launchEcologie === true ? "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/Mockup%2Fecologie.mp4?alt=media&token=13ee8762-dd8f-4354-8cc8-87fe5b92bffd" : "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/Mockup%2Fhumournoir.mp4?alt=media&token=4a14b8fe-904b-422a-8f62-4445b465001f";
        return <LiliVideoPlayer
            onVideoCompleted={() => {
                if (launchEcologie === true) {
                    setLaunchEcologie(false);
                } else {
                    setLaunchInspiration(false);
                }
            }}
            url={url}
            closeTheDocument={() => {
                if (launchEcologie === true) {
                    setLaunchEcologie(false);
                } else {
                    setLaunchInspiration(false);
                }
            }} />
    }

    return (
        <Main mainOptions={mainOptions}>
            <View style={{ flex: 1, width: appTheme.getFullAppWidth() }}>
                <Animated.View style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight(), position: backgroundPosition, backgroundColor: getBackgroundColor() }}>
                    <Animated.Image source={images.bgDayClouds} style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight(), position: "absolute", opacity: getBackgroundImageOpacity(activitiesByTypeIndex) }} resizeMode="contain" />
                    <Animated.Image source={images.bgNightStars} style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight(), position: "absolute", opacity: getBackgroundImageOpacity(activitiesBySkillsIndex) }} resizeMode="cover" />
                </Animated.View>
                <View style={{ position: illustrationPosition, bottom: 0, right: 0, left: 0, paddingBottom: illustationPaddingBottom }}>
                    <Animated.View style={{ width: appTheme.getFullAppWidth(), height: pictoIllustrationHeight + appTheme.pixelPerfect(10), backgroundColor: getGroundColor(), position: "absolute", bottom: 0 }} />
                    <View style={{ width: appTheme.getFullAppWidth(), height: illustrationHeight }}>
                        <Animated.Image source={images.illDayGround} style={{ position: "absolute", width: appTheme.getFullAppWidth(), height: illustrationHeight, opacity: getBackgroundImageOpacity(activitiesByTypeIndex) }} resizeMode="cover" />
                        <Animated.Image source={images.illNightGround} style={{ position: "absolute", width: appTheme.getFullAppWidth(), height: illustrationHeight, opacity: getBackgroundImageOpacity(activitiesBySkillsIndex) }} resizeMode="cover" />
                    </View>
                </View>
                <ScrollView showsVerticalScrollIndicator={false} style={{ flex: 1, width: appTheme.getFullAppWidth() }}>
                    <View style={{ flex: 1, justifyContent: "center" }}>
                        {getHeaderMenu()}
                        <Animated.View style={{ flex: 1, opacity: mainViewOpacity }}>
                            {getContent()}
                        </Animated.View>
                        {getExtraContent()}
                        <Divider size={fullBottomViewHeight + bottomButtonHeight} />
                    </View>
                </ScrollView>
                {getFooter()}
                <Modal animationType="slide" visible={(launchInspiration === true) || (launchEcologie === true)} transparent={true} style={{ width: appTheme.getFullScreenWidth() }}>
                    <View style={{ flex: 1, alignItems: "center", backgroundColor: "#12345680", width: appTheme.getFullScreenWidth() }}>
                        <View style={{ flex: 1, alignItems: "center", justifyContent: "flex-end", width: appTheme.getFullAppWidth() }}>
                            {getModalContent()}
                        </View>
                    </View>
                </Modal>
                <InViewModal ref={inViewModal} />
            </View>
        </Main>
    )
}

export default SchoolMenu;

const styles = StyleSheet.create({
    header: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    superPowerButton: {
        alignItems: "center",
        justifyContent: "center"
    }
});

