import AsyncStorage from "@react-native-async-storage/async-storage";
import { keyUserData, EventUserAuthDB, UserType } from "../../utils/Constants";

import { getPurchaserInfo } from "../../../specific/services/InAppPurchase/InAppPurchaseService";
import { addDataToUser } from "../../services/Database";
import { version, build } from "../../../../package.json";
import { addPremiumTagToUser } from "../../services/Mailing";
import { AppTarget, getAppTarget } from "../../../specific/utils/Navigation/Host";
import { getDayForTimestamp, getNumericDateForAnalyticForTimestamp } from "../../utils/DateUtils";

export type SubStatus = "free" | "premium" | "pre-sub" | "premium_trial" | "premium_tne";

class Code {
    public code: string;
    public key: string;

    constructor(code: { code: string, key: string }) {
        this.code = code.code;
        this.key = code.key;
    }
}

class Subscription {
    public code?: string;
    public creationDate?: number;
    public customer_email: string;
    public date: number;
    public expires_date: number;
    public pre_expires_date?: number;
    public origin: string;
    public product_id: string;
    public start_date: number;
    public auto_renewing: boolean;
    public period_type?: string;
    public stripe_sub_id?: string;

    constructor(subscription: { code?: string, creationDate?: number, customer_email: string, date: number, expires_date: number, origin: string, product_id: string, start_date: number, auto_renewing: boolean, period_type?: string, stripe_sub_id?: string, pre_expires_date?: number }) {
        this.code = subscription.code !== undefined ? subscription.code : "";
        this.creationDate = subscription.creationDate;
        this.customer_email = subscription.customer_email;
        this.date = subscription.date;
        this.expires_date = subscription.expires_date;
        this.origin = subscription.origin;
        this.product_id = subscription.product_id;
        this.start_date = subscription.start_date;
        this.auto_renewing = subscription.auto_renewing;
        this.period_type = subscription.period_type ?? "NORMAL";
        this.stripe_sub_id = subscription.stripe_sub_id;
        this.pre_expires_date = subscription.pre_expires_date ?? 0;
    }
}

type Steps = {
    filter?: boolean,
    tutorial?: boolean,
    webinar?: boolean,
    advicesByLevel?: boolean,
    advicesByActivityType?: boolean,
    path?: boolean,
    academy?: boolean,
    notice?: boolean,
    poster?: boolean
}

class User {
    private static instance: User;

    // Auth
    public email: string = "";
    public uid: string = "";

    // DB
    public admin: boolean = false;
    public bizdev: boolean = false;
    public teamLeader: boolean = false;
    public avatar: string = "base";
    public code: Code | undefined = undefined;
    public favorite_books: string[] = [];
    public favorite_activities: string[] = [];
    public onboardingOptIn: boolean | undefined = false;
    public optIn: boolean | undefined = false;
    public sub: Subscription | undefined = undefined;
    public version: string = version;
    public build: number = parseInt(build);
    public creation_version: string = "1.X";
    public creation_build: number = 347;
    public firstname: string = "";
    public lastname: string = "";
    public filter_by_level: string[] = ["cycle1", "cycle2", "cycle3"];
    public profile?: UserType;
    public steps?: Steps;

    // GAMIFICATION
    public level: number = 1;
    public pts: number = 0;
    public badges: any = {};
    public days_in_a_row: number = 1;

    // TNE
    public tne_ido?: string = "";
    public tne_pro?: string = "";
    public tne_p_ms2?: string = "";
    public tne_uai?: string = "";
    public demo?: boolean | undefined = undefined;

    // MEDIA - Liste des media vus pour griser ou non les listes bien-être, philo, etc...
    // Pour chaque catégorie, on va lister ce qui a éyté fait ou non
    public media?:{[key: string]: string[]}  | undefined = undefined;

    private constructor() {

    }

    public static getInstance(): User {
        if (!User.instance) {
            User.instance = new User();
        }

        return User.instance;
    }

    public getUserData = () => {
        return this;
    }

    public removePreSub = () => {
        const todayInTS = new Date().getTime();
        this.sub = new Subscription({
            customer_email: this.email,
            creationDate: todayInTS,
            date: todayInTS,
            start_date: todayInTS,
            expires_date: todayInTS,
            pre_expires_date: todayInTS,
            auto_renewing: false,
            origin: "stripe",
            product_id: "stripe_cancel"
        });
    }

    public successPreSub = () => {
        this.sub.product_id = "stripe_sucess";
    }

    public initUserData = async (userAuthData: any) => {
        const userData = await AsyncStorage.getItem(keyUserData);
        if (userData !== null) {
            const data: User = JSON.parse(userData);
            this.email = data.email;
            this.uid = data.uid;
            if (data.admin !== undefined) {
                this.admin = data.admin;
            } else {
                this.admin = false;
            }
            if (data.bizdev !== undefined) {
                this.bizdev = data.bizdev;
            } else {
                this.bizdev = false;
            }
            if (data.teamLeader !== undefined) {
                this.teamLeader = data.teamLeader;
            } else {
                this.teamLeader = false;
            }
            this.avatar = data.avatar;
            this.email = data.email;
            this.favorite_books = data.favorite_books;
            this.favorite_activities = data.favorite_activities ?? [];
            this.creation_build = data.creation_build;
            this.creation_version = data.creation_version;
            this.version = data.version;
            this.build = data.build;
            if (data.filter_by_level !== undefined) {
                this.filter_by_level = data.filter_by_level;
            }

            if (data.onboardingOptIn !== undefined) {
                this.onboardingOptIn = data.onboardingOptIn;
            }
            if (data.optIn !== undefined) {
                this.optIn = data.optIn;
            }
            if (data.sub !== undefined) {
                this.sub = new Subscription(data.sub);
            }
            if (data.media !== undefined) {
                this.media = data.media;
            }
            if ((data.code !== undefined) && (data.code.code !== undefined)) {
                this.profile = "teacher";
            }
            if (data.steps !== undefined) {
                this.steps = data.steps;
            } else {
                this.steps = {
                    filter: false,
                    tutorial: false,
                    webinar: false,
                    advicesByLevel: false,
                    advicesByActivityType: false,
                    path: false,
                    academy: false,
                    notice: false,
                    poster: false
                }
            }
        } else {
            this.uid = userAuthData.uid;
            this.email = userAuthData.email;
        }
        return this;
    }

    public getUserProfileForAnalytics = () => {
        if (this.profile === "family") {
            return "parent";
        } else if (this.profile !== "neutral") {
            return this.profile;
        } else {
            return undefined;
        }
    }
    
    public getUserSubscriptionStatusForAnalytics = () => {
        let subDetails: {subStatus:SubStatus, endDate:string} = {
            subStatus:"free",
            endDate:"free"
        }
        if (this.sub !== undefined) {
            const now = new Date().getTime();
            let startDate = this.sub.start_date !== undefined ? this.sub.start_date : this.sub.date !== undefined ? this.sub.date : 0;
            if ((now < this.sub.expires_date) && (now > startDate)) {
                const timeToEndDate: number = this.sub.expires_date - startDate;
                if (timeToEndDate < 864000000) {
                    subDetails = {
                        subStatus:"premium_trial",
                        endDate: getNumericDateForAnalyticForTimestamp(this.sub.expires_date)
                    }
                } else {
                    subDetails = {
                        subStatus:"premium",
                        endDate: getNumericDateForAnalyticForTimestamp(this.sub.expires_date)
                    }
                }
            }
        }
        return subDetails;
    }

    public isTeacher = () => {
        if ((this.profile === undefined) || ((this.profile !== undefined) && (this.profile === "teacher"))) {
            return true;
        };
        return false;
    }

    public userProfile = () => {
        return this.profile;
    }

    public getPremiumStatus = () => {
        let subStatus: SubStatus = "free";
        if (this.sub !== undefined) {
            const now = new Date().getTime();
            let startDate = this.sub.start_date !== undefined ? this.sub.start_date : this.sub.date !== undefined ? this.sub.date : 0;
            if ((now < this.sub.expires_date) && (now > startDate)) {
                subStatus = "premium";
            } else if ((this.sub.pre_expires_date !== undefined) && (this.sub.pre_expires_date > 0)) {
                // On va regarder si cela fait plus de 2h qu'il a demandé un abonnement et que le product_id est égale à stripe_success
                if ((this.sub.product_id === "stripe_success") && (now < this.sub.pre_expires_date) && (now > startDate) && (now - startDate < 7200000)) {
                    subStatus = "pre-sub";
                } else {
                    subStatus = "free";
                }
            }
        }
        return subStatus;
    }

    public isPremium = () => {
        if (this.sub !== undefined) {
            const now = new Date().getTime();
            let startDate = this.sub.start_date !== undefined ? this.sub.start_date : this.sub.date !== undefined ? this.sub.date : 0;
            if ((now < this.sub.expires_date) && (now > startDate)) {
                return true;
            }
        };
        return false;
    }

    public isActivityFavorite(activityId: string) {
        if (this.favorite_activities !== undefined) {
            if (this.favorite_activities.indexOf(activityId) !== -1) {
                return true;
            }
        }
        return false;
    }

    public setAuthUserData = async (data: { email: string | null | undefined, uid: string | undefined }) => {
        this.email = data.email ?? "";
        this.uid = data.uid ?? "";
        await this.saveUserData();
        return this;
    }

    public setDBUserData = async (data: { bizdev?: boolean, admin?: boolean, teamLeader?: boolean, avatar?: string, email: string, uid?: string, favorite_books?: string[], favorite_activities?: string[], onboardingOptIn?: boolean, optIn?: boolean, sub?: Subscription, code?: Code, creation_build?: number, build?: number, creation_version?: string, version?: string, filter_by_level?: string[], media?: {[key: string]: string[]}, profile?: UserProfile, steps?: Steps  }) => {
        if (data.admin !== undefined) {
            this.admin = data.admin;
        } else {
            this.admin = false;
        }
        if (data.bizdev !== undefined) {
            this.bizdev = data.bizdev;
        } else {
            this.bizdev = false;
        }
        if (data.teamLeader !== undefined) {
            this.teamLeader = data.teamLeader;
        } else {
            this.teamLeader = false;
        }
        this.avatar = data.avatar;
        this.email = data.email;
        this.favorite_books = data.favorite_books ?? [];
        this.favorite_activities = data.favorite_activities ?? [];
        this.creation_build = data.creation_build ?? 347;
        this.creation_version = data.creation_version ?? "1.X";
        this.build = data.build ?? parseInt(build);
        this.version = data.version ?? version;
        if (data.filter_by_level !== undefined) {
            this.filter_by_level = data.filter_by_level;
        }
        if (data.onboardingOptIn !== undefined) {
            this.onboardingOptIn = data.onboardingOptIn;
        }
        if (data.optIn !== undefined) {
            this.optIn = data.optIn;
        }
        if (data.sub !== undefined) {
            this.sub = new Subscription(data.sub);
        }
        if (data.code !== undefined) {
            this.code = data.code;
            this.profile = "teacher";
        } else {
            this.code = undefined;
        }
        if (data.profile !== undefined) {
            this.profile = data.profile;
        }
        if (this.profile === undefined) {
            this.profile = "family";
        }
        const appTarget: AppTarget = getAppTarget();
        if (appTarget !== "public") {
            this.profile = "teacher";
        }
        
        if (data.media !== undefined) {
            this.media = data.media;
        }
        if (data.steps !== undefined) {
            this.steps = data.steps;
        } else {
            this.steps = {
                filter: false,
                tutorial: false,
                webinar: false,
                advicesByLevel: false,
                advicesByActivityType: false,
                path: false,
                academy: false,
                notice: false,
                poster: false
            }
        }
        await this.saveUserData();
        return this;
    }

    public setActionsUserData = async (data: { level: number, pts: number, badges: any, days_in_a_row: number }) => {
        const { level, pts, badges, days_in_a_row } = data;
        this.level = level;
        this.pts = pts;
        this.badges = badges;
        this.days_in_a_row = days_in_a_row;
        await this.saveUserData();
        return this;
    }

    public getUserSubForRC = async () => {
        try {
            const purchaserInfo = await getPurchaserInfo();
            if (purchaserInfo !== null) {
                this.addSubToUser(purchaserInfo);
            }
        } catch (error) {

        }
    }

    public revokeRenwability = async () => {
        let userSub = this.sub;
        userSub.auto_renewing = false;
        this.sub = userSub;
        this.saveUserData();
    }

    public addSubToUser = async (purchaserInfo) => {
        this.sub = new Subscription(purchaserInfo);
        addPremiumTagToUser({ email: this.email });
        await addDataToUser({
            key: "sub",
            value: purchaserInfo,
            user_id: this.uid
        });
    }

    saveUserData = async () => {
        await AsyncStorage.setItem(keyUserData, JSON.stringify(this))
    }

    public deleteUser = async () => {
        this.uid = "";
        this.lastname = "";
        this.firstname = "";
        this.tne_ido = "";
        this.tne_pro = "";
        this.tne_p_ms2 = "";
        this.tne_uai = "";
        this.admin = false;
        this.bizdev = false;
        this.teamLeader = false;
        this.avatar = "base";
        this.email = "";
        this.profile = undefined;
        this.favorite_activities = [];
        this.favorite_books = [];
        this.onboardingOptIn = false;
        this.optIn = false;
        this.sub = undefined;
        this.code = undefined;
        this.level = 0;
        this.pts = 0;
        this.badges = {};
        this.days_in_a_row = 0;
        this.media = undefined;
        this.steps = undefined;
        await AsyncStorage.removeItem(keyUserData);
    }
}

export default User;