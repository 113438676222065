import React, { useEffect, useRef, useState } from "react";

import {
    Modal,
    Platform,
    Text,
    TouchableOpacity,
    View
} from "react-native";
import PlayButton from "../../../designSystem/PlayButton";
import PremiumButton from "../../../designSystem/PremiumButton";
import i18n, { getLanguage } from "../../../utils/Localization/Localization";
import AppTheme from "../../../utils/Theme";

// Analytics
import { trackEvent, analyticsEventActivityLaunch, propertiesEventContext, propertiesEventContent, propertiesEventAccess, propertiesEventCategory, propertiesEventCompleted, propertiesEventSection, propertiesEventName, analyticsEventActivityCompleted, analyticsEventActivityDownloaded, propertiesEventProfile, analyticsEventWorkshopLaunched, analyticsEventWorkshopCompleted } from "../../../services/Tracking/Analytics";
import User from "../../../data/user/User";
import ActivityPlayer from "../../Audio/ActivityPlayer";
import ActivityIndicator from "../../../designSystem/ActivityIndicator/ActivityIndicator";
import { checkLocalUrls } from "../../../utils/LocalStorage";
import { onActivityCompleted } from "../../../../specific/services/Specific_Database";
import Activity from "../../../models/Activity";
import Style from "../../../utils/Style";
import SVGView from "../../../utils/SvgView";
import { default as icDismiss } from "../../../assets/svg/icons/solid/times.svg";
import { default as IcFilm } from "../../../assets/svg/icons/solid/film.svg";
import { default as IcHeadphones } from "../../../assets/svg/lili/ic_audio_book.svg";
import { default as IcFilePdf } from "../../../assets/svg/lili/ic_pdf.svg";
import { default as IcDocument } from "../../../assets/svg/lili/ic_pdf.svg";
import { default as IcTapuscrit } from "../../../assets/svg/lili/ic_tapuscrit.svg";
import { addOrRemoveOfflineData, deleteFileForActivityKey } from "../../../../specific/components/Download/DownloadButton";

import ActionSheet from "../../ActionSheets/ActionSheet";
import RoundIconButton from "../../../designSystem/RoundIconButton";
import PDFViewer from "../../../../specific/components/PDF/PDFViewer";
import { getAppTarget } from "../../../../specific/utils/Navigation/Host";
import { getDocumentUrlForActivityId, getDocumentUrlForMediaId } from "../../../../specific/services/AppWrite/AppWriteDatabase";

type localizedString = {
    fr?: string
}

interface ActivityCellBodyProps {
    activity: Activity;
    color: string;
    width: number;
    isEnable?: boolean; // Définit si l'activité est gratuite ou si premium, que l'utilisateur a bien un abo
    urls?: string[];
    componentId?: string;
    context: string,
    categoryAnalyticsKey: string,
    sectionAnalyticsKey: string,
    title: localizedString,
    localImgPath: string,
    categoryKey: string,
    sub_type: string,
    starting_point?: "superpower" | "section"
}

const ActivityCellActionAudio = (props: ActivityCellBodyProps) => {
    const { color, width, isEnable = false, urls = [], componentId = "", context, categoryAnalyticsKey, sectionAnalyticsKey, title, localImgPath, categoryKey, activity, sub_type, starting_point = "superpower" } = props;

    const appTheme: AppTheme = new AppTheme();
    const user: User = User.getInstance();
    const [launchPlayer, setLaunchPlayer] = useState<boolean>(false);
    const [showChoices, setShowChoices] = useState<boolean>(false);
    const [selectedChoice, setSelectedChoice] = useState<{ key?: string, urls?: string[] }>({});
    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const [audioUrls, setAudioUrls] = useState<string[]>([]);
    const [launchDocument, setLaunchDocument] = useState<boolean>(false);

    const actionSheetRef = useRef<ActionSh>(null);

    useEffect(() => {
        if (audioUrls.length > 0) {
            setViewDidAppear(true);
        }
    }, [audioUrls]);

    const onViewDidAppear = () => {
        setUrls();
    }

    const setUrls = async () => {
        let urlToLaunch: any[] = [];
        if (activity.sectionKey === "still") {
            urlToLaunch.push(require("../../../assets/audio/lili_je_me_pose.mp3"));
        } else if (activity.sectionKey === "talk") {
            urlToLaunch.push(require("../../../assets/audio/lili_je_m_exprime.mp3"));
        }
        let audioUrls = urls;
        if (Platform.OS !== "web") {
            if (activity.offline !== undefined) {
                // Etant donné la mise en place des choix dans les activités audio (avec /sans musique, avec / sans intro ....), on doit vérifier que ces choix sont inclus dans le dictionnaire offline.
                // Si ce n'est pas le cas, cela signiie que le téléchargement a été fait avant et qu'il n'est plus valide
                if (activity.offline.action !== undefined) {
                    const { action } = activity.offline;
                    if ((action.choices === undefined) && (activity.action.choices !== undefined)) {
                        // On a un choix online, qu'on n'a pas offline > Donc on supprime l'entrée offline de cette activité
                        await addOrRemoveOfflineData(undefined, activity.key);
                        await deleteFileForActivityKey(activity.key);
                    } else if ((activity.offline.action !== undefined) && (activity.offline.action.audio !== undefined) && (activity.offline.action.audio[getLanguage()] !== undefined)) {
                        audioUrls = checkLocalUrls(activity.offline.action.audio[getLanguage()]);
                    }
                }

            }
        }
        urlToLaunch = urlToLaunch.concat(audioUrls);
        if (activity.sectionKey === "still") {
            urlToLaunch.push(require("../../../assets/audio/lili_je_me_pose.mp3"));
        } else if (activity.sectionKey === "talk") {
            urlToLaunch.push(require("../../../assets/audio/lili_je_m_exprime.mp3"));
        }
        setAudioUrls(urlToLaunch);
    }

    useEffect(() => {
        if (launchPlayer === true) {
            trackEvent(analyticsEventActivityLaunch, {
                [propertiesEventCategory]: categoryAnalyticsKey,
                [propertiesEventContent]: sub_type,
                [propertiesEventAccess]: activity.isPremium() === true ? "premium" : "free",
                [propertiesEventContext]: context,
                [propertiesEventName]: activity.analytics_key,
                "starting_point": starting_point
            });
        }
    }, [launchPlayer]);

    useEffect(() => {
        if (selectedChoice.key !== undefined) {
            setLaunchPlayer(true);
        }
    }, [selectedChoice]);

    const onChoiceDone = (choice: { key: string, path: string[] }) => {
        togglePlayer();
        const { key, path } = choice;
        let urlToLaunch: any[] = [];
        if (activity.sectionKey === "still") {
            urlToLaunch.push(require("../../../assets/audio/lili_je_me_pose.mp3"));
        } else if (activity.sectionKey === "talk") {
            urlToLaunch.push(require("../../../assets/audio/lili_je_m_exprime.mp3"));
        }
        let audioUrls = path;
        if (Platform.OS !== "web") {
            if (activity.offline !== undefined) {
                audioUrls = checkLocalUrls(path);
            }
        }
        urlToLaunch = urlToLaunch.concat(audioUrls);
        if (activity.sectionKey === "still") {
            urlToLaunch.push(require("../../../assets/audio/lili_je_me_pose.mp3"));
        } else if (activity.sectionKey === "talk") {
            urlToLaunch.push(require("../../../assets/audio/lili_je_m_exprime.mp3"));
        }
        setSelectedChoice({ key, urls: urlToLaunch });
    }

    const getModalContent = () => {
        if (showChoices === false) {
            return <View />
        }
        if ((activity !== undefined) && (activity.action !== undefined) && (activity.action.choices !== undefined)) {
            let action = activity.action;
            if ((activity.offline !== undefined) && (activity.offline.action !== undefined) && (activity.offline.action.choices !== undefined)) {
                action = activity.offline.action;
            }
            const marginHorizontal = appTheme.pixelPerfect(20);
            const buttonWidth = width - marginHorizontal * 2;
            const buttonPadding = appTheme.pixelPerfect(5);
            const iconWidth = appTheme.pixelPerfect(40);
            const textWidth = buttonWidth - iconWidth - 2 * buttonPadding;
            const buttonFontSize = appTheme.pixelPerfectForFont(12);
            const buttonHeight = appTheme.pixelPerfect(80);
            const windowMarginBottom = Platform.OS === "web" ? appTheme.pixelPerfect(20) : 0;
            // On va créer kes choix en fonctions de ce qui est présent dans le tableau
            // Le premier choix est l'audio par défaut
            const { main_sub_type, others, title } = action.choices;
            let choicesView = [];
            for (const aChoiceIndex in others) {
                if (Object.prototype.hasOwnProperty.call(others, aChoiceIndex)) {
                    const aChoice = others[aChoiceIndex];
                    const svgIcon = aChoice.type === "audio" ? IcHeadphones : aChoice.type === "video" ? IcFilm : IcDocument;
                    choicesView.push(<TouchableOpacity key={aChoice.key} onPress={() => onChoiceDone({ key: aChoice.key, path: [aChoice.url[getLanguage()]] })} style={{ paddingHorizontal: buttonPadding, flexDirection: "row", alignItems: "center", justifyContent: "space-around", backgroundColor: appTheme.white, width: buttonWidth, height: buttonHeight, borderRadius: appTheme.pixelPerfect(20), marginBottom: appTheme.pixelPerfect(20) }}>
                        <View style={{ width: iconWidth, alignItems: "center", justifyContent: "center" }}>
                            <SVGView Component={svgIcon} size={30} color={props.color} />
                        </View>
                        <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                            <Text numberOfLines={3} style={{ textAlign: "center", fontFamily: appTheme.secondaryFont, fontSize: buttonFontSize, color: props.color, width: textWidth }}>{i18n.t("choices." + aChoice.sub_type).toLocaleUpperCase()}</Text>
                        </View>
                    </TouchableOpacity>);
                }
            }
            choicesView.push(<TouchableOpacity key="main" onPress={() => onChoiceDone({ key: "main", path: action.audio[getLanguage()] })} style={{ paddingHorizontal: buttonPadding, flexDirection: "row", alignItems: "center", justifyContent: "space-around", backgroundColor: appTheme.white, width: buttonWidth, height: buttonHeight, borderRadius: appTheme.pixelPerfect(20), marginBottom: appTheme.pixelPerfect(20) }}>
                <View style={{ width: iconWidth, alignItems: "center", justifyContent: "center" }}>
                    <SVGView Component={IcHeadphones} size={30} color={props.color} />
                </View>
                <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                    <Text numberOfLines={3} style={{ textAlign: "center", fontFamily: appTheme.secondaryFont, fontSize: buttonFontSize, color: props.color, width: textWidth }}>{i18n.t("choices." + main_sub_type).toLocaleUpperCase()}</Text>
                </View>
            </TouchableOpacity>);
            return <View style={[Style.shadowed, { paddingTop: appTheme.pixelPerfect(10), backgroundColor: props.color, width, borderRadius: appTheme.pixelPerfect(10), alignItems: "center", marginBottom: windowMarginBottom }]}>
                <View style={{ marginBottom: appTheme.pixelPerfect(20), width, paddingHorizontal: appTheme.pixelPerfect(10), alignItems: "flex-end" }}>
                    <TouchableOpacity onPress={() => setShowChoices(!showChoices)} style={{ backgroundColor: appTheme.white, width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), borderRadius: appTheme.pixelPerfect(15), justifyContent: "center", alignItems: "center" }}>
                        <SVGView Component={icDismiss} size={20} color={props.color} />
                    </TouchableOpacity>
                </View>
                <Text style={{ paddingHorizontal: appTheme.pixelPerfect(20), textAlign: "center", fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(16), color: appTheme.white }}>
                    {i18n.t("choices." + title)}
                </Text>
                <View style={{ marginVertical: appTheme.pixelPerfect(20) }}>
                    {choicesView}
                </View>
            </View>;
        }
        return <View />;
    }

    const togglePlayer = () => {
        if (launchPlayer === true) {
            setLaunchPlayer(false);
        } else {
            if (activity.action.choices !== undefined) {
                setShowChoices((showChoices) => !showChoices);
            } else {
                setLaunchPlayer(!launchPlayer);
            }
        }
    }

    const onActivityCompletedForGamification = () => {
        onActivityCompleted({
            activityKey: activity.key,
            sectionKey: activity.sectionKey,
            categoryKey,
            iteration: 1,
            detail: "",
            menu: ""
        });
        trackEvent(analyticsEventActivityCompleted, {
            [propertiesEventCategory]: categoryAnalyticsKey,
            [propertiesEventContent]: sub_type,
            [propertiesEventAccess]: activity.isPremium() === true ? "premium" : "free",
            [propertiesEventContext]: context,
            [propertiesEventName]: activity.analytics_key,
            "starting_point": starting_point
        });
    }

    const showChapterList = () => {
        const chaptersUrls = selectedChoice.urls !== undefined ? selectedChoice.urls : audioUrls;
        actionSheetRef.current.showAlert({
            actionSheetType: "AUDIO_CHAPTER",
            data: {
                title: activity.title[getLanguage()],
                urls: chaptersUrls,
                chapters: activity.action.chapters !== undefined ? activity.action.chapters : [],
                localImage: activity.app_image,
                remoteImage: activity.img_url,
                color: props.color
            }
        });
    }

    const openTheDocument = () => {
        setLaunchDocument(true);
    }

    const getActionButton = () => {
        if (viewDidAppear === false) {
            return <ActivityIndicator loading={!viewDidAppear} color={color} />
        }
        let buttonTitleView = <View />;
        if (sub_type === "audio_chapters") {
            buttonTitleView = <Text style={{ paddingTop: appTheme.pixelPerfect(10), fontFamily: appTheme.secondaryFont, color, fontSize: appTheme.pixelPerfectForFont(12), alignItems: "center" }}>
                {"Podcast"}
            </Text>;
        }
        const activityActionButtton = isEnable === true ? <View key={"play"} style={{ alignItems: "center" }}>
            <PlayButton disabled={audioUrls.length === 0} onPress={sub_type === "audio_chapters" ? showChapterList : togglePlayer} backgroundColor={color} />
            {buttonTitleView}
        </View> : <PremiumButton componentId={componentId} />;
        let actionButtonContent = [activityActionButtton];
        let transcriptName: string = "Tapuscrit";
        if ((activity.document !== undefined) && (activity.document[getLanguage()] !== undefined) && (activity.document[getLanguage()].file !== undefined)) {
            const openTranscriptButton = <View key={"transcript"} style={{ alignItems: "center", marginStart: appTheme.pixelPerfect(20) }}>
                <RoundIconButton onPress={openTheDocument} iconName={IcTapuscrit} backgroundColor={color} />
                <Text style={{ textAlign: "center", paddingTop: appTheme.pixelPerfect(10), fontFamily: appTheme.secondaryFont, color, fontSize: appTheme.pixelPerfectForFont(12), alignItems: "center" }}>
                    {transcriptName}
                </Text>
            </View>;
            actionButtonContent.push(openTranscriptButton);
        }
        return <View style={{ flexDirection: "row", width, justifyContent: "center" }}>
            {actionButtonContent}
        </View>
    }

    const getDocumentModal = () => {
        if (launchDocument === false) {
            return <View />
        }
        if ((activity.document !== undefined) && (activity.document[getLanguage()] !== undefined) && (activity.document[getLanguage()].file !== undefined)) {
            const appTarget = getAppTarget();
            let pdfUrl: string = activity.document[getLanguage()].file;
            if (appTarget !== "public") {
                pdfUrl = getDocumentUrlForActivityId({activity_id: activity.key});
            }
            return <PDFViewer
                title={activity.title[getLanguage()]}
                pdfUrl={pdfUrl}
                color={color}
                sectionId={activity.sectionKey}
                closeTheDocument={() => setLaunchDocument(false)} />
        }
        return <View />;
    }


    return <View onLayout={onViewDidAppear} style={{ width, alignItems: "center", marginTop: appTheme.pixelPerfect(10) }}>
        {getActionButton()}
        <Modal animationType="slide" visible={showChoices} transparent={true} style={{ width: appTheme.getFullScreenWidth() }}>
            <View style={{ flex: 1, alignItems: "center", backgroundColor: appTheme.white + "80", width: appTheme.getFullScreenWidth() }}>
                <View style={{ flex: 1, justifyContent: "flex-end", alignItems: "center", width: appTheme.getFullAppWidth() }}>
                    {getModalContent()}
                </View>
            </View>
        </Modal>
        <Modal animationType="slide" visible={launchPlayer} transparent={true} style={{ width: appTheme.getFullScreenWidth() }}>
            <View style={{ flex: 1, alignItems: "center", backgroundColor: color + "80", width: appTheme.getFullScreenWidth() }}>
                <View style={{ flex: 1, alignItems: "center", justifyContent: "flex-end", backgroundColor: color + "80", width: appTheme.getFullAppWidth(), marginBottom: appTheme.pixelPerfect(20) }}>
                    <ActivityPlayer
                        title={title[getLanguage()]}
                        notificationImage={Platform.OS === "ios" ? activity.img_url : localImgPath}
                        onActivityCompleted={onActivityCompletedForGamification}
                        urls={selectedChoice.urls !== undefined ? selectedChoice.urls : audioUrls}
                        color={props.color}
                        localImage={localImgPath}
                        remoteImage={activity.img_url}
                        togglePlayer={togglePlayer} />
                </View>
            </View>
        </Modal>
        <Modal animationType="slide" visible={launchDocument} transparent={true} style={{ width: appTheme.getFullScreenWidth() }}>
            <View style={{ flex: 1, alignItems: "center", backgroundColor: color + "80", width: appTheme.getFullScreenWidth() }}>
                <View style={{ flex: 1, justifyContent: "flex-end", backgroundColor: color + "80", width: appTheme.getFullAppWidth() }}>
                    {getDocumentModal()}
                </View>
            </View>
        </Modal>
        <ActionSheet textColor={color} componentId={props.componentId} ref={actionSheetRef} onChange={() => { }} />
    </View>

}

export default ActivityCellActionAudio;