import React, { useEffect, useRef, useState } from "react";

import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Platform, Animated, ScrollView, ActivityIndicator, ColorPropType } from "react-native";


/* UTILS */
import * as Constants from "../../utils/Constants";
import Style from "../../utils/Style";
import { getLanguage } from "../../utils/Localization/Localization";

/* CUSTOM COMPONENT */
import Image from "../../designSystem/OTBImage";

/* EXTERNAL */

import AsyncStorage from '@react-native-async-storage/async-storage';

import AppTheme from "../../utils/Theme";

// SVG
import SVGView from "../../utils/SvgView";
import Images from "../../../specific/utils/Images";
import { default as IcClose } from "../../assets/svg/icons/solid/times.svg";
import { default as IcVolumeUp } from "../../assets/svg/icons/solid/volume-up.svg";
import { default as IcSlash } from "../../assets/svg/icons/solid/slash.svg";
import { default as IcChevronLeft } from "../../assets/svg/icons/solid/chevron-left.svg";
import { default as IcChevronRight } from "../../assets/svg/icons/solid/chevron-right.svg";
import { default as IcRefresh } from "../../assets/svg/icons/solid/redo.svg";

import AudioPlayer from "../../../specific/utils/AudioPlayer/AudioPlayer";
import { playSound } from "../../../specific/utils/AudioPlayer/SimpleSoundPlayer";

import BundleGameLabels from "../../data/jsons/bundleGameLabels.json";
import { onActivityCompleted } from "../../../specific/services/Specific_Database";
import LiliAirPlayButton from "../../../specific/utils/AudioPlayer/AirPlayButton";
import { getPathForOriginal, getShortNameForUrl } from "../../../specific/components/Download/DownloadButton";
import { checkLocalUrl } from "../../utils/LocalStorage";
import { getAppTarget } from "../../../specific/utils/Navigation/Host";
import { getAudioUrlForMediaId } from "../../../specific/services/AppWrite/AppWriteDatabase";

let relaunchCount = 0;
let currentGameId = 0; //On ajoute un id de jeu pour faire la différence entre les jeux qui se relance
let go = false;
let paused = false;
let easterEggCount = 0;
let easterEggSound = false;
let easterEggSoundNumberOne = true;
let blockPosition = 0;
let isMute = false;

let isSoundPlaying = false;

const countdownAnimatedValue = new Animated.Value(0);

const getMinAndSecForCountdown = (time) => {
    const sec = parseInt(time, 10);
    let minutes = Math.floor(sec / 60);
    let seconds = sec - (minutes * 60);
    let minDigit = 0;
    let minUnit = 0;
    let secDigit = 0;
    let secUnit = 0;
    if (minutes < 10) {
        minDigit = 0;
        minUnit = minutes;
    } else {
        minDigit = Math.floor(minutes / 10);
        minUnit = minutes - (minDigit * 10);
    }
    if (seconds < 10) {
        secDigit = 0;
        secUnit = seconds;
    } else {
        secDigit = Math.floor(seconds / 10);
        secUnit = seconds - (secDigit * 10);
    }
    return { minDigit, minUnit, secDigit, secUnit };
}

const GamePlay = (props: any) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    const scrollViewRef = useRef<ScrollView>(null);
    const audioPlayerRef = useRef(null);
    const [activity, setActivity] = useState(props.activity);

    const width = Platform.OS === "web" ? appTheme.getFullAppWidth() : appTheme.getFullScreenHeight();
    const height = Platform.OS === "web" ? appTheme.getFullScreenHeight() : appTheme.getFullAppWidth();

    const { color = appTheme.talk } = props;

    const dbGameLabelsData = BundleGameLabels;

    const [viewDidAppear, setViewDidAppear] = useState(false);

    // Step 1
    const [gameInstructionValidated, setGameInstructionValidated] = useState(false); // On vérifie si l'utilisateur a écouter les instructions

    // Step 2
    const [countdownDisplayed, setCountdownDisplayed] = useState(false);
    const [countdownValue, setCountdownValue] = useState(5);

    // Step 3
    const [blockLabels, setBlockLabels] = useState({}); // On va stocker les labels sélectionnés par block
    const [histo, setHisto] = useState({}); // On va enregitrer l'historique des jeux pour éviter de reproposer trop souvent des labels identiques

    const [refresh, setRefresh] = useState(0);
    const [time, setTime] = useState(0);
    const [chosenLabels, setChosenLabels] = useState([]);

    const [isDownloaded, setIsDownloaded] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [automaticHisto, setAutomaticHisto] = useState([]); // On va stocker ici les labels utilisés au cours d'un jeu, quand ceux ci changent automatiquement (ex : la valse des émotions), pour ne pas donner 2x le même
    const [mute, setMute] = useState(false);
    const [numberOfPartyDone, setNumberOfPartyDone] = useState(0);// On va stocker le nombre de parties jouées, pour les envoyer ensuite au processus de gamification

    useEffect(() => {
        isMute = mute;
    }, [mute]);

    useEffect(() => {
        setActivity(props.activity);
    }, [props]);

    useEffect(() => {
        if (viewDidAppear === true) {
            relaunchCount = 0;
            currentGameId = 0;
            go = false;
            paused = false;
            easterEggCount = 0;
            easterEggSound = false;
            easterEggSoundNumberOne = true;
            blockPosition = 0;
            AsyncStorage.getItem(Constants.keyGameHistory).then((gameHistory) => {
                if (gameHistory !== null) {
                    setHisto(JSON.parse(gameHistory));
                }
            });
            if ((activity.mute !== undefined) && (activity.mute === true)) {
                setMute(true);
            }
        }
    }, [viewDidAppear]);

    // Apparition de l'écran
    const onViewDidAppear = () => {
        setViewDidAppear(true);
    }

    // Etape 1 : écoute des instructions
    // Méthode appelée à la fin de l'écoute des instructions
    const onPlayerEnd = () => {
        setGameInstructionValidated(true);
        playASound('countdown.mp3');
        launchCountDownValues();
    }

    // Etape 2 : Décompte
    // Méthode de lancement du décompte
    const launchCountDownValues = () => {
        setTimeout(() => {
            setCountdownValue(countdownValue - 1);
        }, 1000);
    }

    useEffect(() => {
        if (countdownValue !== 5) {
            setTimeout(() => {
                if (countdownValue > 1) {
                    setCountdownValue(countdownValue - 1);
                } else {
                    setCountdownValue(6);
                    setCountdownDisplayed(true);
                    launchGame(true);
                }
            }, 1000);
        }
    }, [countdownValue]);

    //  Etape 3 : On lance le jeu
    const launchGame = (firstTime: boolean) => {
        // On vérfiie que le jeu n'est pas fini, et que ce n'est pas la première partie - cela signifie qu'on relance un jeu et donc qu'on doit savoir si l'enfant à fini le jeu pour les analytics et la gamification
        if ((completed === false) && (firstTime === false)) {
            const timePlayed = parseInt(activity.duration) - time;
            if (timePlayed > 0.5 * parseInt(activity.duration)) {
                setNumberOfPartyDone(numberOfPartyDone + 1);
            }
        }

        // On s'assure que le jeu redémarre bien de la première carte
        if (firstTime === false) {
            scrollViewRef.current?.scrollTo({ x: 0, y: 0, animated: true });
        }

        // On affecte un nouvelle id de jeu
        currentGameId = new Date().getTime();

        // On lance le son pour indiquer que le jeu commence
        playASound('time.mp3');
        setCompleted(false);
        setTimeout(() => {
            //On va prendre les éléments des actions à faire au hasard
            go = false;
            blockPosition = 0;
            setAutomaticHisto([]);
            setBlockLabels({});

            let canDisplayGames = true;
            let blockLabels = {};
            let histoForGame = { ...histo };
            if (dbGameLabelsData !== undefined) {
                const blocks = activity.action.blocks;
                if (blocks !== undefined) {
                    blocks.forEach(block => {
                        const blockList = block.list + "_" + getLanguage();
                        if ((dbGameLabelsData[blockList] !== undefined) && (dbGameLabelsData[blockList].data !== undefined) && (dbGameLabelsData[blockList].data.length > 0)) {
                            let newElement = getBlockElement(dbGameLabelsData, blockList);
                            let histoForlist = histoForGame[blockList] !== undefined ? histoForGame[blockList] : [];
                            if (histoForlist.length === dbGameLabelsData[blockList].length) {
                                histoForlist.splice(0, 1);
                            }
                            while (histoForlist.includes(newElement.l) === true) {
                                newElement = getBlockElement(dbGameLabelsData, blockList);
                            }
                            blockLabels[block.key] = newElement;
                            histoForlist.push(newElement.l);
                            if ((histoForlist.length === 11) || (histoForlist.length === dbGameLabelsData[blockList].length - 1)) {
                                histoForlist.splice(0, 1);
                            }
                            histoForGame[blockList] = histoForlist;
                            setHisto(histoForGame);
                        } else {
                            canDisplayGames = false;
                        }
                    });
                }
            } else {
                //Alerte sur le jeu
                canDisplayGames = false;
            }
            if (canDisplayGames === true) {
                // On lance le jeu
                displayGameStepByStep(0, blockLabels, {}, true, currentGameId);
                relaunchCount++;
            } else {
                if (firstTime === true) {
                    launchGame(false);
                }
            }
        }, 2000)
    }

    const playASound = async (soundName: string) => {
        await playSound(soundName, "app");
    }

    const getBlockElement = (gameLabels: any[][], blockList: any) => {
        const aRandomNumber = Math.floor(Math.random() * gameLabels[blockList].data.length);
        let blockElement = gameLabels[blockList].data[aRandomNumber]
        const target = getAppTarget();
        if (target !== "public") {
            blockElement = {l:blockElement.l, a:getAudioUrlForMediaId({media_id:blockList+"_"+aRandomNumber})};
        }
        return blockElement;
    }

    const displayGameStepByStep = (step: number, blocks: any, newBlocks: any, firstTime: boolean, gameId: number) => {
        setTimeout(() => {
            const definedBlocks = activity.action.blocks;
            const gameTime = activity.duration !== undefined ? parseInt(activity.duration) : 300;
            let newBlockLabels = newBlocks;
            const blockLabels = blocks;
            const blockLabelsKeys = Object.keys(blockLabels);
            const stepKey = blockLabelsKeys[step];
            const blockLabel = blockLabels[stepKey];
            newBlockLabels[stepKey] = blockLabel;
            const newBlockLabelsKeys = Object.keys(newBlockLabels);
            if ((newBlockLabelsKeys.length === blockLabelsKeys.length) || (definedBlocks.length > 3)) {
                go = true;
                setTime(gameTime);
                setBlockLabels(newBlockLabels);
                if (blockLabel !== undefined) {
                    setAutomaticHisto([blockLabel]);
                    playTheLabel(blockLabel.a, step, blocks, newBlockLabels, gameTime, firstTime, gameId);
                }
            } else {
                go = false;
                setTime(gameTime);
                setBlockLabels(newBlockLabels);
                setRefresh(new Date().getTime());
                if (blockLabel !== undefined) {
                    setAutomaticHisto([blockLabel]);
                    playTheLabel(blockLabel.a, step, blocks, newBlockLabels, gameTime, firstTime, gameId);
                }
            }
            //On va regarder si on doit lancer un changement automatique pour ce label
            for (const aBlockIndex in definedBlocks) {
                if (Object.prototype.hasOwnProperty.call(definedBlocks, aBlockIndex)) {
                    const aDefinedblock = definedBlocks[aBlockIndex];
                    if (aDefinedblock.key === stepKey) {
                        if ((aDefinedblock.auto !== undefined) && (aDefinedblock.auto > 0)) {
                            launchAutomaticChangeForKeyAndTime(aDefinedblock.key, parseInt(aDefinedblock.auto), blocks, gameId, newBlockLabels);
                        }
                    }
                }
            }

        }, 1000);
    };

    const launchAutomaticChangeForKeyAndTime = (key: string, gameTime: number, blocks: any, gameId: number, gameLabels:any) => {
        //Nous ajoutons 3 secondes pour prendre en considération, le temps d'écoute des labels
        const timeBetweenEachChanges = (gameTime + 3) * 1000;
        setTimeout(() => {
            if ((completed === false) && (gameId === currentGameId) && (go === true)) {
                paused = true;
                const newLabel = getBlockLabelForKey(key);
                let modifiedBlockLables = { ...gameLabels };
                modifiedBlockLables[key] = newLabel;
                go = true;
                setBlockLabels(modifiedBlockLables);
                playTheLabel(newLabel.a, key, blocks, blockLabels, 0, false, gameId);
                launchAutomaticChangeForKeyAndTime(key, gameTime, blocks, gameId, modifiedBlockLables);
            }
        }, timeBetweenEachChanges);
    }

    const playTheLabel = async (sound: string, step: any, blocks: any[], newBlockLabels: any, gameTime: number, firstTime: boolean, gameId: number) => {
        const { offline = undefined } = activity;
        let initialVolume = 0;
        if (mute === false) {
            isSoundPlaying = true;
            if ((offline === undefined) || (Platform.OS === "web")) {
                await playSound(sound, "url");
            } else {
                const blockLabels = newBlockLabels;
                const blockLabelsKeys = Object.keys(blockLabels);
                const stepKey = blockLabelsKeys[step];
                const stepKeysElements = stepKey.split("_");
                const dirForOfflineFile = stepKeysElements[0] + "_" + getLanguage();
                const shortName = getShortNameForUrl(sound);
                let localUrl = getPathForOriginal(shortName, "mp3", dirForOfflineFile);
                localUrl = checkLocalUrl(localUrl);
                await playSound("file://" + localUrl, "url");
            }
        }
        setTimeout(() => {
            isSoundPlaying = false;
            manageEndOfLabel(initialVolume, step, blocks, newBlockLabels, firstTime, gameTime, gameId);
        }, 3000);
    }

    const manageEndOfLabel = (initialVolume: number, step: any, blocks: any[], newBlockLabels: any, firstTime: boolean, gameTime: number, gameId: number) => {
        paused = false;
        if (go === true) {
            if (firstTime === true) {
                setTimeout(() => {
                    countdown(gameTime - 1);
                }, 500);
            }
        } else {
            setTimeout(() => {
                displayGameStepByStep(parseInt(step) + 1, blocks, newBlockLabels, firstTime, gameId);
            }, 500);
        }
    }

    const countdown = (gameTime: number) => {
        setTimeout(() => {
            if (gameTime > -1) {
                if (go === true) {
                    let timeToCountdown = gameTime;
                    if (paused === false) {
                        timeToCountdown = timeToCountdown - 1;
                    }
                    if (gameTime < 11) {
                        if (gameTime < 2) {
                            //On enregistre l'exercice comme fait
                            if (completed === false) {
                                currentGameId = -1;
                                setNumberOfPartyDone(numberOfPartyDone + 1);
                                setCompleted(true);
                            }
                        }
                        if (paused === false) {
                            if (easterEggSound === true) {
                                playEasterEggSound();
                            } else {
                                playFastSound();
                            }
                        }
                    } else {
                        if (paused === false) {
                            if (easterEggSound === true) {
                                playEasterEggSound();
                            } else {
                                playNormalSound();
                            }
                        }
                    }
                    setTime(gameTime);
                    countdown(timeToCountdown);
                }
            } else if (gameTime === -1) {
                playASound('time.mp3');
                setTimeout(() => {
                    playTimerSound();
                }, 1500);
            }
        }, 1000);
    }

    const playTimerSound = () => {
        playASound("lili_je_m_exprime.mp3");
    }

    const playFastSound = () => {
        if ((isMute === false) && (isSoundPlaying === false)) {
            playASound("tictac.mp3");
        }
    }

    const playNormalSound = () => {
        if ((isMute === false) && (isSoundPlaying === false)) {
            const nameOfTheSound = easterEggSoundNumberOne === true ? 'tic.mp3' : 'tac.mp3';
            easterEggSoundNumberOne = !easterEggSoundNumberOne;
            playSound(nameOfTheSound, "app");
        }
    }

    const getBlockLabelForKey = (key: string) => {
        let blockLabel = [];
        const blocks = activity.action.blocks;
        let modifiedAutomaticHisto = [...automaticHisto];
        blocks.forEach(block => {
            if (block.key === key) {
                const blockList = block.list + "_" + getLanguage();
                if ((dbGameLabelsData[blockList] !== undefined) && (dbGameLabelsData[blockList].data !== undefined) && (dbGameLabelsData[blockList].data.length > 0)) {
                    let findNewLabel = false; // On définit un booléen qui va indiqué si le label trouvé n'a pas été utilisé lors du jeu en cours. si oui, on relance la recherche. Si non, on ajoute le label.
                    while (findNewLabel === false) {
                        const itemsList = dbGameLabelsData[blockList].data;
                        const aRandomNumber = Math.floor(Math.random() * itemsList.length);
                        blockLabel = itemsList[aRandomNumber];
                        if (modifiedAutomaticHisto.indexOf(blockLabel) === -1) {
                            findNewLabel = true;
                            modifiedAutomaticHisto.push(blockLabel);
                            setAutomaticHisto(modifiedAutomaticHisto)
                        }
                    }

                }
            }
        });
        return blockLabel;
    }

    const closeView = () => {
        currentGameId = -1;
        let total = numberOfPartyDone;
        if (completed === false) {
            const timePlayed = parseInt(activity.duration) - time;
            if (timePlayed > 0.5 * parseInt(activity.duration)) {
                total = total + 1;
            }
        }
        setTime(-1);
        setCompleted(true);
        go = false;
        if (total > 0) {
            if (props.onGameCompleted !== undefined) {
                props.onGameCompleted();
            }
            onActivityCompleted({
                activityKey: activity.key,
                sectionKey: activity.sectionKey,
                categoryKey: activity.categoryKeys[0],
                iteration: total,
                detail: "",
                menu: ""
            });
        }
        setTimeout(() => {
            if (props.closeTheGame !== undefined) {
                props.closeTheGame();
            }
        }, 500);
    }

    const toogleMuteMode = () => {
        setMute(!isMute);
    }

    const launchEasterEgg = () => {
        if (easterEggCount + 1 === 20) {
            easterEggCount = 0;
            easterEggSound = !easterEggSound;
        } else {
            easterEggCount++;
        }
    }

    const playEasterEggSound = () => {
        if ((isMute === false) && (isSoundPlaying === false)) {
            const nameOfTheSound = easterEggSoundNumberOne === true ? 'bip_one.m4a' : 'bip_two.m4a';
            easterEggSoundNumberOne = !easterEggSoundNumberOne;
            playSound(nameOfTheSound, "app");
        }
    }

    const onBlockScroll = (e) => {
        if (go === true) {
            const newPosition = e.nativeEvent.contentOffset.x / e.nativeEvent.layoutMeasurement.width;
            if (activity.action.blocks[newPosition] !== undefined) {
                blockPosition = newPosition;
                if (completed === false) {
                    const block = activity.action.blocks[newPosition];
                    let newBlockLabels = blockLabels;
                    if (newBlockLabels[block.key] === undefined) {
                        paused = true;
                        const block = activity.action.blocks[newPosition];
                        let newLabel = getBlockLabelForKey(block.key);
                        newBlockLabels[block.key] = newLabel;
                        go = true;
                        setBlockLabels(newBlockLabels);
                        playTheLabel(newLabel.a, blockPosition, {}, blockLabels, 0, false, "");
                    }
                }
            }
        }
    }

    const skipRules = () => {
        onPlayerEnd();
    }

    const goDownOneCard = () => {
        if (blockPosition > 0) {
            const newXPos = width * (blockPosition - 1);
            scrollViewRef.current?.scrollTo({ x: newXPos, y: 0, animated: true });
            blockPosition = blockPosition - 1;
        }
    }

    const goUpOneCard = () => {
        const blocks = activity.action.blocks;
        if (blockPosition < (blocks.length - 1)) {
            const newXPos = width * (blockPosition + 1);
            scrollViewRef.current?.scrollTo({ x: newXPos, y: 0, animated: true });
            blockPosition = blockPosition + 1;
        }
    }

    const topPosition = appTheme.pixelPerfect(20);
    const rightPosition = appTheme.pixelPerfect(40);
    const screenJustifyContent = 'center';
    const screenAlignItems = 'center';
    const closeButtonView = <TouchableOpacity onPress={closeView} style={[{ position: 'absolute', top: topPosition, right: rightPosition, backgroundColor: appTheme.white, borderRadius: appTheme.pixelPerfect(20), height: appTheme.pixelPerfect(40), width: appTheme.pixelPerfect(40), justifyContent: 'center', alignItems: 'center' }]}>
        <SVGView Component={IcClose} width={24} height={24} color={color} />
    </TouchableOpacity>;
    const slashView = mute === true ? <View style={{ height: appTheme.pixelPerfect(40), width: appTheme.pixelPerfect(40), justifyContent: "center", alignItems: "center", position: "absolute" }}>
        <SVGView Component={IcSlash} width={appTheme.pixelPerfect(30)} height={appTheme.pixelPerfect(30)} color={color} />
    </View> : <View />;
    const muteIcon = mute === true ? IcVolumeUp : IcVolumeUp;
    const muteButtonView = <TouchableOpacity onPress={toogleMuteMode} style={[{ position: 'absolute', top: topPosition, left: rightPosition, backgroundColor: appTheme.white, borderRadius: appTheme.pixelPerfect(20), height: appTheme.pixelPerfect(40), width: appTheme.pixelPerfect(40), justifyContent: 'center', alignItems: 'center' }]}>
        <SVGView Component={muteIcon} width={24} height={24} color={color} />
        {slashView}
    </TouchableOpacity>;

    //On va définir le titre
    const aTitleView = <View style={{ paddingTop: appTheme.pixelPerfect(10), flexDirection: 'row', width: '100%' }}>
        <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, paddingTop: appTheme.pixelPerfect(13) }}>
            <Text style={{ fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(16), color, textAlign: 'center' }}>{activity.title[getLanguage()].toLocaleUpperCase()}</Text>
        </View>
    </View>;

    // TO DO : remettre le bouton airplay
    const iosAirPlayLeftPosition = gameInstructionValidated === false ? rightPosition : (rightPosition + appTheme.pixelPerfect(50));
    const iosAirPlayButton = (Platform.OS === "ios") ? <View style={[{ position: 'absolute', top: topPosition, left: iosAirPlayLeftPosition, backgroundColor: appTheme.white, borderRadius: appTheme.pixelPerfect(20), height: appTheme.pixelPerfect(40), width: appTheme.pixelPerfect(40), justifyContent: 'center', alignItems: 'center' }]}>
        <LiliAirPlayButton
            activeTintColor={color}
            tintColor={appTheme.darkBlue} />
    </View> : <View />;

    const countdownCardStyle = {
        height: appTheme.pixelPerfect(40),
        width: appTheme.pixelPerfect(30),
        borderRadius: appTheme.pixelPerfect(6),
        justifyContent: 'center',
        alignItems: 'center'
    };

    const countdownCardTextStyle = {
        fontSize: appTheme.pixelPerfectForFont(30),
        paddingLeft: appTheme.pixelPerfect(2)
    };

    const getTimerView = () => {
        const { minDigit, minUnit, secDigit, secUnit } = getMinAndSecForCountdown(time);
        return <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: appTheme.pixelPerfect(10), height: appTheme.pixelPerfect(40) }}>
            <View style={[countdownCardStyle, { marginEnd: appTheme.pixelPerfect(3), backgroundColor: appTheme.white }]}>
                <Text style={[countdownCardTextStyle, { color: color }]}>{minDigit}</Text>
            </View>
            <View style={[countdownCardStyle, { marginEnd: appTheme.pixelPerfect(3), backgroundColor: appTheme.white }]}>
                <Text style={[countdownCardTextStyle, { color: color }]}>{minUnit}</Text>
            </View>
            <Text style={{ padding: 8, color: color, fontSize: appTheme.pixelPerfectForFont(36) }}>
                :
            </Text>
            <View style={[countdownCardStyle, { marginEnd: appTheme.pixelPerfect(3), backgroundColor: appTheme.white }]}>
                <Text style={[countdownCardTextStyle, { color: color }]}>{secDigit}</Text>
            </View>
            <View style={[countdownCardStyle, { marginEnd: appTheme.pixelPerfect(3), backgroundColor: appTheme.white }]}>
                <Text style={[countdownCardTextStyle, { color: color }]}>{secUnit}</Text>
            </View>
        </View>;
    }

    const getGameContentByStep = () => {
        if (viewDidAppear === false) {
            return <ActivityIndicator color={appTheme.white} />
        }
        let actionButton = <View />;
        if (gameInstructionValidated === false) {
            const actionAudio = ((activity.offline !== undefined) && (activity.offline.action !== undefined) && (activity.offline.action.audio !== undefined) && (Platform.OS !== "web")) ? activity.offline.action.audio : activity.action.audio;
            const appTarget = getAppTarget();
            if (actionAudio !== undefined) {
                let audioList = [require("../../assets/audio/lili_je_m_exprime.mp3")];
                for (const aAudioIndex in actionAudio[getLanguage()]) {
                    if (Object.prototype.hasOwnProperty.call(actionAudio[getLanguage()], aAudioIndex)) {
                        if (appTarget === "public") {
                            const audio = checkLocalUrl(actionAudio[getLanguage()][aAudioIndex]);
                            audioList.push(audio);
                        } else {
                            const media_id = activity.key+"_"+aAudioIndex;
                            audioList.push(getAudioUrlForMediaId({media_id}))
                        }
                    }
                }
                actionButton = <AudioPlayer
                    onPlayerEnd={onPlayerEnd}
                    autoLaunch={false}
                    color={color}
                    urls={audioList} />;
            }

            return (
                <View style={[styles.game, { width, height }]}>
                    <View style={[styles.container, { width: width, height: height, justifyContent: screenJustifyContent, alignItems: screenAlignItems }]}>
                        {aTitleView}
                        <View style={[{ justifyContent: 'center', alignItems: 'center', height: appTheme.pixelPerfect(160), marginTop: appTheme.pixelPerfect(20), width: width / 3 }]}>
                            {actionButton}
                        </View>
                        <TouchableOpacity onPress={skipRules} style={{ backgroundColor: appTheme.white, borderRadius: 10 }}>
                            <Text style={{ padding: 10, color: color, fontFamily: appTheme.secondaryFont, fontSize: 16 }}>Ne pas écouter les consignes du jeu</Text>
                        </TouchableOpacity>
                    </View>
                    {closeButtonView}
                    {iosAirPlayButton}
                </View>
            )
        } else if (countdownDisplayed === false) {
            const numberColors = [appTheme.white, appTheme.white, '#F6DC89', '#4EC6A9', '#C8F5FA', '#FF9972'];
            return (
                <View style={[styles.game, { width, height }]}>
                    <View style={[styles.container, { width, height, justifyContent: screenJustifyContent, alignItems: screenAlignItems }]}>
                        <View style={{ backgroundColor: "#25364F", width, height, justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(92), color: numberColors[countdownValue] }}>{countdownValue}</Text>
                        </View>
                    </View>
                    {closeButtonView}
                </View>
            )
        }

        // DERNIERE ETAPE
        //En fonction du template, on va afficher différentes vues et afficher différents éléments
        //Template 1 : Une seul case
        //Template 2 : 2 cases
        //Template 3 : 3 cases
        //Template 4 : 5 cases
        //On va déjà ajouter le nombre de cases nécessaires en fonction du nombre défini dans activity.action.blocks
        const blocks = activity.action.blocks;
        let blocksContainer = [];
        if (blocks !== undefined) {
            let blocksView = [];
            //On va calculer la largeur des blocs 
            //On va prendre un espace entre les blocs de 30
            const separationMargin = appTheme.pixelPerfect(30);
            //L'espace total à utiliser est la hauteur du tel, moins 16à pixel de marge sur les côté
            //Jusqu'à 3 blocs on affiche tout sur un écran. Au dessus, on aura une scrollview horizontal donc on prend la valeur pour 2
            const numberOfBlocks = blocks.length > 3 ? 2 : blocks.length;
            let widthForBlocks = (width - 160 - (numberOfBlocks - 1) * separationMargin) / numberOfBlocks;
            widthForBlocks = widthForBlocks > appTheme.pixelPerfect(200) ? appTheme.pixelPerfect(200) : widthForBlocks;
            const widthForBlockContainer = blocks.length > 3 ? width : widthForBlocks;
            const horizontalMargin = blocks.length > 3 ? 0 : separationMargin / 2;
            blocks.forEach(block => {
                const aBlockContent = blockLabels[block.key] !== undefined ? <Text style={{ textAlign: 'center', fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(12), color: appTheme.darkBlue, paddingHorizontal: appTheme.pixelPerfect(5) }}>{blockLabels[block.key].l.toLocaleUpperCase()}</Text> : <ActivityIndicator />;
                const aNewBlockView = <TouchableOpacity activeOpacity={1} onPress={launchEasterEgg} style={[Style.shadowed, { justifyContent: 'center', alignItems: 'center', marginTop: appTheme.pixelPerfect(20), marginHorizontal: horizontalMargin, width: widthForBlockContainer }]}>
                    <View style={{ justifyContent: 'center', alignItems: 'center', backgroundColor: appTheme.darkBlue, width: widthForBlocks, height: appTheme.pixelPerfect(44) }}>
                        <Text style={{ fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(12), color }}>{block.title[getLanguage()].toLocaleUpperCase()}</Text>
                    </View>
                    <View style={{ justifyContent: 'center', alignItems: 'center', backgroundColor: appTheme.white, width: widthForBlocks, height: appTheme.pixelPerfect(76) }}>
                        {aBlockContent}
                    </View>
                </TouchableOpacity>;
                blocksView.push(aNewBlockView);
            });
            if (blocks.length > 3) {
                blocksContainer = <View style={{ width, alignItems: 'center' }}>
                    <ScrollView
                        style={{width}}
                        ref={scrollViewRef}
                        onScroll={onBlockScroll}
                        pagingEnabled={true}
                        showsHorizontalScrollIndicator={false}
                        horizontal={true}>
                        <View style={{ flexDirection: 'row' }}>
                            {blocksView}
                        </View>
                    </ScrollView></View>;
            } else {
                blocksContainer = <View style={{ flexDirection: 'row' }}>{blocksView}</View>;
            }
        }

        const arrowButtonSize = appTheme.pixelPerfect(40);
        const topPositionForArrows = (Dimensions.get('screen').width - arrowButtonSize) / 2;
        const leftNavigationButtons = ((blocks.length > 3) && (blockPosition > 0)) ? <TouchableOpacity onPress={goDownOneCard} activeOpacity={1} style={{ justifyContent: 'center', alignItems: 'center', width: arrowButtonSize, height: arrowButtonSize, borderRadius: arrowButtonSize / 2, opacity: 0.8, backgroundColor: appTheme.white, position: 'absolute', top: topPositionForArrows, left: appTheme.pixelPerfect(40) }}>
            <SVGView Component={IcChevronLeft} size={appTheme.pixelPerfect(20)} color={color} />
        </TouchableOpacity> : <View />;
        const rightNavigationButtons = ((blocks.length > 3) && (blockPosition < (blocks.length - 1))) ? <TouchableOpacity onPress={goUpOneCard} activeOpacity={1} style={{ justifyContent: 'center', alignItems: 'center', width: arrowButtonSize, height: arrowButtonSize, borderRadius: arrowButtonSize / 2, opacity: 0.8, backgroundColor: appTheme.white, position: 'absolute', top: topPositionForArrows, right: appTheme.pixelPerfect(40) }}>
            <SVGView Component={IcChevronRight} size={appTheme.pixelPerfect(20)} color={color} />
        </TouchableOpacity> : <View />;
        return (
            <View style={{ width: height, height: width, justifyContent: 'center', alignItems: 'center' }}>
                <View style={[styles.game, { width, height }]}>
                    <View style={[styles.container, { width, height, justifyContent: screenJustifyContent, alignItems: screenAlignItems }]}>
                        {aTitleView}
                        {getTimerView()}
                        {blocksContainer}
                        <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: appTheme.pixelPerfect(10), height: appTheme.pixelPerfect(40) }}>
                            <TouchableOpacity onPress={() => launchGame(false)} style={[Style.shadowed, { backgroundColor: color, borderRadius: appTheme.pixelPerfect(20), height: appTheme.pixelPerfect(40), width: appTheme.pixelPerfect(40), justifyContent: 'center', alignItems: 'center' }]}>
                                <SVGView Component={IcRefresh} width={24} height={24} color={appTheme.white} />
                            </TouchableOpacity>
                        </View>
                    </View>
                    {closeButtonView}
                    {muteButtonView}
                    {leftNavigationButtons}
                    {rightNavigationButtons}
                    {iosAirPlayButton}
                </View>
            </View>
        );
    }

    //On va définir l'image de fond
    //const bgImage = bgImage !== undefined ? bgImage : 'jungle';
    const containerStyle = { 
        width: Platform.OS === "web" ? width : height, 
        height: Platform.OS === "web" ? height : width 
    };
    const bgImage = images.bgOnboardingParent; // TO DO : Revoir les images de fond
    const aBgImageView = <Image source={bgImage} style={[containerStyle, { position:"absolute" }]} />;
    const anOverlayView = <View style={[containerStyle, { position: 'absolute',  backgroundColor: appTheme.white + "30" }]} />;

    return (<View onLayout={onViewDidAppear} style={[containerStyle, {justifyContent: 'center', alignItems: 'center' }]}>
        {aBgImageView}
        {anOverlayView}
        {getGameContentByStep()}
    </View>);
}

export default GamePlay;

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 80
    },
    game: {
        transform: [
            { rotate: Platform.OS === "web" ? "0deg" : "90deg" }
        ],
    }
});
