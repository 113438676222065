import React, { useRef, useState } from "react";

import {
    Image,
    Modal,
    Platform,
    Text,
    TouchableOpacity,
    View,
    ViewStyle
} from "react-native";
import Images, { bundleImage } from "../../../specific/utils/Images";
import Divider from "../../designSystem/Divider/Divider";
import User from "../../data/user/User";
import AppTheme from "../../utils/Theme";
import Style from "../../utils/Style";
import Button from "../../designSystem/Button";
import { AppTarget, getAppTarget } from "../../../specific/utils/Navigation/Host";
import { getDocumentUrlForMediaId } from "../../../specific/services/AppWrite/AppWriteDatabase";
import { PDFDocument } from "./SuperRoom";
import { openUrl } from "../../utils/WebLink";

import InViewModal from "../../../specific/components/InViewModal/InViewModal";
import { ScreenActivitySheet } from "../../utils/Constants";
import * as Navigation from "../../../specific/utils/Navigation/Navigation";
import { addDataToUser } from "../../services/Database";
import SVGView from "../../utils/SvgView";
import { default as IcCheck } from "../../../shared/assets/svg/icons/solid/check.svg";

interface StartingStepsProps {
    width: number,
    height: number,
    componentId?: string,
    navigate?: string,
    onRequest:(requestId:string, backgroundColor:string) => void,
    showAtLaunch?: boolean,
    deeplink?: string
}

type StartingStep = {
    id: string,
    icon: number | bundleImage,
    title: string
}

const StartingSteps = (props: StartingStepsProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const user: User = User.getInstance();
    const inViewModal = useRef<InViewModal>(null);

    const { componentId, navigate, width, height, onRequest, showAtLaunch = false, deeplink = "" } = props;

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const [showSteps, setShowSteps] = useState<boolean>(showAtLaunch);
    const [pdfDocumentToShow, setPdfDocumentToShow] = useState<PDFDocument | undefined>(undefined);

    const teacherSteps: StartingStep[] = [
        {
            id: "filter",
            icon: images.illStepFilterByAge,
            title: "Filtrez les activités par âge"
        },
        {
            id: "tutorial",
            icon: images.illStepTutorial,
            title: "Lisez le mode d’emploi pédagogique"
        },
        {
            id: "webinar",
            icon: images.illStepWebinar,
            title: "Inscrivez-vous au webinar"
        },
        {
            id: "typicalWeekSchedule",
            icon: images.illStepAdvicesByLevel,
            title: "Imprimez notre semaine type par niveau"
        },
        {
            id: "advicesByActivityType",
            icon: images.illStepAdvicesByActivityType,
            title: "Lisez nos conseils par type d’activités"
        },
        {
            id: "path",
            icon: images.illStepPath,
            title: "Découvrez le parcours Bien dans mes baskets pour vos élèves"
        },
        {
            id: "academy",
            icon: images.icMinistereWhite,
            title: "Découvrez comment Lili s’inscrit dans le programme académique"
        }
    ]

    const familySteps: StartingStep[] = [
        {
            id: "filter",
            icon: images.illStepFilterByAge,
            title: "Filtrez les activités par âge"
        },
        {
            id: "tutorial",
            icon: images.illStepTutorial,
            title: "Lisez le mode d’emploi pédagogique"
        },
        {
            id: "advicesByActivityType",
            icon: images.illStepAdvicesByActivityType,
            title: "Lisez nos conseils par type d’activités"
        },
        {
            id: "advicesForSpecialNeedsKids",
            icon: images.illStepAdvicesByLevel,
            title: "Lisez nos conseils pour les enfants à besoins particuliers"
        },
        {
            id: "poster",
            icon: images.illStepPoster,
            title: "Imprimez le poster des super-pouvoirs pour votre enfant"
        },
        {
            id: "path",
            icon: images.illStepPath,
            title: "Découvrez le parcours Bien dans mes baskets pour votre enfant"
        },
        {
            id: "academy",
            icon: images.icMinistereWhite,
            title: "Découvrez comment Lili s’inscrit dans le programme académique"
        }
    ]

    const animatorSteps: StartingStep[] = [
        {
            id: "filter",
            icon: images.illStepFilterByAge,
            title: "Filtrez les activités par âge"
        },
        {
            id: "tutorial",
            icon: images.illStepTutorial,
            title: "Lisez le mode d’emploi pédagogique"
        },
        {
            id: "webinar",
            icon: images.illStepWebinar,
            title: "Inscrivez-vous au webinar"
        },
        {
            id: "advicesByLevel",
            icon: images.illStepAdvicesByLevel,
            title: "Lisez nos conseils par âge"
        },
        {
            id: "advicesByActivityType",
            icon: images.illStepAdvicesByActivityType,
            title: "Lisez nos conseils par type d’activités"
        },
        {
            id: "path",
            icon: images.illStepPath,
            title: "Découvrez le parcours Bien dans mes baskets pour les enfants"
        },
        {
            id: "academy",
            icon: images.icMinistereWhite,
            title: "Découvrez comment Lili s’inscrit dans le programme académique"
        }
    ]

    let stepsToDisplay: StartingStep[] = familySteps;
    let backgroundColor: string = appTheme.parentBackgroundColor;
    let stepsColor = "#354F84";
    let macaroonImage = images.icParentMacaroon;
    let itemsColor: string =  appTheme.homeColor;
    let itemsTextColor: string = appTheme.darkBlue;
    if (user.profile === "teacher") {
        stepsToDisplay = teacherSteps;
        stepsColor = "#716899";
        backgroundColor = appTheme.teacherBackgroundColor;
        macaroonImage = images.icTeacherMacaroon;
        itemsColor = appTheme.schoolColor;
        itemsTextColor =  appTheme.white;
    } else if (user.profile === "animator") {
        stepsToDisplay = animatorSteps;
        stepsColor = appTheme.schoolColor;
        backgroundColor = appTheme.animatorBackgroundColor;
        macaroonImage = images.icTeacherMacaroon;
        itemsColor = appTheme.schoolColor;
        itemsTextColor =  appTheme.white;
    }

    const sectionButtonStyle: ViewStyle = {
        marginTop: appTheme.pixelPerfect(10),
        paddingHorizontal: appTheme.pixelPerfect(20),
        paddingVertical: appTheme.pixelPerfect(5),
        backgroundColor: itemsColor,
        borderRadius: appTheme.pixelPerfect(30)
    };

    const showStepAction = async (step: StartingStep) => {
        if (step.id !== "filter") {
            setShowSteps(false);
        }
        onRequest(step.id, backgroundColor);
    }

    const stepCell = (data: { step: StartingStep, isFirst: boolean, isLast: boolean }) => {
        const { step, isFirst, isLast } = data;
        let isStepChecked = false;
        if (user.steps !== undefined) {
            if (user.steps[step.id] !== undefined) {
                isStepChecked = user.steps[step.id];
            }
        }
        let stepImage: any = step.icon;
        if (step.id === "notice") {
            stepImage = images.illStepNotice;
        }
        const playButtonSize = Platform.OS === "web" ? appTheme.pixelPerfect(42) : appTheme.pixelPerfect(34);
        const lineHeight = Platform.OS === "web" ? appTheme.pixelPerfect(10) : appTheme.pixelPerfect(5);
        const bottomLine = isLast === true ? <View /> : <View style={{ position: "absolute", width: 1, height: lineHeight, bottom: 0, left: playButtonSize / 2, backgroundColor: backgroundColor }} />;
        const topLine = isFirst === true ? <View /> : <View style={{ position: "absolute", width: 1, height: lineHeight, top: 0, left: playButtonSize / 2, backgroundColor: backgroundColor }} />;
        const actionButtonBackgroundColor = isStepChecked === false ? appTheme.white : stepsColor;
        const actionButtonTextColor = isStepChecked === true ? appTheme.white : stepsColor;
        const mediaCellWidth = Platform.OS === "web" ? appTheme.getBlockWidth() : appTheme.getFullAppWidth();
        const checkView = isStepChecked === false ? <View style={{ width: playButtonSize }} /> : <View style={{ width: playButtonSize, justifyContent: "center", alignItems: "center" }}><SVGView Component={IcCheck} color={appTheme.white} size={14} /></View>;
        return <TouchableOpacity onPress={() => showStepAction(step)} style={{ backgroundColor: appTheme.white, flexDirection: "row", height: appTheme.pixelPerfect(45) + lineHeight, justifyContent: "flex-start", alignItems: "center", width: appTheme.getFlexDirectionForSplitScreen() === "row" ? mediaCellWidth * 0.6 : mediaCellWidth * 0.9 }}>
            {topLine}
            {bottomLine}
            <View style={{ borderWidth: appTheme.pixelPerfect(0.5), borderColor: stepsColor + "4D", borderRadius: (playButtonSize + appTheme.pixelPerfect(6)) / 2, backgroundColor: actionButtonBackgroundColor, flexDirection: "row", height: playButtonSize + appTheme.pixelPerfect(6), justifyContent: "flex-start", alignItems: "center", paddingHorizontal: appTheme.pixelPerfect(2), width: appTheme.getFlexDirectionForSplitScreen() === "row" ? mediaCellWidth * 0.6 : mediaCellWidth * 0.9 }}>
                <View style={{ borderWidth: 1, borderColor: appTheme.darkBlue, width: playButtonSize, height: playButtonSize, borderRadius: playButtonSize / 2, justifyContent: "center", alignItems: "center", backgroundColor: actionButtonBackgroundColor, marginEnd: appTheme.pixelPerfect(10) }}>
                    <Image source={stepImage} style={{ width: playButtonSize, height: playButtonSize, borderRadius: playButtonSize / 2 }} />
                </View>
                <Text numberOfLines={3} style={{ color: actionButtonTextColor, flex: 1, fontFamily: appTheme.primarySemiBoldFont, fontSize: appTheme.pixelPerfectForFont(8) }}>{step.title}</Text>
                {checkView}
            </View>
        </TouchableOpacity>
    }

    const getStepList = () => {
        let stepListView = [];
        for (const aStepIndex in stepsToDisplay) {
            if (Object.prototype.hasOwnProperty.call(stepsToDisplay, aStepIndex)) {
                const aStep: StartingStep = stepsToDisplay[aStepIndex];
                stepListView.push(stepCell({
                    step: aStep,
                    isFirst: parseInt(aStepIndex) === 0,
                    isLast: parseInt(aStepIndex) === stepsToDisplay.length - 1,
                }))
            }
        }
        return stepListView
    }

    const getSteps = () => {
        if (showSteps === false) {
            return <View />
        }

        const stepsTitle: string = stepsToDisplay.length + " étapes pour vous lancer";
        const absoluteBadgeSize: number = Platform.OS === "web" ? 80 : 56;
        const absoluteBorderSize: number = Platform.OS === "web" ? 4 : 2;
        const badgeSize: number = Platform.OS === "web" ? appTheme.pixelPerfect(absoluteBadgeSize) : appTheme.pixelPerfect(absoluteBadgeSize);
        return <View style={{ flex: 1, justifyContent: "flex-end", backgroundColor: backgroundColor + "CC" }}>
            <View >
                <Divider size={absoluteBadgeSize / 2} />
                <View style={[Style.shadowed, { backgroundColor: appTheme.white, borderTopEndRadius: appTheme.pixelPerfect(20), borderTopStartRadius: appTheme.pixelPerfect(20), paddingTop: appTheme.pixelPerfect(40), paddingBottom: appTheme.pixelPerfect(40), alignItems: "center" }]}>
                    <Text style={{ textTransform: "uppercase", fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(7) }}>
                        {stepsTitle}
                    </Text>
                    <Divider />
                    {getStepList()}
                    <Divider />
                    <Button onPress={() => setShowSteps(false)} title="Fermer" link={true} />
                </View>
                <View style={{ width: appTheme.getFullScreenWidth(), alignItems: "center", position: "absolute" }}>
                    <View style={{ justifyContent: "center", alignItems: "center", overflow: "hidden", backgroundColor: appTheme.darkBlue, borderWidth: appTheme.pixelPerfect(absoluteBorderSize), borderColor: appTheme.white, width: badgeSize + appTheme.pixelPerfect(absoluteBorderSize), height: badgeSize + appTheme.pixelPerfect(absoluteBorderSize), borderRadius: (badgeSize + appTheme.pixelPerfect(absoluteBorderSize)) / 2 }}>
                        <Image source={images.illSevenSteps} style={{ width: badgeSize, height: badgeSize, borderRadius: badgeSize / 2 }} />
                    </View>
                </View>
            </View>
        </View>
    }

    const getStepsSection = () => {
        const macaroonSize = Platform.OS === "web" ? 60 : 40;
        const owlWidth = Platform.OS === "web" ? 60 : 40;
        const owlHeight = owlWidth * 3 / 2;
        const arrowLeftPosition = Platform.OS === "web" ? appTheme.pixelPerfect(20) : appTheme.pixelPerfect(16);
        const arrowTopPosition = Platform.OS === "web" ? appTheme.pixelPerfect(38) : appTheme.pixelPerfect(32);
        const arrowHeight = Platform.OS === "web" ? appTheme.pixelPerfect(24) : appTheme.pixelPerfect(16);
        const arrowWidth = Platform.OS === "web" ? appTheme.pixelPerfect(26) : appTheme.pixelPerfect(18);
        return <View style={{ alignItems: "center", justifyContent: "center", width: appTheme.getFullScreenWidth(), flexDirection: "row" }}>
            <View style={{ alignItems: "center", justifyContent: "flex-start", width: width + appTheme.pixelPerfect(20), paddingTop: appTheme.pixelPerfect(20) }}>
                <View style={{ width: width, backgroundColor: appTheme.white, borderRadius: appTheme.pixelPerfect(10), height: height, justifyContent: "center", alignItems: "center" }}>
                    <Text style={{ textTransform: "uppercase", fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(7) }}>
                        Nouveau sur LILI ?
                    </Text>
                    <Text style={{ fontFamily: appTheme.primarySemiBoldFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(6) }}>
                        {"Suivez les "+stepsToDisplay.length+" étapes pour vous lancer"}
                    </Text>
                    <TouchableOpacity onPress={() => setShowSteps(true)} style={sectionButtonStyle}>
                        <Text style={{ fontFamily: appTheme.primarySemiBoldFont, color: itemsTextColor, fontSize: appTheme.pixelPerfectForFont(6) }}>Lancez-vous !</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ justifyContent: "center", alignItems: "center", position: "absolute", top: appTheme.pixelPerfect(0), left: appTheme.pixelPerfect(4), width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40) }}>
                    <Image source={macaroonImage} style={{ position: "absolute", width: appTheme.pixelPerfect(macaroonSize), height: appTheme.pixelPerfect(macaroonSize) }} />
                    <Text style={{ textAlign: "center", fontFamily: appTheme.primaryBoldFont, color: appTheme.white, fontSize: appTheme.pixelPerfectForFont(3), textTransform: "uppercase", lineHeight: appTheme.pixelPerfect(10), transform: [{ rotate: '-20deg' }] }}>{"C'est\npar Ici"}</Text>
                    <Image source={images.icArrow} style={{ width: arrowWidth, height: arrowHeight, position: "absolute", left: arrowLeftPosition, top: arrowTopPosition }} />
                </View>
                <View style={{ justifyContent: "center", alignItems: "center", position: "absolute", top: appTheme.pixelPerfect(0), right: appTheme.pixelPerfect(4), width: appTheme.pixelPerfect(owlWidth), height: appTheme.pixelPerfect(owlHeight) }}>
                    <Image source={images.icOwlFlysuit} style={{ position: "absolute", width: appTheme.pixelPerfect(owlWidth), height: appTheme.pixelPerfect(owlHeight) }} resizeMode="contain" />
                </View>
            </View>
        </View>;
    }

    return <View style={{ flex: 1, justifyContent: "flex-end" }}>
        {getStepsSection()}
        <Modal visible={showSteps} animationType="slide" transparent={true}>
            {getSteps()}
        </Modal>
        <InViewModal ref={inViewModal} />
    </View>

}

export default StartingSteps;