import { Platform } from "react-native";
import * as Constants from "../utils/Constants";
import i18n, { getLanguage } from "../utils/Localization/Localization";
import { default as IcFilePdf } from "../assets/svg/lili/ic_pdf.svg";
import { default as IcFilm } from "../assets/svg/icons/solid/film.svg";
import { default as IcHeadphones } from "../assets/svg/lili/ic_audio_book.svg";
import { default as IcSearch } from "../assets/svg/lili/ic_search.svg";
import { getAppTarget } from "../../specific/utils/Navigation/Host";
import { getAudioUrlForMediaId } from "../../specific/services/AppWrite/AppWriteDatabase";
import User from "../data/user/User";

type localisedString = {
    fr: string
}

type localisedStringArray = {
    fr: string[]
}

type localisedIntroduction = {
    body?: localisedString,
    title?: localisedString
}

type header = {
    url?: string,
    type: string
}

type philoAns = {
    txt: localisedString,
    key: string
}

type philoUrls = {
    ans_0: string,
    ans_1: string,
    ans_2: string
}

type philoStep = {
    ans?: philoAns[],
    qst?: localisedString,
    urls?: philoUrls,
    url?: string,
    type: "qcm" | "audio_qcm" | "audio"
}

type actionChoice = {
    key: string,
    sub_type: string,
    type: string,
    url?: localisedString
}

type actionChoices = {
    title?: string,
    main_sub_type?: string,
    others?: actionChoice[]
}

export type block = {
    key: string,
    list: string,
    title: localisedString
}

type action = {
    type: string,
    title: localisedString,
    steps?: philoStep[],
    summary?: any[],
    audio?: localisedString,
    body?: localisedString,
    choices?: actionChoices,
    blocks?: block[],
    auto?: number
}

type document = {
    file?: string,
    title?: string
}

type access = {
    is_essential: boolean,
    is_premium: boolean
}

type colors = {
    bg: string,
    txt: string
}

type media = {
    id: string,
    title: localisedString,
    type: string,
    url: string
}

type summary = {
    body: localisedStringArray,
    title: string
}

type offline = {
    action: action
}

export const getActivityAvalability = (activity: Activity) => {
    let isPremium = false;
    if ((activity.access.is_essential === true) || (activity.access.is_premium === true)) {
        isPremium = true;
    }
    if (isPremium === false) {
        return true;
    }
    const user: User = User.getInstance();

    let isUserSubscriber = user.getPremiumStatus() !== "free";
    return isUserSubscriber;
}

export const getUserProfile = () => {
    const user: User = User.getInstance();
    return user.profile;
}

export default class Activity {
    access: access;
    action: action;
    analytics_key: string;
    app_image: string;
    body: localisedString;
    categoryKeys: string[]; // Catégories dans laquelle l'activité est classée (anciennement subSection)
    colors: colors;
    children: localisedIntroduction;
    cycles: string[]; // Niveaux de classe pour lesquels l'activité est adaptée
    document: document; // Document PDF lié à l'activité
    duration: number; // Durée en secondes de l'activité
    header: header;
    img_url: string;
    key: string; // Identifiant unique de l'activité
    last_update: number;
    main_action_ids: string[];
    media: media[];
    min_build_number: number;
    mute: boolean; // Si l'activité est un jeu, le lance-t-on sans le son par détaut ou avec
    offline?: offline;
    order: number; // Ordre d'affichage de l'activité au sein de sa section
    sectionKey: string;
    summary: summary; // Résumé détaillé de l'activité affiché par paragraphe (exemple: activités philos)
    tags: string[]; // Tags des super-pouvoirs 
    teacher?: localisedIntroduction;
    family?: localisedIntroduction;
    animator?: localisedIntroduction;
    title: localisedString; // Titre de l'activité

    remoteImage: string; // Illustration de l'activité, utilisée si l'illustration n'existe pas en local
    localImage: string; // Illustration local de l'activité

    chapo: string; // Decription de l'activité
    type: string; // Type de l'activité : Audio, AudioSteps, Tuto, etc...
    // header: Objet header à créer

    // action : Objet action à créer
    audio?: string; // Le fichier audio de l'activité (dans le cas d'un fichier audio unique)
    // media : Object media à créer
    media_title?: string; // Le titre à afficher au dessus de la liste des médias de l'activité (documents, préview, etc.)
    premium: boolean; // Est-ce que l'activité n'est accessible qu'aux utilisateurs premium ou à tous

    summaryTitle?: string; // Titre à afficher au dessus du résumé détaillé
    status: string; // Status de l'activité : Draft / Published
    lasAccess?: string; // Accès de l'activité en fonction de l'abonnement prof

    context?: localisedString;

    constructor(activity: {
        access?: access,
        action: action,
        analytics_key?: string,
        app_image?: string,
        body?: localisedString,
        category_keys?: string[],
        children?: localisedIntroduction,
        colors?: colors,
        cycles?: string[],
        document?: document,
        duration?: number,
        header?: header,
        img_url: string,
        key: string,
        last_update?: number,
        main_action_ids?: string[],
        media?: media[],
        min_build_number?: number,
        mute?: boolean,
        offline?: offline,
        order?: number,
        section_key: string,
        status?: string,
        summary?: summary,
        tags?: string[],
        teacher?: localisedIntroduction,
        family?: localisedIntroduction,
        animator?: localisedIntroduction,
        title: localisedString,

        remoteImage: string,
        localImage: string,
        chapo: string,
        type: string,

        sectionId: string,
        rubricId: string,
        audio?: string,
        media_title?: string,
        premium: boolean,
        doc?: string,
        summaryTitle?: string,
        lasAccess?: string

        context?: localisedString

    }) {
        this.access = activity.access ?? { is_essential: false, is_premium: true };
        this.action = activity.action;
        this.analytics_key = activity.analytics_key ?? "";
        this.app_image = activity.app_image ?? "";
        this.body = activity.body ?? { fr: "" };
        this.categoryKeys = activity.category_keys ?? [];
        this.colors = activity.colors ?? { bg: "#A2F2F8", txt: "#1A2737" };
        this.cycles = activity.cycles ?? ["cycle1", "cycle2", "cycle3"];
        this.document = activity.document ?? { file: "", title: "" };
        this.duration = activity.duration ?? 60;
        this.header = activity.header ?? { type: "img", url: "" }; // Si le header est de type img, on prend l'image de l'activité. Si le header est de type vid, l'url de celle-ci est présente dans le champs URL
        this.img_url = activity.img_url;
        this.key = activity.key;
        this.last_update = activity.last_update ?? new Date().getTime();
        this.main_action_ids = activity.main_action_ids ?? [];
        this.media = activity.media ?? [];
        this.min_build_number = activity.min_build_number ?? 0;
        this.mute = activity.mute ?? false;
        this.offline = activity.offline;
        this.order = activity.order ?? 0;
        this.sectionKey = activity.section_key;
        this.status = activity.status ?? "draft";
        this.summary = activity.summary ?? { body: { fr: [] }, title: "" };
        this.tags = activity.tags ?? [];
        this.title = activity.title;

        // On les définit à la fin pour qu'ils prennent les valeurs par défaut s'il n'en ont pas
        this.children = activity.children ?? { body: this.body, title: this.title };
        this.teacher = activity.teacher ?? { body: this.body, title: this.title };
        this.family = activity.family ?? { body: this.body, title: this.title };
        this.animator = activity.animator ?? { body: this.body, title: this.title };

        this.remoteImage = activity.remoteImage;
        this.localImage = activity.localImage;
        this.chapo = activity.chapo;
        this.type = activity.type;

        this.audio = activity.audio;
        this.media_title = activity.media_title;
        this.premium = activity.premium;

        this.summaryTitle = activity.summaryTitle;
        this.lasAccess = activity.lasAccess;

        this.context = activity.context;
    }

    isPremium = () => {
        if ((this.access.is_essential === true) || (this.access.is_premium === true)) {
            return true;
        }
        return false;
    }


    getActivityArrayForDisplay = (context: string, menu: string) => {
        // Dans le but de pouvoir mettre 2 actions en avant, comme dans les tutos philos, on va regarder si on doit séparer un media de la liste pour le mettre en avant
        const userProfile = getUserProfile();
        let mainAction = undefined;
        let activityMedia = [...this.media];
        if ((this[userProfile] !== undefined) && (this[userProfile].media !== undefined)) {
            activityMedia = this[userProfile].media;
        }
        if ((this.main_action_ids !== undefined) && (this.main_action_ids.length > 0)) {
            if (this.media !== undefined) {
                // Version 2.3 - on considère qu'il y a qu'une seule action pour intégrer les carnets philo.
                // On verra ensuite pour en mettre plusieurs
                let mainActionId = this.main_action_ids[0];
                // ON va regarder dans les media si on trouve cette main action
                for (const aMediaIndex in activityMedia) {
                    if (Object.prototype.hasOwnProperty.call(activityMedia, aMediaIndex)) {
                        const aMedia = activityMedia[aMediaIndex];
                        if (aMedia.id === mainActionId) {
                            let iconName = IcFilePdf;
                            if (aMedia.type === "preview") {
                                iconName = IcSearch;
                            } else if (aMedia.type === "audio") {
                                iconName = IcHeadphones;
                            } else if (aMedia.type === "video") {
                                iconName = IcFilm;
                            }
                            mainAction = {
                                icon: {
                                    name: iconName
                                },
                                media: aMedia
                            }
                            activityMedia.splice(parseInt(aMediaIndex), 1);
                            break;
                        }
                    }
                }
            }
        }
        //0. Empty cell for image only for mobile animation
        let activityCellData = [];
        if (Platform.OS !== "web") {
            activityCellData.push({
                type: "header",
                data: {}
            });
        }
        //1. Cell : Titre, durée, tags
        let title = this.title;
        let body = this.body;
        if (menu === "superpowers") {
            if (this.children !== undefined) {
                if (this.children.title !== undefined) {
                    title = this.children.title;
                }
                if (this.children.body !== undefined) {
                    body = this.children.body;
                }
            }
        }
        if (this[userProfile] !== undefined) {
            if (this[userProfile].title !== undefined) {
                title = this[userProfile].title;
            }
            if (this[userProfile].body !== undefined) {
                body = this[userProfile].body;
            }
        }
        if (context === Constants.CONTEXT.teacher) {
            if (this.teacher !== undefined) {
                if (this.teacher.title !== undefined) {
                    title = this.teacher.title;
                }
                if (this.teacher.body !== undefined) {
                    body = this.teacher.body;
                }
            }
        }
        let tagsString = "";
        if (this.tags.length > 0) {
            let superPowersKeys = [
                Constants.superPowersKeys.EMOTIONS_TAMING,
                Constants.superPowersKeys.FOCUS,
                Constants.superPowersKeys.ERROR_TAMING,
                Constants.superPowersKeys.SPEAK,
                Constants.superPowersKeys.CRITICAL_THINKING,
                Constants.superPowersKeys.COLLECTIVE,
                Constants.superPowersKeys.SELF_ESTEEM
            ];
            for (const aSuperPowerKeyIndex in superPowersKeys) {
                if (Object.prototype.hasOwnProperty.call(superPowersKeys, aSuperPowerKeyIndex)) {
                    const aSuperPowerKey = superPowersKeys[aSuperPowerKeyIndex];
                    if (this.tags.indexOf(aSuperPowerKey) !== -1) {
                        if (tagsString.length > 1) {
                            tagsString = tagsString + " | ";
                        }
                        tagsString = tagsString + i18n.t("tags." + aSuperPowerKey).replace("\n", " ");
                    }
                }
            }
        }
        activityCellData.push({
            type: "title",
            data: {
                title,
                duration: this.duration,
                tags: tagsString
            }
        });
        // 2. Résumé du podcast
        if (this.summary !== undefined) {
            const summary_title = ((this.summary.title !== undefined) && (this.summary.title[getLanguage()] !== undefined)) ? this.summary.title[getLanguage()] : i18n.t("activities.summary.title");
            const summary_body = ((this.summary.body !== undefined) && (this.summary.body[getLanguage()] !== undefined)) ? this.summary.body[getLanguage()] : [];
            // Si on a aucune donnée dans body, il n'est pas nécessaire de créer une vue
            if (summary_body.length > 0) {
                activityCellData.push({
                    type: "summary",
                    data: {
                        summary_title,
                        summary_body
                    }
                });
            }
        }

        // 2.bis Context du podcast
        if (this.context !== undefined) {
            const pdf_link: string = ((this.context !== undefined) && (this.context[getLanguage()] !== undefined)) ? this.context[getLanguage()] : "";
            if (pdf_link.length > 0) {
                activityCellData.push({
                    type: "context",
                    data: {
                        pdf_link
                    }
                });
            }
        }
        // 3. Action
        if ((this.action !== undefined) && (this.action.type !== undefined)) {
            if ((this.action.type === "audio") || (this.action.type === "audio_chapters")) {
                if ((this.action.audio !== undefined) && (this.action.audio[getLanguage()] !== undefined) && (this.action.audio[getLanguage()][0] !== undefined)) {
                    let urls = [];
                    if (getAppTarget() !== "public") {
                        for (const anUrlIndex in this.action.audio[getLanguage()]) {
                            if (Object.prototype.hasOwnProperty.call(this.action.audio[getLanguage()], anUrlIndex)) {
                                urls.push(getAudioUrlForMediaId({ media_id: this.key + "_" + anUrlIndex }));
                            }
                        }
                    } else {
                        urls = this.action.audio[getLanguage()];
                    }
                    activityCellData.push({
                        type: "action",
                        sub_type: this.action.type,
                        data: {
                            urls,
                            choices: this.action.choices,
                            title: this.action.title
                        }
                    });
                }
            } else if ((this.action.type === "game") || (this.action.type === "doc") || (this.action.type === "tuto") || (this.action.type === "web") || (this.action.type === "video")) {
                activityCellData.push({
                    type: "action",
                    sub_type: this.action.type,
                    data: {
                        title: this.action.title
                    }
                });
            } else if (this.action.type === "virelangue") {
                // On va ajouter la cellule avec la citation
                if ((this.action.body !== undefined) && (this.action.body[getLanguage()] !== undefined) && (this.action.body[getLanguage()].length > 0)) {
                    activityCellData.push({
                        type: "quote",
                        sub_type: "virelangue",
                        data: {
                            quote: this.action.body
                        }
                    });
                }

                let urls = [];
                if (getAppTarget() !== "public") {
                    for (const anUrlIndex in this.action.audio[getLanguage()]) {
                        if (Object.prototype.hasOwnProperty.call(this.action.audio[getLanguage()], anUrlIndex)) {
                            urls.push(getAudioUrlForMediaId({ media_id: this.key + "_" + anUrlIndex }));
                        }
                    }
                } else {
                    urls = this.action.audio[getLanguage()];
                }

                // Puis on ajoute la cellule avec l'action AUdio
                activityCellData.push({
                    type: "action",
                    sub_type: "virelangue",
                    data: {
                        urls,
                        title: this.action.title
                    }
                });
            } else if (this.action.type === "audio_steps") {
                activityCellData.push({
                    type: "action",
                    sub_type: "audio_steps",
                    data: {
                        title: this.action.title,
                        mainAction
                    }
                });
            }
        }
        // 4. Description
        if ((body !== undefined) && (body[getLanguage()] !== undefined)) {
            activityCellData.push({
                type: "body",
                data: {
                    body
                }
            });
        }
        
        let showDownloadOption: boolean = true; // On va définir si on montre le bouton de téléchargement en fonction du type d'action de l'activité
        let actionTypeWithoutDownloads = ["questions", "workshop", "web", "video"];
        if ((this.action !== undefined) && (this.action.type !== undefined)) {
            if (actionTypeWithoutDownloads.indexOf(this.action.type) !== -1) {
                showDownloadOption = false;
            }
        }
        // 5. User actions : Share, Download, Fav
        activityCellData.push({
            type: "user_actions",
            data: {
                showDownloadOption
            }
        });
        // 6. Media
        if (activityMedia.length > 0) {
            // Pour s'assurer qu'on a bien une vrai liste de media, on va d'abord construire la liste des media puis le titre
            let mediaCells: any[] = [];
            for (const aMediaIndex in activityMedia) {
                if (Object.prototype.hasOwnProperty.call(activityMedia, aMediaIndex)) {
                    const aMedia = activityMedia[aMediaIndex];
                    if ((aMedia.id !== undefined) && (aMedia.type !== undefined)) {
                        mediaCells.push({
                            type: "media",
                            data: {
                                media: aMedia
                            }
                        });
                    }
                }
            }
            if (mediaCells.length > 0) {
                // On va ajouter la ligne de titre
                let mediaIntroTitle: string = i18n.t("activities.grow.media.intro");
                if (this.sectionKey === "inspire") {
                    mediaIntroTitle = i18n.t("activities.bio.media.intro");
                }
                activityCellData.push({
                    type: "media_title",
                    data: {
                        title: mediaIntroTitle
                    }
                });
                // Ensuite on ajoute une ligne par média
                activityCellData = activityCellData.concat(mediaCells);
            }


        }
        return activityCellData;
    }
}